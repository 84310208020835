import React from 'react'
import { withAuthLogin } from 'eazy-auth'
const d = new Date()

const Login = ({
  handleSubmit,
  credentials: { username, password },
  error,
  loading,
}) => (
  <div className="d-flex align-items-center justify-content-center mt-5">
    <div className={'d-flex flex-direction-column'}>
      <form className="form-signin" onSubmit={handleSubmit}>
        <div className="text-center mb-2">
          <img
            className="mb-3"
            src="IrriSmart_Logo_CMYK.svg"
            alt="logo"
            width="200"
          />
          {/* <h1 className="h3 mb-3 font-weight-normal">IRRISMART</h1>
              <p>Irrigation management system</p> */}
        </div>

        <div className="form-group">
          <label>Login</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter username"
            autoFocus
            required
            {...username}
          />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            required
            {...password}
          />
        </div>

        <div className="checkbox mb-3">
          {/* <label>
                <input type="checkbox" value="remember-me" /> Remember me
              </label> */}
          <small>
            Forgotten password? Write an email to{' '}
            <a href="mailto:info@irrismart.it?subject=[IRRISmart] Forgotten password">
                info@irrismart.it
            </a>{' '}
            {/* <Link
                  to='/recover'
                  className='text-primary link'
                  >
                    <b>Recupera password</b>
                  </Link> */}
          </small>
        </div>
        <button
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          disabled={loading}
        >
          Sign in{loading && '...'}
        </button>
        {error && (
          <div className="alert alert-danger mt-3">Invalid credentials</div>
        )}
        <p className="mt-4 mb-3 text-muted text-center">
          &copy; {d.getFullYear()} -{' '}
          <a
            title="link irrismart"
            href="http://www.iambientale.it"
            target="_blank"
            rel="noopener noreferrer"
          >
            Informatica ambientale
          </a>
        </p>
      </form>
    </div>
  </div>
)

export default withAuthLogin({ credentials: ['username', 'password'] })(Login)
