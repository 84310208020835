import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import { rj } from 'redux-rocketjump'

// Namespace for actions
const NS = '@notification/'

// fields list
const GET_NOTIFICATION = `${NS}GET_NOTIFICATION`
export const {
  actions: { load: loadNotification, unload: unloadNotification },
  selectors: { getData: getNotification, isLoading: getNotificationLoading },
  reducer: notificationReducer,
  saga: notificationSaga,
} = rj({
  type: GET_NOTIFICATION,
  state: 'notification',
  //effectCaller: authApiCall,
  effect: ({ data }) =>
    request
      .post(`${API_URL}notifications/notificationstatus/segnala`)
      .set('Content-Type', 'application/json')
      .send(data)
      .then(({ body }) => body),
})()

export const reducer = notificationReducer

export const saga = function*() {
  yield fork(notificationSaga)
}
