import React from 'react'
import ReactDOM from 'react-dom'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { Link, withRouter } from 'react-router-dom'
import { get, keyBy, uniq, keys } from 'lodash'
import wktParse from 'wellknown'
import {
  Map,
  GeoJSON,
  LayersControl,
  Tooltip,
  CircleMarker,
  Pane,
  Popup,
  LayerGroup,
  Marker,
} from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google'
import { Row, Col, Card, CardBody, CardText, CardHeader } from 'reactstrap'
import {
  getMapBounds,
  getMapPanel,
  getMapObject,
  setMapObject,
  unsetMapObject,
  setMapBounds,
  setSensorNodeModal,
} from '../state/ui'

import { processGeoItems, getStatusClass, getStatusColor } from '../utils'
import { geometryCollection } from '@turf/helpers'
import center from '@turf/center'
import L from 'leaflet'
import classNames from 'classnames'
import DeviceData from './DeviceData'
import { GOOGLE_API_KEY } from '../consts'
import { MomentSpan } from './Helpers'
import { getFullDatetimeUserFormat, getNumberFormat } from '../state/languages'

const mapStateToProps = state => ({
  mapBounds: getMapBounds(state),
  mapPanel: getMapPanel(state),
  mapObject: getMapObject(state),
  fullDatetimeUserFormat: getFullDatetimeUserFormat(state),
  numberFormat: getNumberFormat(state),
})

const mapStateToPropsMap = state => ({
  mapBounds: getMapBounds(state),
  mapObject: getMapObject(state),
})

const SuggestedIrrigation = localize()(
  withRouter(
    ({
      irrigation,
      dateFormat,
      estate,
      currentObject,
      type,
      t,
      label = 'Add irrigation',
    }) => {
      const state = {
        id_estate: estate.id,
        type: 'irrigation',
        date: irrigation.date,
        use_duration: type === 'field' ? false : true,
        duration: type === 'field' ? 0 : irrigation.duration_min,
        amount: type === 'field' ? irrigation.quantity_m3 : 0,
        id: 0,
        id_field: type === 'field' ? currentObject.id : 0,
        id_irrigation_sector: type === 'field' ? 0 : currentObject.id,
        applied_to: type === 'field' ? type : 'irrigation_sector',
      }

      const to = {
        pathname: `/estates/${estate.id}`,
        search: '?tabName=irrigation',
        state,
      }

      return (
        <span>
          <MomentSpan date={irrigation.date} format={dateFormat} />,{' '}
          {t('duration')} {irrigation.duration_min} min., {t('quantity')}{' '}
          {irrigation.quantity_m3} m<sup>3</sup>.
          {(type !== 'field' || !currentObject.id_irrigation_sector) && (
            <span>
              {' '}
              <Link to={to}>{label}</Link>
            </span>
          )}
        </span>
      )
    }
  )
)

class SVGIconComponent extends React.Component {
  render() {
    const text = this.props.text || 'ciao'
    return (
      <svg
        width="80px"
        height="50px"
        viewBox="0 0 50 50"
        style={{ pointerEvents: 'none' }}
      >
        <text className="map-field-label" x={25} y={10}>
          {text}
        </text>
      </svg>
    )
  }
}

export const FieldsMapLeaflet = withRouter(
  connect(
    mapStateToPropsMap,
    { setMapObject, setMapBounds }
  )(
    localize()(
      class extends React.PureComponent {
        state = {
          fillPatterns: {},
        }

        componentWillMount() {
          const { estate } = this.props
          this.setState({
            viewport: {
              center: [estate.latitude, estate.longitude],
              zoom: 9,
            },
          })
        }

        componentDidMount() {
          const { fields } = this.props
          if (fields) {
            this.zoomToFields(fields)
          }
          this.updatePatterns()
        }

        componentDidUpdate(oldProps) {
          const oldFields = (oldProps.fields || []).map(x => x.id).join('-')
          const fields = (this.props.fields || []).map(x => x.id).join('-')
          if (oldFields !== fields) {
            this.updatePatterns()
          }
        }

        componentWillReceiveProps(nextProps) {
          const { viewport } = nextProps
          if (viewport !== this.props.viewport) {
            this.setState({ viewport })
          }

          if (nextProps.fields !== this.props.fields) {
            const { fields } = nextProps
            this.zoomToFields(fields)
          }

          if (nextProps.mapBounds !== this.props.mapBounds) {
            this.setState({ viewport: nextProps.mapBounds })
            if (!nextProps.mapBounds.noScroll) {
              ReactDOM.findDOMNode(this.map).scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
            }
          }
        }

        onViewportChanged = viewport => {
          this.setState({ viewport })
        }

        zoomToFields = fields => {
          const processedFields = processGeoItems(fields)
          const fieldsCollection = geometryCollection(
            processedFields.map(f => f.geomJson)
          )
          const collectionCenter = processedFields.length
            ? center(fieldsCollection)
            : null
          if (collectionCenter) {
            this.setState({
              viewport: {
                center: collectionCenter.geometry.coordinates.reverse(),
                zoom: 15,
              },
            })
          }
        }

        updatePatterns = () => {
          const { fields } = this.props
          const map = this.map.leafletElement
          const sectors = uniq(
            (fields || []).map(field => field.id_irrigation_sector)
          )

          keys(this.state.fillPatterns).forEach(k => {
            map.removePattern(this.state.fillPatterns[k])
          })

          let fillPatterns = {}
          const fieldsOrEmpty = fields || []
          fieldsOrEmpty.forEach((field, i) => {
            const sectorIndex = sectors.indexOf(field.id_irrigation_sector)
            const fieldStatus = get(field, 'water_deficit_status.status')
            fillPatterns[field.id] = new L.StripePattern({
              angle: sectorIndex * 30,
              weight: 1,
              opacity: 0.2,
              color: getStatusColor(fieldStatus),
              spaceWeight: 7,
              spaceColor: getStatusColor(fieldStatus),
              spaceOpacity: 0.2,
            })
            map.addPattern(fillPatterns[field.id])
            this.setState({ fillPatterns })
          })
        }

        getStyle = (type, obj) => feature => {
          const { mapObject } = this.props
          const { fillPatterns } = this.state

          const fillPattern = obj.id
            ? fillPatterns[obj.id.toString()]
            : undefined

          if (!mapObject) {
            return {
              fillPattern,
              fillOpacity: 1,
              weight: 0,
            }
          }

          const matchId = type === 'field' ? obj.id : obj.id_irrigation_sector

          return {
            color:
              mapObject.id === matchId && mapObject.type === type
                ? 'cyan'
                : undefined,
            fillPattern,
            fillOpacity: 1,
            weight: 2,
          }
        }

        handleClickMapObject = (type, item, id) => () => {
          const { allowSetObject = true } = this.props
          if (allowSetObject) {
            this.props.setMapObject({ type, id, item })
          }
          return
        }

        render() {
          const {
            estate,
            estateUnits,
            mapPanel,
            allowSetObject = true,
            fields,
            sectorsById,
            height = 400,
            t,
          } = this.props
          const { viewport } = this.state
          const { zoom } = viewport

          const processedFields = processGeoItems(fields)
          const hardware = get(estate, 'hardware', {}) || {}
          const { sensor_nodes, gateways, meteo_stations } = hardware

          return (
            <Map
              ref={node => {
                this.map = node
              }}
              style={{ height: height, zIndex: 1 }}
              // onClick={this.onClickReset}
              scrollWheelZoom={false}
              onViewportChanged={this.onViewportChanged}
              viewport={this.state.viewport}
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer name="Google satellite" checked>
                  <GoogleLayer
                    googlekey={GOOGLE_API_KEY}
                    maptype={'SATELLITE'}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google terrain">
                  <GoogleLayer googlekey={GOOGLE_API_KEY} maptype={'TERRAIN'} />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Google roads">
                  <GoogleLayer googlekey={GOOGLE_API_KEY} maptype={'ROADMAP'} />
                </LayersControl.BaseLayer>
                {(mapPanel === 'fields' ||
                  mapPanel === 'sectors' ||
                  mapPanel === 'configuration') && (
                  <LayersControl.Overlay name={t('fieldsLabels')}>
                    <LayerGroup>
                      {zoom >= 15 &&
                        processedFields.length > 0 &&
                        processedFields.map((field, i) => (
                          <Marker
                            position={field.center.reverse()}
                            key={field.id}
                            icon={L.divIcon({
                              className: 'no-pointer-events',
                              iconSize: [50, 50],
                              iconAnchor: [25, 0],
                              html: ReactDOMServer.renderToString(
                                <SVGIconComponent text={field.name} />
                              ),
                            })}
                          ></Marker>
                        ))}
                    </LayerGroup>
                  </LayersControl.Overlay>
                )}
                {estateUnits && (
                  <LayersControl.Overlay name={t('pedoclimaticUnits')}>
                    <LayerGroup>
                      <Pane style={{ zIndex: 500 }}>
                        {estateUnits.map(unit => {
                          let geom
                          try {
                            geom = wktParse(unit.geom_wkt)
                          } catch (err) {
                            return null
                          }

                          return (
                            <GeoJSON
                              key={`${unit.id}`}
                              data={geom}
                              className="field-unit"
                              style={{
                                color: 'blue',
                                fillColor: 'lime',
                                fillOpacity: 0.7,
                                weight: 1,
                              }}
                            >
                              <Tooltip>
                                <div className="text-center p-2">
                                  {t('pedoclimaticUnit')}: {unit.name}
                                </div>
                              </Tooltip>
                            </GeoJSON>
                          )
                        })}
                      </Pane>
                    </LayerGroup>
                  </LayersControl.Overlay>
                )}
              </LayersControl>
              {/* <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        /> */}

              {(mapPanel === 'fields' || mapPanel === 'configuration') &&
                fields &&
                processedFields.length > 0 && (
                  <Pane>
                    {processedFields.map((field, i) => (
                      <GeoJSON
                        key={`${field.id}`}
                        data={field.geomJson}
                        className="field-geometry"
                        style={this.getStyle('field', field)}
                        onClick={this.handleClickMapObject(
                          'field',
                          field,
                          field.id
                        )}
                      >
                        <Tooltip>
                          <div>
                            {field.name}
                            {field.id_irrigation_sector &&
                              get(
                                sectorsById,
                                field.id_irrigation_sector.toString()
                              ) && (
                                <div>
                                  {
                                    sectorsById[
                                      field.id_irrigation_sector.toString()
                                    ].name
                                  }
                                </div>
                              )}
                          </div>
                        </Tooltip>
                        {!allowSetObject && (
                          <Popup autoPan={false}>
                            <div className="text-center p-2">
                              Field: {field.name}
                              <br />
                              <a
                                href="#"
                                onClick={() => {
                                  const url = `/estates/${estate.id}/fields/${field.id}`
                                  this.props.history.push(url)
                                }}
                              >
                                {t('showDetails')}
                              </a>
                            </div>
                          </Popup>
                        )}
                      </GeoJSON>
                    ))}
                  </Pane>
                )}

              {mapPanel === 'sectors' && fields && processedFields.length > 0 && (
                <Pane>
                  {processedFields.map((field, i) => (
                    <GeoJSON
                      key={`${field.id}`}
                      data={field.geomJson}
                      className="sector-geometry"
                      style={this.getStyle('sector', field)}
                      onClick={
                        field.id_irrigation_sector
                          ? this.handleClickMapObject(
                              'sector',
                              get(
                                sectorsById,
                                field.id_irrigation_sector.toString()
                              ),
                              field.id_irrigation_sector
                            )
                          : undefined
                      }
                    >
                      <Tooltip>
                        <div>
                          {field.name}
                          {field.id_irrigation_sector &&
                            get(
                              sectorsById,
                              field.id_irrigation_sector.toString()
                            ) && (
                              <div>
                                Sector{' '}
                                {
                                  get(
                                    sectorsById,
                                    field.id_irrigation_sector.toString()
                                  ).name
                                }
                              </div>
                            )}
                        </div>
                      </Tooltip>
                      {!allowSetObject && (
                        <Popup autoPan={false}>
                          {field.id_irrigation_sector &&
                            get(
                              sectorsById,
                              field.id_irrigation_sector.toString()
                            ) && (
                              <div className="text-center p-2">
                                {
                                  get(
                                    sectorsById,
                                    field.id_irrigation_sector.toString()
                                  ).name
                                }
                                <br />
                                <a
                                  href="#"
                                  onClick={() => {
                                    const url = `/estates/${estate.id}/sectors/${field.id_irrigation_sector}`
                                    this.props.history.push(url)
                                  }}
                                >
                                  {t('showDetails')}
                                </a>
                              </div>
                            )}
                        </Popup>
                      )}
                    </GeoJSON>
                  ))}
                </Pane>
              )}

              {mapPanel !== 'meteo' &&
                (sensor_nodes && sensor_nodes.length > 0) && (
                  <MarkersLayer
                    allowSetObject={allowSetObject}
                    setSensorNodes={true}
                    items={sensor_nodes}
                    tooltipPrefix={`Sensor: `}
                    type={'sensor'}
                    fillGetter={item => {
                      return get(item, 'wealth_color')
                    }}
                  />
                )}
              {meteo_stations && meteo_stations.length > 0 && (
                <MarkersLayer
                  allowSetObject={allowSetObject}
                  items={meteo_stations}
                  tooltipPrefix={`Meteo station: `}
                  type={'meteo_station'}
                />
              )}
              {mapPanel !== 'meteo' && (gateways && gateways.length > 0) && (
                <MarkersLayer
                  allowSetObject={allowSetObject}
                  items={gateways}
                  type={'gateway'}
                  tooltipPrefix={`Gateway: `}
                />
              )}
            </Map>
          )
        }
      }
    )
  )
)

const DetailInfo = connect(
  mapStateToProps,
  { unsetMapObject, setSensorNodeModal }
)(
  localize()(
    class extends React.PureComponent {
      render() {
        const {
          mapObject,
          mapPanel,
          estate,
          fields,
          sectors,
          currentSector,
          t,
          numberFormat,
        } = this.props

        const currentObject = get(mapObject, 'item')
        const currentType = get(mapObject, 'type')
        const hasLink = currentType === 'sector' || currentType === 'field'
        const isSensor = currentType === 'sensor'

        const deficitStatus = get(currentObject, 'water_deficit_status.status')
        const soilWater = get(currentObject, 'water_deficit_status.soil_water')
        const suggestedIrrigation = get(
          currentObject,
          'water_deficit_status.suggested_irrigation'
        )

        const deficitStatus24 = get(
          currentObject,
          'water_deficit_status_24.status'
        )
        const soilWater24 = get(
          currentObject,
          'water_deficit_status_24.soil_water'
        )
        const suggestedIrrigation24 = get(
          currentObject,
          'water_deficit_status_24.suggested_irrigation'
        )

        const deficitStatus48 = get(
          currentObject,
          'water_deficit_status_48.status'
        )
        const soilWater48 = get(
          currentObject,
          'water_deficit_status_48.soil_water'
        )
        const suggestedIrrigation48 = get(
          currentObject,
          'water_deficit_status_48.suggested_irrigation'
        )

        const anyDeficitStatus =
          deficitStatus !== undefined ||
          deficitStatus24 !== undefined ||
          deficitStatus48 !== undefined

        const objectNames = {
          sensor: 'Sensor',
          gateway: 'Gateway',
          meteo_station: 'Meteo station',
          field: 'Field',
          sector: 'Sector',
        }

        const sensorNodes = get(estate, 'hardware.sensor_nodes', [])
        const sensorNodesStopped = sensorNodes.filter(
          item => !!item.turned_off_date
        )
        const sensorNodesNotTransmitting = sensorNodes.filter(
          item => item.wealth_status !== 'Green' && !item.turned_off_date
        )
        const allNodesOk =
          !sensorNodesStopped.length && !sensorNodesNotTransmitting.length

        return (
          <Card className="h-100">
            <CardHeader>
              <i className="fa fa-info-circle"></i> Info
              {mapObject && (
                <i
                  className="fa fa-close float-right pointer"
                  onClick={this.props.unsetMapObject}
                ></i>
              )}
            </CardHeader>
            <CardBody>
              {!mapObject && (
                <>
                  <CardText>{t('selectAnItemForDetails')}</CardText>
                  <CardText>
                    <b>{t('sensorsStatus')}</b>
                    <br />
                    {allNodesOk && t('allNodesOk')}
                    {sensorNodesStopped.length > 0 && (
                      <p>
                        {t('nodesStopped')}:{' '}
                        {sensorNodesStopped.map(x => x.name).join(', ')}
                      </p>
                    )}

                    {sensorNodesNotTransmitting.length > 0 && (
                      <p>
                        {t('nodesNotTransmitting')}:{' '}
                        {sensorNodesNotTransmitting.map(x => x.name).join(', ')}
                      </p>
                    )}
                  </CardText>
                </>
              )}

              {fields && sectors && currentObject && (
                <div>
                  <CardText>
                    <b>
                      {hasLink && (
                        <Link
                          to={
                            mapPanel === 'fields' ||
                            mapPanel === 'configuration'
                              ? `/estates/${estate.id}/fields/${currentObject.id}`
                              : `/estates/${estate.id}/sectors/${currentObject.id}`
                          }
                        >
                          {objectNames[mapObject.type]
                            ? objectNames[mapObject.type] + ': '
                            : ''}
                          {currentObject.name}
                        </Link>
                      )}
                      {!hasLink && !isSensor && (
                        <b>
                          {objectNames[mapObject.type]
                            ? objectNames[mapObject.type] + ': '
                            : ''}
                          {currentObject.name}
                        </b>
                      )}
                      {!hasLink && isSensor && (
                        <span>
                          {objectNames[mapObject.type]
                            ? objectNames[mapObject.type] + ': '
                            : ''}
                          {currentObject.name}{' '}
                          <a
                            href="#"
                            onClick={() =>
                              this.props.setSensorNodeModal(mapObject.item)
                            }
                          >
                            <small>{t('showData')}</small>
                          </a>
                        </span>
                      )}
                    </b>
                  </CardText>

                  {anyDeficitStatus && (
                    <div className="py-2">
                      {deficitStatus && (
                        <div className="mt-2">
                          {t('deficitStatus')}:{' '}
                          <span
                            className={`badge ${getStatusClass(
                              deficitStatus,
                              'badge-'
                            )}`}
                          >
                            {deficitStatus}
                          </span>
                        </div>
                      )}
                      {(soilWater || suggestedIrrigation) && (
                        <div className="mb-2">
                          {soilWater && (
                            <div>
                              <small>
                                {t('waterDeficit')}:{' '}
                                {numberFormat(soilWater.water_deficit)} mm.
                                <br />
                                {t('soilWaterContent')}:{' '}
                                {numberFormat(soilWater.available_water)} m
                                <sup>3</sup>/m<sup>3</sup>
                              </small>
                            </div>
                          )}
                          {suggestedIrrigation && (
                            <div>
                              <small>
                                {t('suggestedIrrigation')}:{' '}
                                <SuggestedIrrigation
                                  dateFormat={this.props.fullDatetimeUserFormat}
                                  irrigation={suggestedIrrigation}
                                  estate={estate}
                                  currentObject={currentObject}
                                  type={currentType}
                                />
                              </small>
                            </div>
                          )}
                        </div>
                      )}

                      {deficitStatus24 && (
                        <div className="mt-2">
                          {t('deficitStatus')} (24h):{' '}
                          <span
                            className={`badge ${getStatusClass(
                              deficitStatus24,
                              'badge-'
                            )}`}
                          >
                            {deficitStatus24}
                          </span>
                        </div>
                      )}
                      {(soilWater24 || suggestedIrrigation24) && (
                        <div className="mb-2">
                          {soilWater24 && (
                            <div>
                              <small>
                                {t('waterDeficit')}:{' '}
                                {numberFormat(soilWater24.water_deficit)} mm.
                                <br />
                                {t('soilWaterContent')}:{' '}
                                {numberFormat(soilWater24.available_water)} m
                                <sup>3</sup>/m<sup>3</sup>
                              </small>
                            </div>
                          )}
                          {suggestedIrrigation24 && (
                            <div>
                              <small>
                                {t('suggestedIrrigation')}:{' '}
                                <SuggestedIrrigation
                                  dateFormat={this.props.fullDatetimeUserFormat}
                                  irrigation={suggestedIrrigation24}
                                  estate={estate}
                                  currentObject={currentObject}
                                  type={currentType}
                                />
                              </small>
                            </div>
                          )}
                        </div>
                      )}

                      {deficitStatus48 && (
                        <div className="mt-2">
                          {t('deficitStatus')} (48h):{' '}
                          <span
                            className={`badge ${getStatusClass(
                              deficitStatus48,
                              'badge-'
                            )}`}
                          >
                            {deficitStatus48}
                          </span>
                        </div>
                      )}
                      {(soilWater48 || suggestedIrrigation48) && (
                        <div className="mb-2">
                          {soilWater48 && (
                            <div>
                              <small>
                                {t('waterDeficit')}:{' '}
                                {numberFormat(soilWater48.water_deficit)} mm.
                                <br />
                                {t('soilWaterContent')}:{' '}
                                {numberFormat(soilWater48.available_water)} m
                                <sup>3</sup>/m<sup>3</sup>
                              </small>
                            </div>
                          )}
                          {suggestedIrrigation48 && (
                            <div>
                              <small>
                                {t('suggestedIrrigation')}:{' '}
                                <SuggestedIrrigation
                                  dateFormat={this.props.fullDatetimeUserFormat}
                                  irrigation={suggestedIrrigation48}
                                  estate={estate}
                                  currentObject={currentObject}
                                  type={currentType}
                                />
                              </small>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {currentObject && currentObject.device && (
                    <DeviceData
                      device={currentObject.device}
                      columnClassName="col-sm-12"
                    />
                  )}

                  {currentSector && (
                    <CardText>
                      <Link
                        to={`/estates/${estate.id}/sectors/${currentSector.id}`}
                      >
                        {t('irrigationSector')}: {currentSector.name}
                      </Link>
                    </CardText>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        )
      }
    }
  )
)

const MarkersLayer = connect(
  mapStateToProps,
  { setMapObject, setSensorNodeModal }
)(
  localize()(
    class extends React.PureComponent {
      isCurrentMapObject = item => {
        const { mapObject, type } = this.props
        return mapObject && mapObject.type === type && item.id === mapObject.id
      }

      handleClickMapObject = (type, item, id) => () => {
        const { allowSetObject = true } = this.props
        if (allowSetObject) {
          this.props.setMapObject({ type, id, item })
        }
        return
      }

      render() {
        const {
          items,
          setMapObject,
          type,
          tooltipPrefix,
          t,
          fillGetter,
        } = this.props

        return (
          <Pane style={{ zIndex: 500 }}>
            {items.map((item, i) => (
              <CircleMarker
                key={`${item.id}-${this.isCurrentMapObject(item)}`}
                center={[item.latitude, item.longitude]}
                fillOpacity={fillGetter ? undefined : 0.85}
                weight={this.isCurrentMapObject(item) ? 2 : 1}
                radius={5}
                // className={`marker-${type} ${ this.isCurrentMapObject(item) ? 'current' : '' }`}
                className={classNames({
                  [`marker-${type}`]: fillGetter === undefined,
                  current: this.isCurrentMapObject(item),
                })}
                fillColor={fillGetter ? fillGetter(item) : undefined}
                onClick={this.handleClickMapObject(type, item, item.id)}
              >
                <Tooltip>
                  <div>
                    {tooltipPrefix}
                    {item.name}
                  </div>
                </Tooltip>
                {type === 'sensor' && (
                  <Popup autoPan={false}>
                    <div>
                      <div>{item.name}</div>
                      <small>
                        <a
                          href="#"
                          onClick={() => this.props.setSensorNodeModal(item)}
                        >
                          {t('showData')}
                        </a>
                      </small>
                    </div>
                  </Popup>
                )}
              </CircleMarker>
            ))}
          </Pane>
        )
      }
    }
  )
)

class FieldsMap extends React.PureComponent {
  render() {
    const {
      mapPanel,
      mapObject,
      fields,
      sectors,
      estateUnits,
      estate,
    } = this.props
    const currentObject = get(mapObject, 'item')
    const sectorsById = keyBy(sectors, 'id')

    const currentObjectSectorId = get(currentObject, 'id_irrigation_sector')
    const currentSector = get(sectorsById, currentObjectSectorId)

    return (
      <Row>
        <Col md={8} sm={12} xs={12}>
          <FieldsMapLeaflet
            sectorsById={sectorsById}
            fields={fields}
            mapPanel={mapPanel}
            estate={estate}
            estateUnits={estateUnits}
          />
        </Col>
        <Col md={4} sm={12} xs={12}>
          <DetailInfo
            estate={estate}
            sectors={sectors}
            fields={fields}
            currentSector={currentSector}
          />
        </Col>
      </Row>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { setMapObject, unsetMapObject, setSensorNodeModal }
  )(FieldsMap)
)
