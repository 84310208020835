import React from 'react'
import ReactDOM from 'react-dom'
import memoizeOne from 'memoize-one'
import { connect } from 'react-redux'
import {
  get,
  min,
  max,
  map,
  chunk,
  meanBy,
  first,
  last,
  keys,
  find,
  keyBy,
  maxBy,
} from 'lodash'
import { scaleLinear, scaleTime } from 'd3-scale'
import { AxisBottom, AxisLeft, AxisRight } from '@vx/axis'
import { Group } from '@vx/group'
import moment from 'moment'
import { Text } from '@vx/text'
import { LinePath, AreaClosed, Bar } from '@vx/shape'
import { GlyphDot } from '@vx/glyph'
import { extent } from 'd3-array'
import { timeFormat } from 'd3-time-format'
// import { downloadSensorCSV } from '../api'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'
import Brush from './Brush'
import SoilProviderLegend from './SoilProviderLegend'
import { getDayHourUserTimeFormat, getNumberFormat } from '../state/languages'
import { UncontrolledTooltip } from 'reactstrap'

const getMargin = mini =>
  mini
    ? {
        top: 10,
        left: 50,
        bottom: 30,
        right: 80,
      }
    : {
        top: 50,
        left: 50,
        bottom: 50,
        right: 80,
      }

const makeSerie = (serie, fromDate, toDate) =>
  get(serie, 'values', []).filter(x => {
    const d = x.date
    return (
      d >= fromDate.format('YYYY-MM-DDTHH:mm:ss') &&
      d <= toDate.format('YYYY-MM-DDTHH:mm:ss')
    )
  })

class SerieGraph extends React.PureComponent {
  render() {
    const {
      isCurrentX,
      getCurrentValues,
      setGuides,
      guideX,
      guideY,
      datum,
      margin,
      datesScale,
      varScale,
      vi,
      isSolarRadiation,
      values,
      dataSeries,
      mini,
      numberFormat,
      chartHeight,
      chartWidth,
      unitOfMeasure,
    } = this.props

    //const dsName = get(dataSeries[vi], 'name', '').toLowerCase()
      const dsName = get(dataSeries[vi], 'provider_type', '').toLowerCase()
    const isBars = dsName === 'rain' || dsName === 'irrigation'

    if (isBars) {
      return null
    }

    return (
      <Group top={margin.top} left={margin.left} key={vi}>
        {!isSolarRadiation && (
          <LinePath
            data={values}
            xScale={datesScale}
            yScale={varScale}
            y={i => i.value}
            x={i => moment(i.date).toDate()}
            stroke={get(dataSeries[vi], 'color', 'deepskyblue')}
            strokeWidth={2}
            glyph={(d, i) => {
              return (
                <GlyphDot
                  fill={
                    isCurrentX(d)
                      ? get(dataSeries[vi], 'color', 'deepskyblue')
                      : 'transparent'
                  }
                  key={`line-dot-${i}`}
                  cx={datesScale(moment(d.date).toDate())}
                  cy={varScale(d.value)}
                  r={4}
                  onMouseEnter={e => {
                    setGuides(
                      moment(d.date).toDate(),
                      varScale(d.value),
                      {
                        waterContent: dataSeries.map((dd, jj) =>
                          getCurrentValues(jj, d.date)
                        ),
                      },
                      vi
                    )
                  }}
                />
              )
            }}
          />
        )}

        {isSolarRadiation && (
          <AreaClosed
            data={values}
            xScale={datesScale}
            yScale={varScale}
            y={i => i.value}
            x={i => moment(i.date).toDate()}
            fill={get(dataSeries[vi], 'color', 'deepskyblue')}
            fillOpacity={0.8}
            strokeWidth={0}
            glyph={(d, i) => {
              return (
                <GlyphDot
                  fill={
                    isCurrentX(d)
                      ? get(dataSeries[vi], 'color', 'deepskyblue')
                      : 'transparent'
                  }
                  key={`line-dot-${i}`}
                  cx={datesScale(moment(d.date).toDate())}
                  cy={varScale(d.value)}
                  r={4}
                  onMouseEnter={e => {
                    setGuides(
                      moment(d.date).toDate(),
                      varScale(d.value),
                      {
                        waterContent: dataSeries.map((dd, jj) =>
                          getCurrentValues(jj, d.date)
                        ),
                      },
                      vi
                    )
                  }}
                />
              )
            }}
          />
        )}
        {/* tooltip */}
        {!mini && guideX && (
          <Group
            top={guideY < chartHeight / 2 ? guideY + 40 : guideY - 110}
            left={Math.max(0, datesScale(guideX) - 100)}
          >
            <rect
              style={{ fill: '#efefef', stroke: '#ccc' }}
              x={0}
              y={0}
              width={200}
              height={30 + 14 * dataSeries.length}
              rx="4"
              ry="4"
            ></rect>
            <text className="chart-guide-label" y={16}>
              <tspan className="chart-guide-label-big" x={100}>
                {timeFormat('%a %m/%d %H:%M')(guideX)}
              </tspan>
              {dataSeries.map((dd, j) => {
                return (
                  <tspan key={j} x={100} dy={14}>
                    {`${dataSeries[j].name}`}:{' '}
                    {datum.waterContent[j] !== undefined
                      ? numberFormat(datum.waterContent[j])
                      : '-'}{' '}
                    {`${dataSeries[j].unit_of_measure}`}
                  </tspan>
                )
              })}
            </text>
          </Group>
        )}
      </Group>
    )
  }
}

class SoilProviderGraph_ extends React.PureComponent {
  state = {
    guideX: null,
    guideY: null,
    datum: [],
    guideSerie: null,
  }

  resetGuides = () => {
    this.setState({
      guideX: null,
      guideY: null,
      datum: [],
      guideSerie: null,
    })
  }

  setGuides = (x, y, datum, guideSerie) => {
    this.setState({
      guideX: x,
      guideY: y,
      datum,
      guideSerie,
    })
  }

  isCurrentX = d => {
    return (
      this.state.guideX &&
      moment(d.date)
        .toDate()
        .toString() === this.state.guideX.toString()
    )
  }

  getCurrentValues = (serieIndex, date) => {
    const { seriesValues, numberFormat } = this.props
    const datum = find(seriesValues[serieIndex], item => item.date === date)
    const value = get(datum, 'value')
    return value
  }

  getBarWidth = memoizeOne((barValuesRain, datesScale, chartWidth) => {
    let rainBarWidth = 0
    if (barValuesRain.length) {
      const dates = barValuesRain.map(i => i.date).sort()
      if (dates.length > 1) {
        rainBarWidth =
          datesScale(moment(dates[1]).toDate()) -
          datesScale(moment(dates[0]).toDate())
      } else {
        // one data point only ..
        rainBarWidth = chartWidth
      }
    }
    return Math.max(rainBarWidth, 2)
  })

  render() {
    const {
      dataSeriesRaw,
      nodeData,
      width,
      height,
      showToday = false,
      currentParams,
      fromDate,
      toDate,
      margin,
      providerType,
      mini = false,
      numberFormat,
      hasRain,
      t
    } = this.props

    const {
      varScale,
      datesScale,
      chartHeight,
      chartWidth,
      barCharHeight,
      seriesValues,
      barValuesRain,
      barValuesIrrigation,
      rainScale,
      irrigationScale,
      unitOfMeasure,
      chartBands,
      dataSeries,
    } = this.props

      //console.log(barValuesRain, barValuesIrrigation)

    const isSolarRadiation =
      dataSeries &&
      dataSeries.length &&
      dataSeries[0].name === 'Solar Radiation'
    const hasThresholds =
      ['SoilWaterContent', 'AttainableWater'].indexOf(providerType) !== -1

    let barWidth = width / 20
    const today = moment(moment().format('YYYY-MM-DDTHH') + ':00:00')

      //const dataSeriesByName = keyBy(dataSeries, d => d.name.toLowerCase())
      const dataSeriesByName = keyBy(dataSeries, d => d.provider_type.toLowerCase())
      //console.log(dataSeriesByName)
    const rainBarWidth = this.getBarWidth(barValuesRain, datesScale, chartWidth)

    return (
      <React.Fragment>
        <Group onMouseLeave={() => this.resetGuides()}>
          {!mini && (
            <AxisLeft
              scale={varScale}
              left={margin.left}
              top={margin.top}
              tickFormat={numberFormat}
              tickLength={4}
              label={unitOfMeasure}
              numTicks={mini ? 2 : undefined}
            />
          )}

          <AxisBottom
            scale={datesScale}
            left={margin.left}
            top={margin.top + chartHeight}
            tickLength={4}
            tickFormat={
              mini
                ? timeFormat('%m/%d')
                : timeFormat(this.props.dayHourUserTimeFormat)
            }
          />

          {/* rain and irrigation chart */}
          {hasRain && (
            <g>
              <AxisBottom
                scale={datesScale}
                left={margin.left}
                top={margin.top + chartHeight + 150}
                tickLength={4}
                tickFormat={
                  mini
                    ? timeFormat('%m/%d')
                    : timeFormat(this.props.dayHourUserTimeFormat)
                }
              />

              {!mini && (
                <AxisLeft
                  scale={rainScale}
                  left={margin.left}
                  top={margin.top + chartHeight + 150 - 100}
                  tickFormat={numberFormat}
                  tickLength={4}
                  label={get(dataSeriesByName['rain'], 'unit_of_measure', 'mm')}
                  numTicks={undefined}
                />
              )}

              <AxisRight
                scale={irrigationScale}
                left={margin.left + chartWidth}
                top={margin.top + chartHeight + 150 - 100}
                tickFormat={numberFormat}
                tickLength={4}
                label={get(
                  dataSeriesByName['irrigation'],
                  'unit_of_measure',
                  'mm'
                )}
                numTicks={undefined}
              />

              <Group
                top={margin.top + chartHeight + 150 - 100}
                left={margin.left}
              >
                {barValuesRain.map((d, i) => {
                  const x = datesScale(moment(d.date).toDate())
                  const height = 100 - rainScale(d.value)
                  return (
                    <g key={i}>
                      <Bar
                        width={rainBarWidth}
                        height={height}
                        x={x - rainBarWidth / 2}
                        y={rainScale(d.value)}
                        strokeWidth={2}
                        fill={dataSeriesByName['rain'].color}
                      ></Bar>
                    </g>
                  )
                })}
                {barValuesIrrigation.map((d, i) => {
                  const x = datesScale(moment(d.date).toDate())
                  const height = 100 - irrigationScale(d.value)
                  return (
                    <g key={i}>
                      <Bar
                        width={rainBarWidth}
                        height={height}
                        x={x - rainBarWidth / 2}
                        y={irrigationScale(d.value)}
                        strokeWidth={2}
                        fill={dataSeriesByName['irrigation'].color}
                      ></Bar>
                    </g>
                  )
                })}
              </Group>
            </g>
          )}

          {!mini && today && showToday && (
            <Group top={margin.top} left={margin.left}>
              <rect
                width={barWidth * 3}
                x={datesScale(
                  today
                    .clone()
                    .subtract(1, 'hours')
                    .toDate()
                )}
                height={chartHeight}
                className="today-bar"
              />
              <Text
                className="chart-legend-label"
                textAnchor={'middle'}
                y={-10}
                x={datesScale(
                  today
                    .clone()
                    .add(30, 'minutes')
                    .toDate()
                )}
              >
               {t('now')}
              </Text>
            </Group>
          )}

            {!mini && today && showToday && (
                <Group top={margin.top} left={margin.left}>
                    <rect
                        width={datesScale(
                            toDate
                        ) - datesScale(
                            today
                                .clone()
                                .add(2, 'hours')
                                .toDate()
                        )}
                        x={datesScale(
                            today
                                .clone()
                                .add(2, 'hours')
                                .toDate()
                        )}
                        height={chartHeight}
                            className="forecast-bar"
                    />
                    <Text
                        className="chart-legend-label"
                        textAnchor={'middle'}
                        y={-10}
                        x={datesScale(
                            today
                                .clone()
                                .add(150, 'minutes')
                                .toDate()
                        )}
                    >
                    {t('forecast')}
            </Text>
                </Group>
            )}

          {/* guides */}
          {!mini && this.state.guideX && (
            <Group top={margin.top} left={margin.left}>
              <line
                x1={datesScale(this.state.guideX)}
                x2={datesScale(this.state.guideX)}
                y1={0}
                y2={chartHeight}
                className="chart-guide"
              ></line>

              {get(this.state.datum, 'waterContent', []) &&
                this.state.datum.waterContent.map((xx, j) => (
                  <Group key={j}>
                    <line
                      y1={varScale(xx)}
                      y2={varScale(xx)}
                      x1={datesScale(this.state.guideX)}
                      x2={0}
                      className="chart-guide"
                    ></line>
                    <circle
                      cy={varScale(xx)}
                      fill={get(dataSeries[j], 'color', 'deepskyblue')}
                      r={4}
                      cx={0}
                    ></circle>
                  </Group>
                ))}
            </Group>
          )}

          {/* thresholds */}
          {(!mini || hasThresholds) && (
            <Group left={margin.left} top={margin.top}>
              {chartBands.length > 0 &&
                chartBands.map((band, bi) => (
                  <g key={bi}>
                    <line
                      stroke={band.color}
                      strokeWidth={1.5}
                      x1={0}
                      x2={chartWidth}
                      y1={varScale(band.value)}
                      y2={varScale(band.value)}
                    />
                    {!mini && (
                      <Text
                        textAnchor={'start'}
                        verticalAnchor={'middle'}
                        className="axis-annotation"
                        width={100}
                        fill={'#666'}
                        x={chartWidth + 10}
                        y={varScale(band.value)}
                      >
                        {band.label}
                      </Text>
                    )}
                  </g>
                ))}
            </Group>
          )}

          {/* actual var */}
          {seriesValues.map((values, vi) => (
            <SerieGraph
              key={vi}
              margin={margin}
              vi={vi}
              values={values}
              isCurrentX={this.isCurrentX}
              getCurrentValues={this.getCurrentValues}
              setGuides={this.setGuides}
              guideX={this.state.guideX}
              guideY={this.state.guideY}
              datum={this.state.datum}
              isSolarRadiation={isSolarRadiation}
              {...this.props}
            ></SerieGraph>
          ))}

          {/* rain and irrigation on mini chart */}
          {mini && (
            <Group top={margin.top} left={margin.left}>
              {barValuesRain.map((d, i) => {
                const x = datesScale(moment(d.date).toDate())
                const height = chartHeight / 4 - rainScale(d.value)
                return (
                  <g
                    key={i}
                    onMouseEnter={e => {
                      console.log('enter', d)
                    }}
                  >
                    <Bar
                      width={2}
                      height={height}
                      x={x - 1}
                      y={rainScale(d.value) + (chartHeight / 4) * 3}
                      fill={dataSeriesByName['rain'].color}
                      strokeWidth={2}
                    />
                  </g>
                )
              })}
              {barValuesIrrigation.map((d, i) => {
                const x = datesScale(moment(d.date).toDate())
                const height = chartHeight / 4 - irrigationScale(d.value)
                return (
                  <g key={i}>
                    <Bar
                      width={2}
                      height={height}
                      x={x - 1}
                      y={irrigationScale(d.value) + (chartHeight / 4) * 3}
                      fill={dataSeriesByName['irrigation'].color}
                      strokeWidth={2}
                    />
                  </g>
                )
              })}
            </Group>
          )}

          {!mini && (
            <SoilProviderLegend
              width={width}
              top={5}
              label={`${providerType}`}
              dataSeries={dataSeries}
            />
          )}
        </Group>
      </React.Fragment>
    )
  }
}

const SoilProviderGraph = connect(state => ({
  dayHourUserTimeFormat: getDayHourUserTimeFormat(state),
  numberFormat: getNumberFormat(state),
}))(SoilProviderGraph_)

class ChartWrapper extends React.PureComponent {
  downloadPng = e => {
    const { chartLabel } = this.props

    var node = ReactDOM.findDOMNode(this)
    function filter(node) {
      return node.tagName !== 'I'
    }

    domtoimage
      .toBlob(node, { bgcolor: '#fff', filter: filter })
      .then(function(blob) {
        FileSaver.saveAs(blob, `${chartLabel}.png`)
      })
  }

  makeChunkDatum = chunk => {
    const d = first(chunk)
    const lastD = last(chunk)
    const datesDiff = moment(lastD.date).diff(moment(d.date))
    const midDate = moment(d.date).add(datesDiff / 2, 'milliseconds')

    const k = keys(d)
    let out = {
      type: d.type,
      date: midDate.toDate(),
    }
    k.filter(x => ['date', 'type'].indexOf(x) === -1).forEach(x => {
      out[x] = meanBy(chunk, x)
    })
    return out
  }

  updateDataSeries = memoizeOne(
    (dataSeriesRaw, mini, margin, height, width, toDate, fromDate, hasRain) => {
      const BAR_SERIES = ['rain', 'irrigation']

      const dataSeries = dataSeriesRaw.map(x => ({
        ...x,
        values:
          mini && BAR_SERIES.indexOf(x.name.toLowerCase()) === -1
            ? chunk(x.values, 6).map(y => y[0])
            : x.values,
      }))

      const firstDatum = dataSeries[0]

      const providerType = get(firstDatum, 'provider_type')
      const providerId = get(firstDatum, 'id')
      const unitOfMeasure = get(firstDatum, 'unit_of_measure')
      const allValues = dataSeries
        .filter(s => BAR_SERIES.indexOf(s.name.toLowerCase()) === -1)
        .reduce((acc, item) => {
          return acc.concat(get(item, 'values'))
      }, [])          

      const valuesRange = extent(allValues, x => x.value)

      const chartBands = get(firstDatum, 'chart_bands')
      const bandMinValue =
        chartBands && chartBands.length
          ? min(map(chartBands, 'value'))
          : valuesRange[0]
      const bandMaxValue =
        chartBands && chartBands.length
          ? max(map(chartBands, 'value'))
          : valuesRange[1]

      const absMax = Math.max(bandMaxValue, valuesRange[1])
      const absMin = Math.min(bandMinValue, valuesRange[0])

      const delta = Math.abs(absMax - absMin)
      const marginScale = delta * 0.1
      let minForScale = absMin - marginScale
      const maxForScale = absMax + marginScale

      if (
        [
          'SoilWaterContent',
          'AttainableWater',
          'RelativeHumidity',
          'SolarRadiation',
        ].indexOf(providerType) !== -1
      ) {
        minForScale = Math.max(minForScale, 0)
      }

      const barCharHeight = hasRain && !mini ? 150 : 0
      const chartHeight = height - margin.top - margin.bottom - barCharHeight
      const chartWidth = width - margin.left - margin.right

      const datesScale = scaleTime()
        .range([0, chartWidth])
        .domain([fromDate.toDate(), toDate.toDate()])

      const seriesValues = dataSeries.map(s => makeSerie(s, fromDate, toDate))
      let barValuesRain = []
      let barValuesIrrigation = []

      if (hasRain) {
        //const rainSerie = find(
        //  dataSeries,
        //  s => s.name.toLowerCase() === 'rain',
        //  {}
        //)
          const rainSerie = find(
              dataSeries,
              s => s.provider_type.toLowerCase() === 'rain',
              {}
          )
        barValuesRain = makeSerie(rainSerie, fromDate, toDate)
        //const irrigationSerie = find(
        //  dataSeries,
        //  s => s.name.toLowerCase() === 'irrigation',
        //  {}
        //)
          const irrigationSerie = find(
              dataSeries,
              s => s.provider_type.toLowerCase() === 'irrigation',
              {}
          )
        barValuesIrrigation = makeSerie(irrigationSerie, fromDate, toDate)
      }

      const rainRange = extent(barValuesRain, x => x.value)
      const irrigationRange = extent(barValuesIrrigation, x => x.value)

      const dataRatio = Math.ceil(seriesValues.length / this.props.width)
      if (dataRatio > 1) {
        seriesValues = chunk(seriesValues, dataRatio).map(this.makeChunkDatum)
        // barValuesRain = this.makeChunkDatum(chunk(barValuesRain, dataRatio))
        // barValuesIrrigation = this.makeChunkDatum(chunk(barValuesIrrigation, dataRatio))
      }

      const varScale = scaleLinear()
        .range([chartHeight, 0])
        .domain([minForScale, maxForScale])

      const rainScale = scaleLinear()
        .range([mini ? chartHeight / 4 : 100, 0])
        .domain([0, Math.max(rainRange[1] || 0, 1)])

      const irrigationScale = scaleLinear()
        .range([mini ? chartHeight / 4 : 100, 0])
        .domain([0, Math.max(irrigationRange[1] || 1)])

      return {
        varScale,
        datesScale,
        chartHeight,
        chartWidth,
        seriesValues,
        barValuesRain,
        barValuesIrrigation,
        unitOfMeasure,
        chartBands,
        dataSeries,
        providerId,
        barCharHeight,
        rainScale,
        irrigationScale,
      }
    }
  )

  render() {
    const {
      minDate,
      maxDate,
      dragCallbackLeft,
      dragCallbackRight,
      dragCallbackCenter,
      dataProvidersByType,
      providerType,
      providerData,
      dateRange,
      fromDate,
      toDate,
      mini,
      currentParams,
      width,
      height,
      hasRain,
      ...passProps
    } = this.props

    const margin = getMargin(this.props.mini)
    const dataSeriesRaw = dataProvidersByType[providerType]

    const downProps = this.updateDataSeries(
      dataSeriesRaw,
      mini,
      margin,
      height,
      width,
      toDate,
      fromDate,
      hasRain
    )

    if (!dataSeriesRaw || !dataSeriesRaw.length) {
      return <svg width={width} height={height} />
    }

    const fullTitle = downProps.dataSeries.map(d => d.name).join(', ')

    return (
      <div className="chart-container">
        {!mini && (
          <React.Fragment>
            <i
              className="text-muted pointer float-right fa fa-download download-button"
              id={`provider-${providerData.id}-${providerType}`}
              onClick={() => this.downloadPng()}
            ></i>
            <UncontrolledTooltip
              placement="right"
              target={`provider-${providerData.id}-${providerType}`}
            >
              Download chart
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        {!mini && fullTitle && (
          <div className="text-center">
            <span>
              <b>{fullTitle}</b>{' '}
            </span>
          </div>
        )}

        <svg width={width} height={height}>
          <SoilProviderGraph
            width={width}
            height={height}
            fromDate={fromDate}
            toDate={toDate}
            mini={mini}
            margin={margin}
            providerType={providerType}
            {...downProps}
            dataSeriesRaw={dataSeriesRaw}
            nodeData={providerData}
            hasRain={hasRain}
          />
          {this.props.mini && (
            <Brush
              dateRange={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              dragCallbackLeft={dragCallbackLeft}
              dragCallbackRight={dragCallbackRight}
              dragCallbackCenter={dragCallbackCenter}
              margin={margin}
              chartPadding={10}
              height={height}
              width={width}
            />
          )}
        </svg>
      </div>
    )
  }
}

//
//
export default class OuterWrapper extends React.PureComponent {
  state = {
    dateRange: null,
  }

  computeDataState = memoizeOne(providerData => {
    const dateRange = [
      moment(providerData.from_date).toDate(),
      moment(providerData.to_date).toDate(),
    ]
    const minDateForDetail = moment(dateRange[1]).subtract(2, 'days')
    const maxDateForDetail = moment(dateRange[1])
    return { dateRange, minDateForDetail, maxDateForDetail }
  })

  componentDidMount() {
    const { providerData } = this.props
    const {
      dateRange,
      minDateForDetail,
      maxDateForDetail,
    } = this.computeDataState(providerData)
    this.setState({ dateRange, minDateForDetail, maxDateForDetail })
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.width !== this.props.width ||
      oldProps.providerData !== this.props.providerData
    ) {
      const { providerData } = this.props
      const {
        dateRange,
        minDateForDetail,
        maxDateForDetail,
      } = this.computeDataState(providerData)
      this.setState({ dateRange, minDateForDetail, maxDateForDetail })
    }
  }
  //

  //
  dragCallbackLeft = minDate => {
    const { maxDateForDetail } = this.state
    this.setState({ minDateForDetail: minDate })
    // this.updateShortData(minDate, maxDateForDetail)
  }

  dragCallbackRight = maxDate => {
    const { minDateForDetail } = this.state
    this.setState({ maxDateForDetail: maxDate })
    // this.updateShortData(minDateForDetail, maxDate)
  }

  dragCallbackCenter = (minDate, maxDate) => {
    this.setState({ minDateForDetail: minDate, maxDateForDetail: maxDate })
    // this.updateShortData(minDate, maxDate)
  }

  render() {
    if (!this.props.width || !this.state.dateRange) {
      return null
    }

    const {
      height,
      width,
      hasRain,
      dataProvidersByType,
      providerType,
      providerData,
      currentParams,
      ...passProps
    } = this.props
    const { dateRange, minDateForDetail, maxDateForDetail } = this.state
    const miniHeight = height > 300 ? 150 : 100

    return (
      <div>
        {
          <ChartWrapper
            dataProvidersByType={dataProvidersByType}
            providerType={providerType}
            providerData={providerData}
            fromDate={minDateForDetail}
            toDate={maxDateForDetail}
            currentParams={currentParams}
            dateRange={dateRange}
            height={height - miniHeight}
            width={width}
            hasRain={hasRain}
            {...passProps}
          />
        }
        <ChartWrapper
          mini={true}
          dataProvidersByType={dataProvidersByType}
          providerType={providerType}
          providerData={providerData}
          minDate={minDateForDetail.toDate()}
          maxDate={maxDateForDetail.toDate()}
          fromDate={moment(dateRange[0])}
          toDate={moment(dateRange[1])}
          currentParams={currentParams}
          height={miniHeight}
          width={width}
          hasRain={hasRain}
          {...passProps}
          dragCallbackLeft={this.dragCallbackLeft}
          dragCallbackRight={this.dragCallbackRight}
          dragCallbackCenter={this.dragCallbackCenter}
          dateRange={dateRange}
        />
      </div>
    )
  }
}
