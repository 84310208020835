import wktParse from 'wellknown'
import { polygon } from '@turf/helpers'
import centroid from '@turf/centroid'
import { timeFormat } from 'd3-time-format'
import keys from 'lodash/keys'
import get from 'lodash/get'
import difference from 'lodash/difference'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import moment from 'moment'
import keyBy from 'lodash/keyBy'

export const getBrowserLang = () => {
  return (navigator.language || navigator.userLanguage)
    .toLowerCase()
    .indexOf('it') === 0
    ? 'it'
    : 'en-US'
}

export const processGeoItems = items =>
  (items || [])
    .filter(item => item.geom_wkt)
    .map(item => {
      const geomJson = wktParse(item.geom_wkt)
      const poly = polygon(geomJson.coordinates)
      const c = centroid(poly)
      const center = c.geometry.coordinates
      return { ...item, geomJson, center }
    })

export const fixCoordsPrecision = coords =>
  coords.map(coord => parseFloat(coord.toFixed(6)))

export const getStatusClass = (status, prefix) => {
  const alertClass =
    status === 'Irri_Warning'
      ? `${prefix}warning`
      : status === 'Irri_Critical'
      ? `${prefix}danger`
      : `${prefix}info`
  return alertClass
}

export const getStatusColor = status => {
  const alertClass =
    status === 'Irri_Warning'
      ? `orange`
      : status === 'Irri_Critical'
      ? `red`
      : `blue`
  return alertClass
}

// export const getUserLanguage = () => (navigator.language || navigator.userLanguage)
export const getUserLanguage = u => get(u, 'ui_language')
export const isEnglish = lang => {
  return ['en-us', 'en', 'us'].indexOf(lang.toLowerCase()) !== -1
}

export const getDayMonthTimeFormat = user => {
  const lang = getUserLanguage(user)
  return isEnglish(lang) ? timeFormat('%m/%d') : timeFormat('%d/%m')
}

export const getDayMonthYearMomentFormat = user => {
  const lang = getUserLanguage(user)
  return isEnglish(lang) ? 'MM-DD-YYYY' : 'DD/MM/YYYY'
}

export const getFulldDatetimeFormat = user => {
  const lang = getUserLanguage(user)
  return isEnglish(lang) ? 'MM-DD-YYYY HH:mm' : 'DD/MM/YYYY HH:mm'
}

export const getDayHourTimeFormat = user => {
  const lang = getUserLanguage(user)
  return isEnglish(lang) ? '%m/%d %H:%M' : ' %d/%m %H:%M'
}

export const getUserNumberFormat = user => {
  const lang = getUserLanguage(user)
  const formatter = new Intl.NumberFormat(lang, { maximumFractionDigits: 2 })
  return formatter.format
}

const chartHeightByProvider = {
  AttainableWater: 700,
  SoilWaterContent: 700,
  RelativeHumidity: 500,
  BatteryCharge: 300,
}
export const getChartHeight = providerType => {
  return chartHeightByProvider[providerType] || 500
}

const chartHasRainByProvider = {
  AttainableWater: true,
  SoilWaterContent: true,
  RelativeHumidity: false,
  BatteryCharge: false,
}
export const getChartHasRain = providerType => {
  return !!chartHasRainByProvider[providerType]
}

export const getSortedProviders = providers => {
  const sortedKeys = keys(chartHeightByProvider)
  return sortedKeys
    .filter(x => providers.indexOf(x) !== -1)
    .concat(difference(providers, sortedKeys))
}

export const inferContinuousPeriods = (dataPoints, dateField = 'date') => {
  if (!dataPoints || dataPoints.length < 2) {
    return []
  }
  // estimate frequency
  const minDate = moment(get(minBy(dataPoints, dateField), dateField))
  const maxDate = moment(get(maxBy(dataPoints, dateField), dateField))
  const firstDate = moment(get(dataPoints[0], dateField))
  const secondDate = moment(get(dataPoints[1], dateField))
  const duration = moment.duration(secondDate.diff(firstDate)).asMinutes()
  const fmt = firstDate._f
  // filling the period
  let datesFilled = []
  const startDate = minDate.clone()
  const byDate = keyBy(
    dataPoints.map(x => ({
      ...x,
      dateFormatted: moment(get(x, dateField)).format(fmt),
    })),
    'dateFormatted'
  )
  while (startDate.isBefore(maxDate)) {
    const idx = startDate.format(fmt)
    const datum = byDate[idx] ? byDate[idx] : { value: 0, dateFormatted: idx }
    datesFilled.push(datum)
    startDate.add(duration, 'minutes')
  }

  const contiguous = datesFilled.reduce((acc, item, idx) => {
    if (idx === 0) {
      if (item.value) {
        const start = moment(item.dateFormatted)
        const end = moment(item.dateFormatted)
        const record = {
          start,
          end,
          value: item.value,
          duration: moment
            .duration(end.add(duration, 'minutes') - start)
            .asHours(),
        }
        acc.push(record)
      }
    } else {
      if (item.value) {
        if (datesFilled[idx - 1].value) {
          let record = acc[acc.length - 1]
          record.value += item.value
          record.end = moment(item.dateFormatted)
          record.duration = moment
            .duration(record.end.add(duration, 'minutes') - record.start)
            .asHours()
          acc[acc.length - 1] = record
        } else {
          const start = moment(item.dateFormatted)
          const end = moment(item.dateFormatted)
          const record = {
            start,
            end,
            value: item.value,
            duration: moment
              .duration(end.add(duration, 'minutes') - start)
              .asHours(),
          }
          acc.push(record)
        }
      }
    }
    return acc
  }, [])

  return contiguous
}

export const getSuggestedIrrigation = (data, subFieldName) => {
  const subField = get(data, subFieldName)
  const suggestedIrrigation = get(subField, 'suggested_irrigation')
  if (!suggestedIrrigation) {
    return undefined
  }
  if (!suggestedIrrigation.duration_min && !suggestedIrrigation.quantity_m3) {
    return undefined
  }
  return suggestedIrrigation
}
