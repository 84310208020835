import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.scss'
import { Input, FormGroup, Label, FormFeedback } from 'reactstrap'
import classNames from 'classnames'
import moment from 'moment'

const arrayze = a => (Array.isArray(a) ? a : [a])

export const FieldInput = ({
  input,
  meta,
  label,
  type,
  labelProps,
  ...passProps
}) => (
  <FormGroup>
    {label && <Label>{label}</Label>}
    <Input
      type={type}
      {...input}
      {...passProps}
      invalid={!!(meta.touched && meta.error)}
    />
    {meta.touched &&
      meta.error &&
      arrayze(meta.error).map((error, i) => (
        <FormFeedback key={i}>{error}</FormFeedback>
      ))}
  </FormGroup>
)

FieldInput.defaultProps = {
  type: 'text',
}

export const FieldCheckbox = props => (
  <FormGroup check>
    <Label check>
      <Input
        type="checkbox"
        checked={props.input.value}
        {...props.input}
        disabled={props.disabled}
      />{' '}
      {props.label}
    </Label>
    {props.meta.touched &&
      props.meta.error &&
      arrayze(props.meta.error).map((error, i) => (
        <FormFeedback key={i}>{error}</FormFeedback>
      ))}
  </FormGroup>
)

export const FieldDatepicker = ({
  input: { value, onChange, onBlur, onFocus },
  label,
  meta,
  fullWidth = false,
  ...passProps
}) => {
  const selectedDate = value === '' ? null : moment(value)
  return (
    <FormGroup className={classNames({ 'datepicker-full-width': fullWidth })}>
      {label && <Label>{label}</Label>}
      <DatePicker
        className={classNames('form-control', {
          'is-invalid': meta.touched && meta.error,
        })}
        selected={selectedDate}
        onChangeRaw={data => {
          if (moment(data.target.value).isValid())
            onChange(moment(data.target.value).format())
        }}
        onChange={d => {
          if (d) {
            onChange(d.format())
          } else {
            onChange(null)
          }
        }}
        onBlur={() => onBlur()}
        onFocus={() => onFocus()}
        {...passProps}
      />
      {meta.touched &&
        meta.error &&
        arrayze(meta.error).map((error, i) => (
          <div key={i} className="invalid-feedback d-block">
            {error}
          </div>
        ))}
    </FormGroup>
  )
}

export class FieldDatepickerSplit extends React.Component {
  onChangeTime = value => {
    const {
      input: { onChange },
      timeFormat,
      dateFormat,
    } = this.props

    const dateValue = moment(this.datePicker.input.value, dateFormat).format(
      dateFormat
    )
    const v = moment(`${dateValue}${value}`, `${dateFormat}${timeFormat}`)
    console.log('onChangeTime', value, dateValue, v.format())
    onChange(v.format('YYYY-MM-DDTHH:mm'))
  }

  onChangeDate = value => {
    const {
      input: { onChange },
      timeFormat,
      dateFormat,
    } = this.props

    const timeValue = moment(this.timePicker.input.value, timeFormat).format(
      timeFormat
    )
    const v = moment(`${value}${timeValue}`, `${dateFormat}${timeFormat}`)
    console.log('onChangeDate', value, timeValue, v.format())
    onChange(v.format('YYYY-MM-DDTHH:mm'))
  }

  render() {
    const {
      input: { value, onChange, onBlur, onFocus },
      label,
      meta,
      fullWidth = false,
      timeFormat,
      dateFormat,
      ...passProps
    } = this.props

    const selectedDate = value === '' ? null : moment(value)
    const selectedTime = value === '' ? null : moment(value)

    return (
      <FormGroup className={classNames({ 'datepicker-full-width': fullWidth })}>
        {label && <Label>{label}</Label>}
        <div>
          <DatePicker
            ref={node => (this.datePicker = node)}
            showTimeSelect={false}
            className={classNames('form-control', {
              'is-invalid': meta.touched && meta.error,
              'form-control-sm': passProps.size === 'sm',
            })}
            selected={selectedDate}
            onChange={d => {
              if (d) {
                console.log('date change, ', d.format(dateFormat))
                this.onChangeDate(d.format(dateFormat))
              } else {
                this.onChangeDate(null)
              }
            }}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            dateFormat={dateFormat}
            {...passProps}
          />
        </div>

        <div>
          <DatePicker
            ref={node => (this.timePicker = node)}
            showTimeSelect={true}
            showTimeSelectOnly={true}
            className={classNames('form-control', {
              'is-invalid': meta.touched && meta.error,
              'form-control-sm': passProps.size === 'sm',
            })}
            selected={selectedTime}
            onChange={d => {
              if (d) {
                console.log('time change, ', d.format(timeFormat))
                this.onChangeTime(d.format(timeFormat))
              } else {
                this.onChangeTime(null)
              }
            }}
            onBlur={() => onBlur()}
            onFocus={() => onFocus()}
            timeFormat={timeFormat}
            dateFormat={timeFormat}
            {...passProps}
          />
        </div>

        {meta.touched &&
          meta.error &&
          arrayze(meta.error).map((error, i) => (
            <div key={i} className="invalid-feedback d-block">
              {error}
            </div>
          ))}
      </FormGroup>
    )
  }
}
