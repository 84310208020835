import React, { Component } from 'react'
import { localize } from '../localize'
import { connect } from 'react-redux'
import { getAuthUser } from 'eazy-auth'
import {
  updateLanguage,
  isUpdatingLanguage,
  isLangUpdated,
  clearUpdateLanguage,
} from '../state/languages'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap'

class UpdateLanguageModal extends Component {
  state = {
    lang: '',
  }

  save = () => {
    this.props.updateLanguage({ lang: this.state.lang })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.updated !== this.props.updated &&
      this.props.updated &&
      !prevProps.updated
    ) {
      // Clear "updated" redux state
      this.props.clearUpdateLanguage()
      // Close modal
      this.props.toggle()
    }
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.setState({
        lang: this.props.user.ui_language,
      })
    }
  }

  componentWillUnmount() {
    this.props.clearUpdateLanguage()
  }

  render() {
    const { isOpen, toggle, languages, updating, t } = this.props
    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>{t('changeLanguage')}</ModalHeader>
        <ModalBody>
          <Input
            type="select"
            value={this.state.lang}
            onChange={e => this.setState({ lang: e.target.value })}
          >
            {languages &&
              languages.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.description}
                </option>
              ))}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button disabled={updating} color="success" onClick={this.save}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    updated: isLangUpdated(state),
    updating: isUpdatingLanguage(state),
    user: getAuthUser(state),
  }),
  {
    updateLanguage,
    clearUpdateLanguage,
  }
)(localize()(UpdateLanguageModal))
