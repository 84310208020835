import React from 'react'
import { getSuggestedIrrigation } from '../utils'
import { localize } from '../localize'
import { Link } from 'react-router-dom'
import moment from 'moment'
import qs from 'query-string'

function SuggestedIrrigationLink({
  estate,
  data,
  t,
  style,
  className = 'text-center',
  linkClassName,
}) {
  const suggestedIrrigation = getSuggestedIrrigation(
    data,
    'water_deficit_status'
  )
  if (suggestedIrrigation) {
    const params = {
      tabName: 'irrigation',
      suggested_date: moment(suggestedIrrigation.date).format(
        'YYYY-MM-DDTHH:mm'
      ),
      id_field: data.id_field,
      id_irrigation_sector: data.id,
      duration: suggestedIrrigation.duration_min,
      applied_to: data.id_field ? 'field' : 'irrigation_sector',
    }

    return (
      <div className={className} style={style}>
        <Link
          to={`/estates/${estate.id}/?${qs.stringify(params)}`}
          className={linkClassName}
        >
          <small>{t('addIrrigation')}</small>
        </Link>
      </div>
    )
  }

  return null
}

export default localize()(SuggestedIrrigationLink)
