import { combineReducers } from 'redux'
import { DELETE_ESTATE_IRRIGATION_EVENT } from './estates'
export const SET_CURRENT_IRRIGATION = 'SET_CURRENT_IRRIGATION'
export const UNSET_CURRENT_IRRIGATION = 'UNSET_CURRENT_IRRIGATION'

export const setCurrentIrrigation = payload => ({
  type: SET_CURRENT_IRRIGATION,
  payload,
})

export const unsetCurrentIrrigation = () => ({
  type: UNSET_CURRENT_IRRIGATION,
})

const currentIrrigationReducer = (prevState = null, { type, payload }) => {
  if (type === SET_CURRENT_IRRIGATION) {
    return payload
  }
  if (type === UNSET_CURRENT_IRRIGATION) {
    return null
  }
  if (
    type === `${DELETE_ESTATE_IRRIGATION_EVENT}_SUCCESS` &&
    prevState &&
    prevState.id === payload.data
  ) {
    return null
  }
  return prevState
}

export const irrigationEditReducer = combineReducers({
  currentIrrigation: currentIrrigationReducer,
})

export const getCurrentIrrigation = state =>
  state.irrigationEdit.currentIrrigation
