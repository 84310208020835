import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { Link } from 'react-router-dom'
import { Table, Card, CardHeader, CardBody } from 'reactstrap'
import { get } from 'lodash'
import {
  loadSectorFields,
  unloadSectorFields,
  getSectorFields,
  getSector,
  loadSectorFieldsDataDict,
  unloadSectorFieldsDataDict,
  makeGetSectorsFieldData,
} from '../state/sectors'
import { getEstate } from '../state/estates'
import { getStatusClass } from '../utils'
import {
  getDayMonthYearMomentUserFormat,
  getNumberFormat,
} from '../state/languages'
import moment from 'moment'

class SectorFieldsTable extends React.PureComponent {
  componentWillMount() {
    const { sector } = this.props
    this.props.loadSectorFields(sector.id, { includeStatus: true })
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.fields !== this.props.fields &&
      this.props.fields &&
      this.props.fields.length > 0
    ) {
      this.props.fields.forEach(field => {
        this.props.loadSectorFieldsDataDict(field.id)
      })
    }
  }

  componentWillUnmount() {
    this.props.unloadSectorFields()
    if (this.props.fields && this.props.fields.length > 0) {
      this.props.fields.forEach(field => {
        this.props.unloadSectorFieldsDataDict(field.id)
      })
    }
  }

  render() {
    const { fields, estate, t, numberFormat } = this.props
    //const today = moment().format('YYYY-MM-DDTHH') + ':00:00'

    return (
      <Card className="my-3 w-100" style={{ overflowX: 'auto' }}>
        <CardHeader>Fields status</CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>{t('field')}</th>
                <th>{t('status')}</th>
                <th>{t('availableWater')}</th>
                <th>{t('waterDeficit')}</th>
                <th>{t('nextIrrigation')}</th>
              </tr>
            </thead>
            <tbody>
              {fields &&
                fields.length > 0 &&
                fields.map(field => {
                  //const fieldData = getSectorFieldsData(field.id)
                  //const waterContentData = get(fieldData, 'data.water_content.values')
                  //const waterContentUom = get(fieldData, 'data.water_content.unit_of_measure')
                  //const dataByDate = keyBy(waterContentData, x => x.date)
                  // const datum = get(dataByDate, today)

                  // const datum = last(waterContentData)
                  const suggestedIrrigation = get(
                    field,
                    'water_deficit_status.suggested_irrigation'
                  )
                  const suggestedIrrigationDate = get(
                    suggestedIrrigation,
                    'date'
                  )

                  // const availableWater = get(field, 'water_deficit_status.available_water')
                  const soilWaterDate = get(
                    field,
                    'water_deficit_status.soil_water.date'
                  )

                  return (
                    <tr key={field.id}>
                      <td>
                        <Link to={`/estates/${estate.id}/fields/${field.id}`}>
                          {field.name}
                        </Link>
                      </td>
                      <td
                        className={`xtext-center ${getStatusClass(
                          get(field, 'water_deficit_status.status'),
                          'alert-'
                        )}`}
                      >
                        {get(field, 'water_deficit_status.status')}
                      </td>

                      <td>
                        {numberFormat(
                          get(
                            field,
                            'water_deficit_status.soil_water.attainable_water',
                            0
                          )
                        )}{' '}
                        %
                        {soilWaterDate && (
                          <div>
                            <small>
                              (
                              {moment(soilWaterDate).format(
                                `${this.props.dayMonthYearMomentUserFormat} HH:mm`
                              )}
                              )
                            </small>
                          </div>
                        )}
                      </td>
                      <td>
                        {numberFormat(
                          get(
                            field,
                            'water_deficit_status.soil_water.water_deficit',
                            0
                          )
                        )}{' '}
                        mm
                        {soilWaterDate && (
                          <div>
                            <small>
                              (
                              {moment(soilWaterDate).format(
                                `${this.props.dayMonthYearMomentUserFormat} HH:mm`
                              )}
                              )
                            </small>
                          </div>
                        )}
                      </td>
                      <td>
                        <div>
                          {suggestedIrrigationDate &&
                            moment(suggestedIrrigationDate).format(
                              `${this.props.dayMonthYearMomentUserFormat} HH:mm`
                            )}
                        </div>
                        <div className="d-flex justify-content-between">
                          <div style={{ flex: 1 }}>
                            {numberFormat(
                              get(suggestedIrrigation, 'quantity_m3', 0)
                            )}{' '}
                            m<sup>3</sup>
                          </div>
                          <div style={{ flex: 1 }}>
                            {numberFormat(
                              get(suggestedIrrigation, 'duration_min', 0)
                            )}{' '}
                            min
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    )
  }
}

const makeMapStateToProps = () => {
  const getSectorFieldsData = makeGetSectorsFieldData()
  const mapStateToProps = state => ({
    estate: getEstate(state),
    sector: getSector(state),
    fields: getSectorFields(state),
    getSectorFieldsData: fieldId => getSectorFieldsData(state, fieldId),
    dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
    numberFormat: getNumberFormat(state),
  })

  return mapStateToProps
}

const mapDispatchToProps = {
  loadSectorFields,
  unloadSectorFields,
  loadSectorFieldsDataDict,
  unloadSectorFieldsDataDict,
}

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(localize()(SectorFieldsTable))
