import request from 'superagent'
import { combineReducers } from 'redux'
import { fork, put } from 'redux-saga/effects'
import { getAuthUser, patchUser } from 'eazy-auth'
import { rj } from 'redux-rocketjump'
import { takeEveryAndCancel } from 'redux-rocketjump'
import { authApiCall, withToken } from './auth'
import { setLanguage } from 'redux-i18n'
import { API_URL } from './consts'
import {
  getDayMonthYearMomentFormat,
  getFulldDatetimeFormat,
  getDayHourTimeFormat,
  getDayMonthTimeFormat,
  getUserNumberFormat,
} from '../utils'
import { createSelector } from 'reselect'
import moment from 'moment'

const GET_LANGUAGES = 'GET_LANGUAGES'
export const {
  actions: { load: loadLanguages },
  selectors: { getData: getLanguages },
  reducer: languagesListReducer,
  saga: languagesListSaga,
} = rj({
  type: GET_LANGUAGES,
  state: 'languages.list',
  effectCaller: authApiCall,
  effect: t => params =>
    withToken(t, request.get(`${API_URL}ui-languages`))
      .query(params)
      .then(({ body }) => body),
})()

const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE'
export const {
  actions: { load: updateLanguage, unload: clearUpdateLanguage },
  selectors: { isLoading: isUpdatingLanguage, getData: isLangUpdated },
  reducer: updateLanguageReducer,
  saga: updateLanguageSaga,
} = rj({
  type: UPDATE_LANGUAGE,
  takeEffect: takeEveryAndCancel,
  state: 'languages.update',
  effectCaller: authApiCall,
  successEffect: function*(lang) {
    yield put(patchUser({ ui_language: lang }))
    yield put(setLanguage(lang))
    try {
      const momentLocale = lang.split('-')[0]
      moment.locale(momentLocale)
    } catch (err) {
      console.error('problem setting locale', lang)
    }
  },
  effect: t => ({ lang }) =>
    withToken(t, request.put(`${API_URL}change-ui-language`))
      .send({
        preferredLanguage: lang,
      })
      .then(() => lang),
})()

export const reducer = combineReducers({
  list: languagesListReducer,
  update: updateLanguageReducer,
})

export const saga = function*() {
  yield fork(languagesListSaga)
  yield fork(updateLanguageSaga)
}

export const getDayMonthYearMomentUserFormat = createSelector(
  getAuthUser,
  user => (!user ? null : getDayMonthYearMomentFormat(user))
)
export const getDayMonthTimeUserFormat = createSelector(
  getAuthUser,
  user => (!user ? null : getDayMonthTimeFormat(user))
)
export const getFullDatetimeUserFormat = createSelector(
  getAuthUser,
  user => (!user ? null : getFulldDatetimeFormat(user))
)

export const getDayHourUserTimeFormat = createSelector(
  getAuthUser,
  user => (!user ? null : getDayHourTimeFormat(user))
)

export const getNumberFormat = createSelector(
  getAuthUser,
  user => {
    if (!user) {
      return x => x
    }
    return getUserNumberFormat(user)
  }
)
