import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import Layout from '../components/Layout'
import Spinner from '../components/Spinner'
import { getAuthUser } from 'eazy-auth'
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import {
  loadProfile,
  getProfileLoading,
  getProfile,
  unloadProfile,
  makeChangePassword,
} from '../state/profile'
import { loadLanguages, getLanguages } from '../state/languages'
import PasswordChangeForm from '../components/PasswordChangeForm'
import UpdateLanguageModal from '../components/UpdateLanguageModal'
import versionData from '../data/version.json'

class Profile extends React.PureComponent {
  state = {
    passwordFormShown: false,
    showLangModal: false,
    showSaved: false,
  }

  togglePasswordForm = () => {
    this.setState({ passwordFormShown: !this.state.passwordFormShown })
  }

  toggleShowSaved = () => {
    this.setState({ showSaved: !this.state.showSaved })
  }

  toggleShowLang = () => {
    this.setState(prevState => ({ showLangModal: !prevState.showLangModal }))
  }

  componentDidMount() {
    this.props.loadProfile()
    this.props.loadLanguages()
  }

  componentWillUnmount() {
    this.props.unloadProfile()
  }

  render() {
    const { profileLoading, user, t, languages } = this.props

    return (
      <Layout style={{ paddingBottom: 64 }}>
        <Layout.Content className="container-fluid h-100">
          <div className="mt-3">
            {profileLoading && (
              <div className="text-center p-5">
                <Spinner></Spinner>
              </div>
            )}
            {user && (
              <div>
                <Card>
                  <CardHeader>{t('userProfile')}</CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs={12} sm={12} md={4} className="p-2">
                        <b>{t('firstName')}:</b> {user.first_name}
                      </Col>
                      <Col xs={12} sm={12} md={4} className="p-2">
                        <b>{t('lastName')}:</b> {user.first_name}
                      </Col>
                      <Col xs={12} sm={12} md={4} className="p-2">
                        <b>{t('language')}:</b> {user.ui_language}{' '}
                        <Button
                          onClick={() => this.toggleShowLang(user.ui_language)}
                          outline
                          color="primary"
                          size="sm"
                          className="ml-2"
                        >
                          <i className="fa fa-edit" />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={4} className="p-2">
                        <b>Email: </b> {user.email}
                      </Col>
                      <Col xs={12} sm={12} md={4} className="p-2">
                        <b>Username: </b> {user.user_name}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="mt-4">
                  <CardHeader>{t('userCredentials')}</CardHeader>
                  <CardBody>
                    {!this.state.passwordFormShown && (
                      <div className="text-center">
                        <Button onClick={this.togglePasswordForm}>
                          {t('changePassword')}
                        </Button>
                      </div>
                    )}

                    {this.state.passwordFormShown && (
                      <PasswordChangeForm
                        onSubmit={this.props.changePassword}
                        onSubmitSuccess={() => {
                          this.togglePasswordForm()
                          this.toggleShowSaved()
                        }}
                        cancelCallback={this.togglePasswordForm}
                      />
                    )}
                  </CardBody>
                </Card>
              </div>
            )}

            <nav class="navbar navbar-light bg-light fixed-bottom ">
              <div className="navbar-text">
                <small>IRRISmart v.{versionData.version}&nbsp;{versionData.releaseDate}.{versionData.build}</small>
              </div>
            </nav>

            <UpdateLanguageModal
              isOpen={this.state.showLangModal}
              toggle={this.toggleShowLang}
              languages={languages}
            />

            <Modal
              // fade={false}
              isOpen={this.state.showSaved}
              toggle={this.toggleShowSaved}
              className={this.props.className}
            >
              <ModalHeader toggle={this.toggleShowSaved}>
                {t('passwordSaved')}
              </ModalHeader>
              <ModalBody>{t('newPasswordSaved')}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.toggleShowSaved}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    user: getAuthUser(state),
    languages: getLanguages(state),
    profile: getProfile(state),
    profileLoading: getProfileLoading(state),
    changePassword: makeChangePassword(state),
  }),
  {
    loadProfile,
    unloadProfile,
    loadLanguages,
  }
)(localize()(Profile))
