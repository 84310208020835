import React from 'react'
import { Provider } from 'react-redux'
import I18n from 'redux-i18n'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { GuestRoute, AuthRoute, MaybeAuthRoute } from 'eazy-auth'
import store from './state'
import Login from './pages/Login'
import Home from './pages/Home'
import Profile from './pages/Profile'
import SegnalaNotifica from './pages/SegnalaNotifica'
// import ProfiloUpdate from './pages/ProfiloUpdate'
import Recover from './pages/Recover'
import Reset from './pages/Reset'
import Estates from './pages/Estates'
import Estate from './pages/Estate'
import { getBrowserLang } from './utils'
import moment from 'moment'
import 'moment/locale/it'
import 'moment/locale/es'

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
})
moment.updateLocale('it', {
  week: {
    dow: 1,
  },
})
moment.updateLocale('es', {
  week: {
    dow: 1,
  },
})
moment.locale('en')

class App extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>
        <I18n
          translations={{}}
          initialLang={getBrowserLang()}
          useReducer={true}
        >
          <Router>
            <Switch>
              <AuthRoute path="/" exact component={Home} />
              <AuthRoute path="/profile" exact component={Profile} />
              <MaybeAuthRoute
                path="/SegnalaNotifica"
                exact
                component={SegnalaNotifica}
              />
              {/* <AuthRoute path='/profilo/update' exact component={ProfiloUpdate} /> */}
              <GuestRoute
                path="/login"
                exact
                component={Login}
                redirectToReferrer={false}
              />
              <Route path="/recover" exact component={Recover} />
              <Route path="/reset/:token" exact component={Reset} />
              <AuthRoute path="/estates" exact component={Estates} />
              <AuthRoute path="/estates/:estateId" component={Estate} />
            </Switch>
          </Router>
        </I18n>
      </Provider>
    )
  }
}
export default App
