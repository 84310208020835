import { API_URL } from '../state/consts'
import request from 'superagent'

const downloadCSVFile = (data, fileName) => {
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', fileName)
  window.document.body.appendChild(link)
  link.click()
  window.document.body.removeChild(link)
}

export const downloadMeteoCSV = (stationId, params, fileName, token) => {
  const url = `${API_URL}meteo-stations/${stationId}/csv`
  return request
    .get(url)
    .query(params)
    .set('Authorization', `bearer ${token}`)
    .then(resp => {
      const data =
        'data:application/octet-stream,' + encodeURIComponent(resp.text)
      downloadCSVFile(data, fileName)
    })
}

export const downloadNodeCSV = (nodeId, params, fileName, token) => {
  const url = `${API_URL}data-providers/nodes/${nodeId}/csv`
  return request
    .get(url)
    .query(params)
    .set('Authorization', `bearer ${token}`)
    .then(resp => {
      const data =
        'data:application/octet-stream,' + encodeURIComponent(resp.text)
      downloadCSVFile(data, fileName)
    })
}

export const downloadSensorCSV = (sensorId, params, fileName, token) => {
  const url = `${API_URL}data-providers/sensors/${sensorId}/csv`
  return request
    .get(url)
    .query(params)
    .set('Authorization', `bearer ${token}`)
    .then(resp => {
      const data =
        'data:application/octet-stream,' + encodeURIComponent(resp.text)
      downloadCSVFile(data, fileName)
    })
}

export const downloadFieldCSV = (fieldId, params, fileName, token) => {
  const url = `${API_URL}fields/${fieldId}/csv`
  return request
    .get(url)
    .query(params)
    .set('Authorization', `bearer ${token}`)
    .then(resp => {
      const data =
        'data:application/octet-stream,' + encodeURIComponent(resp.text)
      downloadCSVFile(data, fileName)
    })
}
