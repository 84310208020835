import {
  makeAuthFlow,
  makeAuthReducer,
  AUTH_WITH_TOKEN_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
  getAuthUser,
  // getAuthAccessToken as getToken
} from 'eazy-auth'
import { setLanguage, setTranslations } from 'redux-i18n'
// import { createSelector } from 'reselect'
import { fork, take, put, select, call } from 'redux-saga/effects'
import request from 'superagent'
import { API_URL } from './consts'
import { getBrowserLang } from '../utils'
import moment from 'moment'

// Inject token in Authorization header when provided
export const withToken = (token, baseRequest) =>
  token ? baseRequest.set('Authorization', `bearer ${token}`) : baseRequest

const login = ({ username, password }) =>
  request
    .post(`${API_URL}sign-in`)
    .send({ username, password })
    .then(({ body }) => ({
      access_token: body.token,
      refresh_token: body.refresh_token,
      // expires: 3,
    }))

const refreshToken = refresh_token =>
  request
    .post(`${API_URL}refresh-token`)
    .query({ token: refresh_token })
    .then(({ body }) => ({
      access_token: body.token,
      refresh_token: body.refresh_token,
      // expires: 3,
    }))

// const me = (token) => new Promise((resolve) => resolve({username: 'Mauro'}))
const me = token =>
  withToken(token, request.get(`${API_URL}profile`)).then(({ body }) => ({
    ...body,
  }))

const { authFlow, authApiCall } = makeAuthFlow({
  meCall: me,
  loginCall: login,
  refreshTokenCall: refreshToken,
})

const fetchTranslations = () =>
  request.get('/translations.json').then(({ body }) => body)

function* watchLang() {
  try {
    const translations = yield call(fetchTranslations)
    yield put(setTranslations(translations))
  } catch (e) {
    console.error('Failed to load translations', e)
  }
  while (true) {
    yield take([AUTH_WITH_TOKEN_SUCCESS, LOGIN_SUCCESS])
    const user = yield select(getAuthUser)
    if (user.ui_language) {
      yield put(setLanguage(user.ui_language))
      try {
        const momentLocale = user.ui_language.split('-')[0]
        moment.locale(momentLocale)
      } catch (err) {
        console.error('problem setting locale', user.ui_language)
      }
    }
    yield take(LOGOUT)
    yield put(setLanguage(getBrowserLang()))
  }
}

function* saga() {
  yield fork(authFlow)
  yield fork(watchLang)
}

// export const getUpdateProfilo = createSelector(
//   getToken,
//   t => data => withToken(t, request.put('/api/profilo/'))
//     .send(data)
//     .then(({ body }) => body)
// )

// export const getChangePassword = createSelector(
//   getToken,
//   t => data => withToken(t, request.put('/api/profilo/change-password/'))
//     .send(data)
//     .then(({ body }) => body)
// )

const reducer = makeAuthReducer()

export { saga, authApiCall, reducer }
