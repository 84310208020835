import { combineReducers } from 'redux'

export const SET_MAP_BOUNDS = 'SET_MAP_BOUNDS'
export const setMapBounds = bounds => ({ type: SET_MAP_BOUNDS, bounds })

export const SET_MAP_PANEL = 'SET_MAP_PANEL'
export const setMapPanel = panel => ({ type: SET_MAP_PANEL, panel })

export const SET_MAP_OBJECT = 'SET_MAP_OBJECT'
export const UNSET_MAP_OBJECT = 'UNSET_MAP_OBJECT'
export const setMapObject = obj => ({ type: SET_MAP_OBJECT, obj })
export const unsetMapObject = () => ({ type: SET_MAP_OBJECT, obj: null })

export const SET_SENSOR_NODE_MODAL = 'SET_SENSOR_NODE_MODAL'
export const UNSET_SENSOR_NODE_MODAL = 'UNSET_SENSOR_NODE_MODAL'
export const setSensorNodeModal = obj => ({ type: SET_SENSOR_NODE_MODAL, obj })
export const unsetSensorNodeModal = () => ({
  type: UNSET_SENSOR_NODE_MODAL,
  obj: null,
})

export const SET_UI_CACHE = 'SET_UI_CACHE'
export const setUiCache = (key, value) => ({
  type: SET_UI_CACHE,
  payload: {
    key,
    value,
  },
})

const mapBoundsReducer = (prevState = null, { type, bounds }) => {
  if (type === SET_MAP_BOUNDS) {
    return bounds
  }
  return prevState
}

const mapPanelReducer = (prevState = 'fields', { type, panel }) => {
  if (type === SET_MAP_PANEL) {
    return panel
  }
  return prevState
}

const mapObjectReducer = (prevState = null, { type, obj }) => {
  if (type === SET_MAP_OBJECT) {
    return obj
  }
  if (type === UNSET_MAP_OBJECT) {
    return null
  }
  return prevState
}

const sensorNodeModalReducer = (prevState = null, { type, obj }) => {
  if (type === SET_SENSOR_NODE_MODAL) {
    return obj
  }
  if (type === UNSET_SENSOR_NODE_MODAL) {
    return null
  }
  return prevState
}

const uiCacheReducer = (prevState = {}, { type, payload }) => {
  if (type === SET_UI_CACHE) {
    const { key, value } = payload
    return {
      ...prevState,
      [key]: value,
    }
  }
  return prevState
}

export const getMapBounds = state => state.ui.mapBounds
export const getMapPanel = state => state.ui.mapPanel
export const getMapObject = state => state.ui.mapObject
export const getSensorNodeModal = state => state.ui.sensorNodeModal
export const getUiCache = state => state.ui.uiCache

export const uiReducer = combineReducers({
  mapBounds: mapBoundsReducer,
  mapPanel: mapPanelReducer,
  mapObject: mapObjectReducer,
  sensorNodeModal: sensorNodeModalReducer,
  uiCache: uiCacheReducer,
})
