import React from 'react'
import ReactDOM from 'react-dom'
import { Group } from '@vx/group'
import { get } from 'lodash'
import { extent } from 'd3-array'
import { AxisBottom, AxisLeft } from '@vx/axis'
import { scaleLinear } from 'd3-scale'
import { LinePath } from '@vx/shape'
import Spinner from '../components/Spinner'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'

export default class PhenologyChart extends React.PureComponent {
  state = {
    guideX: null,
    guideY: null,
    datum: null,
  }

  resetGuides = () => {
    this.setState({
      guideX: null,
      guideY: null,
      datum: null,
    })
  }

  setGuides = (x, y, datum) => {
    this.setState({
      guideX: x,
      guideY: y,
      datum,
    })
  }

  isCurrentX = item => {
    return (
      this.state.guideX && item.doy.toString() === this.state.guideX.toString()
    )
  }

  render() {
    const { data, height, width, xLabel, yLabel } = this.props

    if (!data) {
      return (
        <svg width={width} height={height}>
          <Spinner className="text-muted" />
        </svg>
      )
    }

    const margin = {
      top: 50,
      left: 50,
      bottom: 50,
      right: 50,
    }

    const chartHeight = height - margin.top - margin.bottom
    const chartWidth = width - margin.left - margin.right
    const values = get(data, 'phenology_chart_values', [])

    if (!values) {
      return null
    }

    const xDomain = extent(values, v => v.doy)
    const xScale = scaleLinear()
      .range([0, chartWidth])
      .domain(xDomain)
    const yScale = scaleLinear()
      .range([chartHeight, 0])
      // .domain([yDomain[0], yDomain[1] + yDomain[1] * 0.1])
      .domain([0, 1])

    return (
      <div>
        <i
          className="text-muted pointer float-right fa fa-download download-button"
          onClick={() => this.downloadPng()}
        ></i>
        <svg
          width={width}
          height={height}
          style={{ position: 'relative' }}
          viewBox={`0 0 500 500`}
        >
          <Group onMouseLeave={() => this.resetGuides()}>
            <AxisLeft
              scale={yScale}
              left={margin.left}
              top={margin.top}
              tickFormat={i => i}
              tickLength={4}
              label={yLabel}
            />
            <AxisBottom
              scale={xScale}
              left={margin.left}
              top={margin.top + chartHeight}
              tickLength={4}
              label={xLabel}
            />
            <Group left={margin.left} top={margin.top}>
              <LinePath
                data={values}
                xScale={xScale}
                yScale={yScale}
                y={item => item.value}
                x={item => item.doy}
              />
              {values.map((item, i) => (
                <g
                  key={i}
                  onMouseEnter={e => {
                    this.setGuides(xScale(item.doy), yScale(item.value), item)
                  }}
                >
                  <circle cx={xScale(item.doy)} cy={yScale(item.value)} r={3} />
                </g>
              ))}

              {/* guides */}
              {this.state.guideX && this.state.guideY && (
                <Group>
                  <line
                    x1={this.state.guideX}
                    x2={this.state.guideX}
                    y1={chartHeight}
                    y2={this.state.guideY}
                    className="chart-guide"
                  />
                  <line
                    x1={0}
                    x2={this.state.guideX}
                    y1={this.state.guideY}
                    y2={this.state.guideY}
                    className="chart-guide"
                  />
                </Group>
              )}
            </Group>
          </Group>
        </svg>
      </div>
    )
  }

  downloadPng = e => {
    const { chartLabel } = this.props
    var node = ReactDOM.findDOMNode(this)
    function filter(node) {
      return node.tagName !== 'I'
    }
    domtoimage
      .toBlob(node, { bgcolor: '#fff', filter: filter })
      .then(function(blob) {
        FileSaver.saveAs(blob, chartLabel || `${'download'}.png`)
      })
  }
}

PhenologyChart.defaultProps = {
  size: 500,
}
