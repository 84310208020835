import React from 'react'
import { localize } from '../localize'
import { connect } from 'react-redux'
import { getDayMonthYearMomentUserFormat } from '../state/languages'
import moment from 'moment'

class DeviceData extends React.PureComponent {
  render() {
    const {
      device,
      columnClassName = 'col-sm-6',
      t,
      latitude,
      longitude,
      elevation,
    } = this.props

    return (
      <div className="row no-gutters">
        <div className={columnClassName}>
          <div className="list-group">
            {device && (
              <>
                <div className="list-group-item">
                  {t('company')}: {device.company}
                </div>
                <div className="list-group-item">
                  {t('deviceType')}: {device.device_type}
                </div>
                <div className="list-group-item">
                  {t('model')}: {device.model}
                </div>
              </>
            )}
            {latitude !== undefined && longitude !== undefined && (
              <div className="list-group-item">
                {t('coordinates')}: lat {latitude}, lon {longitude}
              </div>
            )}
            {elevation !== undefined && (
              <div className="list-group-item">
                {t('elevation')}: {elevation}
              </div>
            )}
          </div>
        </div>
        {device && (
          <div className={columnClassName}>
            <div className="list-group">
              <div className="list-group-item">
                {t('serialNumber')}: {device.serial_number}
              </div>
              <div className="list-group-item">
                {t('purchaseDate')}:{' '}
                {moment(device.purchase_date).format(
                  this.props.dayMonthYearMomentUserFormat
                )}
              </div>
              <div className="list-group-item">
                {t('timePeriod')}: {device.time_period}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default localize()(
  connect(state => ({
    dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
  }))(DeviceData)
)
