import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { getSuggestedIrrigation } from '../utils'
import {
  getFieldIrrigationPreview,
  loadFieldIrrigationPreview,
  getFieldIrrigationPreviewLoading,
} from '../state/fields'
import {
  getSectorIrrigationPreview,
  loadSectorIrrigationPreview,
  getSectorIrrigationPreviewLoading,
} from '../state/sectors'
import { getEstate } from '../state/estates'
import { localize } from '../localize'
import moment from 'moment'
import IrrigationPreviewForm from './IrrigationPreviewForm'
import IWGraph from './IWGraph'
import Spinner from './Spinner'
import { ParentSize } from '@vx/responsive'
import qs from 'query-string'
import { Link, withRouter } from 'react-router-dom'
import keyBy from 'lodash/keyBy'

function IrrigationPreviewLink_({ data, setter, t }) {
  const suggestedIrrigation = getSuggestedIrrigation(
    data,
    'water_deficit_status'
  )
  if (!suggestedIrrigation) {
    return null
  }
  return (
    <div className="text-center">
      <a href="#" className="text-white pointer" onClick={() => setter(data)}>
        <small>{t('previewIrrigationLink')}</small>
      </a>
    </div>
  )
}

const emptySuggestedIrrigation = {
  quantity_m3: 0,
  duration_min: 0,
}

export const IrrigationPreviewLink = localize()(IrrigationPreviewLink_)

const baseFieldPayload = {
  // amount: 0,
  // use_duration: true,
  duration: 0,
  alleged_irrigation: null,
  id: 0,
  type: 'irrigation',
}

function IrrigationPreview({
  irrigationPreviewParams,
  cancel,
  t,
  estate,
  detailIrrigationPreviewField,
  loadFieldIrrigationPreview,
  detailIrrigationPreviewSector,
  loadSectorIrrigationPreview,
  detailIrrigationPreviewFieldLoading,
  detailIrrigationPreviewSectorLoading,
  history,
}) {
  const suggestedIrrigation = useMemo(() => {
    return (
      getSuggestedIrrigation(irrigationPreviewParams, 'water_deficit_status') ||
      emptySuggestedIrrigation
    )
  }, [irrigationPreviewParams])

  const fieldId = useMemo(() => {
    return irrigationPreviewParams && irrigationPreviewParams.id_field
  }, [irrigationPreviewParams])

  const sectorId = useMemo(() => {
    return (
      irrigationPreviewParams && irrigationPreviewParams.id_irrigation_sector
    )
  }, [irrigationPreviewParams])

  const [currentValues, setCurrentValues] = useState(suggestedIrrigation)
  useEffect(() => {
    setCurrentValues(suggestedIrrigation)
  }, [suggestedIrrigation])

  const durationString = useMemo(() => {
    const duration = moment.duration(
      suggestedIrrigation.duration_min,
      'minutes'
    )
    const h = duration.hours()
    const m = duration.minutes()
    return h ? `${h}h ${m}min` : `${m}min`
  }, [suggestedIrrigation.duration_min])

  const makePreviewCall = useCallback(
    params => {
      if (!irrigationPreviewParams) {
        return
      }
      // console.log(
      //   'suggestedIrrigation',
      //   params,
      //   irrigationPreviewParams,
      //   fieldId
      // )

      if (fieldId && !sectorId) {
        const payload = {
          ...baseFieldPayload,
          applied_to: 'field',
          id_field: fieldId,
          id_irrigation_sector: 0,
          id_estate: estate.id,
          duration: params.use_duration ? params.duration_min : 0,
          use_duration: params.use_duration,
          amount: params.use_duration ? 0 : parseInt(params.quantity_m3),
          date: params.date,
        }
        loadFieldIrrigationPreview(fieldId, payload)
      }

      if (sectorId) {
        const payload = {
          ...baseFieldPayload,
          applied_to: 'irrigationsector',
          id_field: 0,
          id_irrigation_sector: sectorId,
          id_estate: estate.id,
          duration: params.duration_min,
          amount: 0,
          use_duration: true,
          date: params.date,
        }
        loadSectorIrrigationPreview(sectorId, payload)
      }
    },
    [
      irrigationPreviewParams,
      fieldId,
      sectorId,
      estate.id,
      loadFieldIrrigationPreview,
      loadSectorIrrigationPreview,
    ]
  )

  useEffect(() => {
    if (!suggestedIrrigation || !suggestedIrrigation.quantity_m3) {
      return
    }
    makePreviewCall(suggestedIrrigation)
  }, [makePreviewCall, suggestedIrrigation])

  function linkToIrrigation(date, id_field, id_irrigation_sector, duration) {
    const params = {
      tabName: 'irrigation',
      suggested_date: moment(suggestedIrrigation.date).format(
        'YYYY-MM-DDTHH:mm'
      ),
      id_field,
      id_irrigation_sector,
      duration,
      applied_to: id_field ? 'field' : 'irrigation_sector',
    }

    history.push({
      pathname: `/estates/${estate.id}/`,
      search: qs.stringify(params),
    })
  }

  const fieldsDataForSector = useMemo(() => {
    if (detailIrrigationPreviewSector && detailIrrigationPreviewSector.data) {
      return keyBy(detailIrrigationPreviewSector.data, 'field_id')
    }
    return null
  }, [detailIrrigationPreviewSector])

  const [currentFieldForSector, setCurrentFieldForSector] = useState(null)
  useEffect(() => {
    setCurrentFieldForSector(null)
  }, [irrigationPreviewParams])

  useEffect(() => {
    if (fieldsDataForSector) {
      const k = Object.keys(fieldsDataForSector)
      if (k.length) {
        setCurrentFieldForSector(k[0])
      }
    }
  }, [fieldsDataForSector])

  return (
    <div>
      <Modal isOpen={!!irrigationPreviewParams} size="lg">
        <ModalHeader toggle={cancel}>{t('previewIrrigation')}</ModalHeader>
        <ModalBody>
          <div className="p-2">
            <a onClick={() => setCurrentValues({ ...suggestedIrrigation })}>
              Suggested irrigation
            </a>
            : {suggestedIrrigation.quantity_m3} m<sup>3</sup> ({durationString})
          </div>
          <div className="row">
            <div className="col-sm-12">
              <IrrigationPreviewForm
                appliedTo={sectorId ? 'irrigation_sector' : 'field'}
                initialValues={{
                  use_duration: true,
                  duration: suggestedIrrigation.duration_min,
                  amount: suggestedIrrigation.quantity_m3,
                }}
                onSubmit={values => {
                  // console.log('values', values)
                  makePreviewCall({
                    ...suggestedIrrigation,
                    use_duration: values.use_duration,
                    quantity_m3: values.amount,
                    duration_min: values.duration,
                  })
                }}
                applyValues={(duration, amount) => {
                  linkToIrrigation(
                    suggestedIrrigation.date,
                    fieldId,
                    sectorId,
                    duration
                  )
                }}
              ></IrrigationPreviewForm>
            </div>
            <div className="col-sm-12">
              {(detailIrrigationPreviewFieldLoading ||
                detailIrrigationPreviewSectorLoading) && (
                <div className="text-center p-2">
                  {t('loading')}
                  <br></br>
                  <Spinner></Spinner>
                </div>
              )}

              <div className="mt-4">
                {detailIrrigationPreviewField && !sectorId && (
                  <ParentSize>
                    {({ width: w, height: h }) => {
                      return (
                        <IWGraph
                          chartId={'attainable-water'}
                          showWaterContent={false}
                          showAllAtStart={true}
                          width={w}
                          height={500}
                          data={detailIrrigationPreviewField}
                        />
                      )
                    }}
                  </ParentSize>
                )}
              </div>
              {detailIrrigationPreviewSector && currentFieldForSector && (
                <div>
                  <div className="form-group mb-2">
                    <label>{t('choose_field')}</label>
                    <select
                      className="form-control"
                      value={currentFieldForSector}
                      onChange={e => {
                        setCurrentFieldForSector(e.target.value)
                      }}
                    >
                      {Object.keys(fieldsDataForSector).map(k => (
                        <option key={k} value={k}>
                          {fieldsDataForSector[k].field_name} ({k})
                        </option>
                      ))}
                    </select>

                    {!(
                      detailIrrigationPreviewFieldLoading ||
                      detailIrrigationPreviewSectorLoading
                    ) &&
                      currentFieldForSector && (
                        <ParentSize>
                          {({ width: w, height: h }) => {
                            return (
                              <IWGraph
                                chartId={'attainable-water'}
                                showWaterContent={false}
                                showAllAtStart={true}
                                width={w}
                                height={500}
                                data={{
                                  ...detailIrrigationPreviewSector,
                                  data:
                                    fieldsDataForSector[currentFieldForSector],
                                }}
                              />
                            )
                          }}
                        </ParentSize>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  detailIrrigationPreviewField: getFieldIrrigationPreview(state),
  detailIrrigationPreviewFieldLoading: getFieldIrrigationPreviewLoading(state),
  detailIrrigationPreviewSector: getSectorIrrigationPreview(state),
  detailIrrigationPreviewSectorLoading: getSectorIrrigationPreviewLoading(
    state
  ),
  estate: getEstate(state),
})

const mapDispatchToProps = {
  loadFieldIrrigationPreview,
  loadSectorIrrigationPreview,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(localize()(IrrigationPreview))
)
