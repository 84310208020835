import React from 'react'
import { Link } from 'react-router-dom'
import { withPasswordReset } from 'eazy-auth'

const Reset = ({
  resetToken,
  validResetToken,
  isCheckingResetToken,
  checkTokenError,
  onSubmitResetPassword,
  password,
  onPasswordChange,
  passwordRepeat,
  onPasswordRepeatChange,
  isResettingPassword,
  resetPasswordError,
  passwordResetted,
}) => {
  return (
    <div>
      {isCheckingResetToken && <div>Checking your reset token....</div>}
      {checkTokenError && <div>BAD TOKEN: {resetToken}</div>}
      {validResetToken && !passwordResetted && (
        <form onSubmit={onSubmitResetPassword} style={{ padding: 20 }}>
          <div>
            <input
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
          </div>
          <div>
            <input
              type="password"
              value={passwordRepeat}
              onChange={onPasswordRepeatChange}
            />
          </div>
          <input
            type="submit"
            disabled={
              isResettingPassword ||
              password.trim() === '' ||
              password !== passwordRepeat
            }
          />
          {resetPasswordError && (
            <div>Error while trying to resetting password</div>
          )}
        </form>
      )}
      {passwordResetted && (
        <div>
          Password resetted! Go to <Link to="/login">login</Link>
        </div>
      )}
    </div>
  )
}

export default withPasswordReset(({ match }) => match.params.token)(Reset)
