export const breakpoints = {
  xs: '(max-width: 576px)',
  notxs: '(min-width: 576px)',
  sm: '(max-width: 768px)',
  md: '(max-width: 992px)',
  lg: '(max-width: 1200px)',
  xl: '(min-width: 100px)',
}

// #TODO USE IA KEY!
// export const GOOGLE_API_KEY =  'AIzaSyDbhzU1U-s5rcRn0HhDg7jXJqZaFHdmF8Y';
export const GOOGLE_API_KEY = 'AIzaSyAl5siQHZ0Lqt_cx5mC4jwTmcz3KpvlRRI'
