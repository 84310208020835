import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { keyBy, groupBy, mapValues, get, keys } from 'lodash'
import {
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Badge,
  Collapse,
  Tooltip,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { setMapBounds, setMapObject } from '../state/ui'
import { processGeoItems, fixCoordsPrecision } from '../utils'
import { getNumberFormat } from '../state/languages'

class EstateFieldsTable extends React.PureComponent {
  state = {
    isOpen: false,
    tooltipOpen: false,
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen })
  toggleTooltip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })

  zoomToField = fieldId => e => {
    const { fields } = this.props
    const processedFields = processGeoItems(fields)
    const processedFieldsById = keyBy(processedFields, 'id')
    const processedField = processedFieldsById[fieldId]
    if (processedField) {
      this.props.setMapBounds({
        center: fixCoordsPrecision(processedField.center.reverse()),
        zoom: 15,
      })
      this.props.setMapObject({
        type: 'field',
        id: processedField.id,
        item: processedField,
      })
    }
  }

  getBadgeColor = notificationType => {
    const colors = {
      warning: 'danger',
      error: 'warning',
    }
    return colors[notificationType] || notificationType
  }

  render() {
    const { fields, estate, notifications, t, numberFormat } = this.props
    const notificationsByFieldId = groupBy(notifications, 'id_field')
    const notificationsByFieldIdAndType = mapValues(notificationsByFieldId, v =>
      groupBy(v, 'type')
    )
    return (
      <div className="my-4 w-100">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-start">
                  <div>
                    <span
                      id="tooltip-target"
                      onClick={this.toggleOpen}
                      className="pointer"
                    >
                      <i className="fa fa-th"></i> {t('fields')} (
                      {fields ? fields.length : 0}){' '}
                      <i
                        className={
                          this.state.isOpen
                            ? 'fa fa-angle-down pointer'
                            : 'fa fa-angle-right'
                        }
                      ></i>
                      <Tooltip
                        isOpen={this.state.tooltipOpen}
                        toggle={this.toggleTooltip}
                        placement="right"
                        target="#tooltip-target"
                      >
                        {this.state.isOpen ? t('hideFields') : t('showFields')}
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={this.state.isOpen}>
                <Table responsive className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t('name')}</th>
                      <th scope="col">{t('messages')}</th>
                      <th scope="col">{t('description')}</th>
                      <th scope="col">{t('crop')}</th>
                      <th scope="col">
                        Area [m<sup>2</sup>]
                      </th>
                    </tr>
                  </thead>
                  {fields && fields.length > 0 && (
                    <tbody>
                      {fields.map(field => (
                        <tr key={field.id}>
                          <th scope="row">
                            <Link
                              to={`/estates/${estate.id}/fields/${field.id}`}
                            >
                              {field.name}
                            </Link>{' '}
                            <i
                              className="fa fa-globe pointer"
                              onClick={this.zoomToField(field.id)}
                            ></i>
                          </th>
                          <td>
                            {keys(notificationsByFieldIdAndType[field.id])
                              .sort()
                              .map(notificationType => (
                                <span key={notificationType}>
                                  <Badge
                                    color={this.getBadgeColor(notificationType)}
                                  >
                                    {
                                      get(
                                        notificationsByFieldIdAndType[field.id],
                                        notificationType,
                                        []
                                      ).length
                                    }
                                  </Badge>{' '}
                                </span>
                              ))}
                          </td>
                          <td>{field.description}</td>
                          <td>{field.estate_crop_name}</td>
                          <td>{numberFormat(field.area)}</td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  numberFormat: getNumberFormat(state),
})
export default connect(
  mapStateToProps,
  { setMapBounds, setMapObject }
)(localize()(EstateFieldsTable))
