import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../../localize'
import { get, find } from 'lodash'
import {
  getCurrentIrrigation,
  setCurrentIrrigation,
  unsetCurrentIrrigation,
} from '../../state/irrigationEdit'
import IrrigationsUpload from './IrrigationsUpload'
import { Button, Tooltip } from 'reactstrap'
import {
  loadEstateIrrigationEvents,
  getEstate,
  getEstateFields,
  getEstateSectors,
  getEstateIrrigationEvents,
  getEstateAllegedIrrigationEvents,
} from '../../state/estates'
import IrrigationCalendar from './IrrigationCalendar'
import AllegedIrrigationsPanel from './AllegedIrrigationsPanel'

// main component
class EstateIrrigation extends React.PureComponent {
  state = {
    uploadOpen: false,
  }

  toggleUploadOpen = () => {
    this.setState({ uploadOpen: !this.state.uploadOpen })
  }

  componentDidMount() {
    const { estate, location } = this.props
    const params = {
      from_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      to_date: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    }
    this.props.loadEstateIrrigationEvents({ id: estate.id, ...params })
    if (location.state) {
      this.props.setCurrentIrrigation(location.state)
    }
  }

  onRangeChange = range => {
    const fromDate = Array.isArray(range) ? range[0] : range.start
    const toDate = Array.isArray(range) ? range[range.length - 1] : range.end
    const { estate } = this.props
    const params = {
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).format('YYYY-MM-DD'),
    }
    this.props.loadEstateIrrigationEvents({ id: estate.id, ...params })
  }

  updateEvents = () => {
    const { estate, location } = this.props
    const params = {
      from_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      to_date: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    }
    this.props.loadEstateIrrigationEvents({ id: estate.id, ...params })
  }

  render() {
    const {
      estate,
      estateFields,
      estateSectors,
      estateIrrigationEvents,
      allegedIrrigations,
      t,
    } = this.props

    const events = get(estateIrrigationEvents, 'events', []).map(evt => ({
      ...evt,
      target:
        evt.applied_to === 'field'
          ? find(estateFields, field => field.id === evt.id_field)
          : find(
              estateSectors,
              sector => sector.id === evt.id_irrigation_sector
            ),
    }))

    return (
      <div>
        {estate && (
          <div>
            <div className="row no-gutters">
              <div
                className="col-md-8 order-2 order-md-1"
                style={{ minHeight: 700 }}
              >
                <IrrigationCalendar
                  events={events}
                  onRangeChange={this.onRangeChange}
                  updateEvents={this.updateEvents}
                />
              </div>

              <div className="col-md-4 pb-3 order-1 order-md-2">
                <div className="px-3">
                  {/* legend */}
                  <div className="text-center mb-2">
                    <div className="sector-irrigation-legend"></div>{' '}
                    {t('irrigationSectors')}
                    <div className="field-irrigation-legend ml-3"></div>{' '}
                    {t('fields')}
                  </div>
                  <hr />

                  {/* upload control */}
                  <div>
                    <Button block size="sm" onClick={this.toggleUploadOpen}>
                      <span className="fa fa-upload"></span> {t('upload')}
                    </Button>
                  </div>

                  <hr />

                  {/* alleged irrigations */}
                  {allegedIrrigations && (
                    <AllegedIrrigationsPanel
                      allegedIrrigations={allegedIrrigations}
                    ></AllegedIrrigationsPanel>
                  )}
                </div>
                <div></div>
              </div>
            </div>

            <IrrigationsUpload
              isOpen={this.state.uploadOpen}
              toggle={this.toggleUploadOpen}
              updateEvents={this.updateEvents}
            ></IrrigationsUpload>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  estateFields: getEstateFields(state),
  estateSectors: getEstateSectors(state),
  estateIrrigationEvents: getEstateIrrigationEvents(state),
  allegedIrrigations: getEstateAllegedIrrigationEvents(state),
})
export default withRouter(
  connect(
    mapStateToProps,
    { loadEstateIrrigationEvents, setCurrentIrrigation }
  )(localize()(EstateIrrigation))
)
