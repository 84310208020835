import React from 'react'
import { Button, Form, FormGroup, Col, Label } from 'reactstrap'
// import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { FieldInput, required, minLength } from './forms'

const validate = values => {
  if (
    values.newPassword &&
    values.newPasswordRepeat &&
    values.newPassword !== values.newPasswordRepeat
  ) {
    return {
      newPasswordRepeat: 'Passwords must match',
    }
  }
  return {}
}

const minPasswordLength = minLength(6)

const PasswordChangeForm = props => {
  const { handleSubmit, submitting, invalid, cancelCallback } = props
  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label for="password" sm={4}>
          <b>Old password *</b>
        </Label>
        <Col sm={8}>
          <Field
            component={FieldInput}
            name="password"
            type="password"
            validate={[required]}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="newPassword" sm={4}>
          <b>New password *</b>
        </Label>
        <Col sm={8}>
          <Field
            component={FieldInput}
            name="newPassword"
            type="password"
            validate={[required, minPasswordLength]}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for="newPassword" sm={4}>
          <b>New password (repeat)*</b>
        </Label>
        <Col sm={8}>
          <Field
            component={FieldInput}
            name="newPasswordRepeat"
            type="password"
            validate={[required]}
          />
        </Col>
      </FormGroup>
      <div className="d-inline-flex justify-content-between pt-2 w-100">
        <Button color="danger" onClick={cancelCallback}>
          Annulla
        </Button>
        <Button color="success" disabled={submitting || invalid}>
          {submitting ? 'Salvo...' : 'Salva'}
        </Button>
      </div>
    </Form>
  )
}

export default reduxForm({
  form: 'passwordChange',
  validate,
})(PasswordChangeForm)
