import {
  rj,
  takeLatestAndCancelGroupBy,
  makeReducer,
  makeActionTypes,
} from 'redux-rocketjump'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import omit from 'lodash/omit'
import moment from 'moment'

export const rjDetail = () =>
  rj({
    actions: {
      load: ({ load }) => (id, params = {}, meta = {}) =>
        load({ ...params, id }, { ...meta, id }),
    },
  })

export const makeApiMapSelectors = (getBaseState, dictDataSelector) => {
  const baseState = (state, id) => getBaseState(state)[id]
  const makeGetData = () => {
    return createSelector(
      baseState,
      state => {
        return dictDataSelector
          ? dictDataSelector(get(state, 'data', null))
          : get(state, 'data', null)
      }
    )
  }
  const makeGetLoading = () => {
    return createSelector(
      baseState,
      state => {
        return get(state, 'loading', null)
      }
    )
  }
  return {
    makeGetData,
    makeGetLoading,
  }
}

// for loading multiple objects of the same kind
export const makeApiMapReducer = prefix => {
  const actionsTypes = makeActionTypes(prefix)
  const dictReducer = makeReducer(prefix)
  const innerReducer = (prevState = {}, action) => {
    const { type, meta } = action

    switch (type) {
      case actionsTypes.success:
      case actionsTypes.loading:
      case actionsTypes.failure:
        return {
          ...prevState,
          [meta.id]: dictReducer(prevState[meta.id], action),
        }
      case actionsTypes.unload:
        return omit(prevState, meta.id)
      default:
        return prevState
    }
  }

  return innerReducer
}

export const rjDict = (config = {}) =>
  rj({
    actions: {
      load: ({ load }) => (id, params = {}, meta = {}) =>
        load({ ...params, id }, { ...meta, id }),
      unload: ({ unload }) => (id, meta = {}) => unload({ id, ...meta }),
    },
    reducer: (oldReducer, { type }) => makeApiMapReducer(type),
    selectors: ({ getBaseState }) =>
      makeApiMapSelectors(getBaseState, config.dictDataSelector),
    takeEffect: takeLatestAndCancelGroupBy,
    takeEffectArgs: [payload => payload.meta.id],
  })

export const enhanceEvent = evt => ({
  id: evt.id,
  start: moment(evt.date).toDate(),
  end: evt.duration
    ? moment(evt.date)
        .add(evt.duration, 'minutes')
        .toDate()
    : moment(evt.date)
        .add(1, 'hours')
        .toDate(),
  title: evt.applied_to === 'field' ? `Field` : `Sector`,
  targetId:
    evt.applied_to === 'field' ? evt.id_field : evt.id_irrigation_sector,
  // we keep all the original json in the "data attribute"
  data: { ...evt },
})

// rj plugin used in with IrrigationsCalendar component
export const rjIrrigationEvents = (config = {}) =>
  rj({
    dataReducer: (oldData, { type, payload }) => {
      return {
        ...payload.data,
        events: get(payload.data, 'events', [])
          .filter(evt => evt.type === 'irrigation')
          .map(evt => ({
            ...enhanceEvent(evt),
          })),
      }
    },
  })
