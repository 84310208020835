import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'
import { localize } from '../localize'
import { connect } from 'react-redux'
import { Group } from '@vx/group'
import { Bar, LinePath } from '@vx/shape'
import { GlyphDot } from '@vx/glyph'
import moment from 'moment'
import {
  get,
  keyBy,
  uniq,
  chunk,
  keys,
  sumBy,
  last,
  first,
  meanBy,
} from 'lodash'
import { extent } from 'd3-array'
import { AxisBottom, AxisLeft, AxisRight } from '@vx/axis'
import { scaleLinear, scaleTime } from 'd3-scale'
import { timeFormat } from 'd3-time-format'
import { Text } from '@vx/text'
import Spinner from '../components/Spinner'
import { UncontrolledTooltip } from 'reactstrap'
import FileSaver from 'file-saver'
import memoizeOne from 'memoize-one'
import domtoimage from 'dom-to-image'
import { getDayHourUserTimeFormat } from '../state/languages'
import Brush from './Brush'
import { getNumberFormat } from '../state/languages'
import find from 'lodash/find'

const getMargin = mini =>
  mini
    ? {
        top: 10,
        left: 50,
        bottom: 30,
        right: 100,
      }
    : {
        top: 50,
        left: 50,
        bottom: 100,
        right: 80,
      }

class IWGraph_ extends React.PureComponent {
  state = {
    guideX: null,
    guideY: null,
    datum: null,
  }

  resetGuides = () => {
    this.setState({
      guideX: null,
      guideY: null,
      datum: null,
    })
  }

  setGuides = (x, y, datum) => {
    this.setState({
      guideX: x,
      guideY: y,
      datum,
    })
  }

  mouseEnter = e => {
    const {
      attainableWaterValuesByDate,
      irrigationsValuesByDate,
      waterContentValuesByDate,
      rainValuesByDate,
      showAttainableWater = true,
      showWaterContent = true,
      data,
    } = this.props

    if (e.target.dataset && e.target.dataset.y) {
      const dataY = parseFloat(e.target.dataset.y)
      const dataDate = e.target.dataset.date
      const dataDateMoment = moment(dataDate)
      const irrigationEvent = find(
        data.irrigationPeriods,
        item =>
          item.start.isSameOrBefore(dataDateMoment) &&
          item.end.isSameOrAfter(dataDateMoment)
      )
      const rainEvent = find(
        data.rainPeriods,
        item =>
          item.start.isSameOrBefore(dataDateMoment) &&
          item.end.isSameOrAfter(dataDateMoment)
      )

      const dataDatum = {
        attainableWater: showAttainableWater
          ? get(get(attainableWaterValuesByDate, dataDate), 'value')
          : undefined,
        irrigation: get(get(irrigationsValuesByDate, dataDate), 'value'),
        rain: get(get(rainValuesByDate, dataDate), 'value'),
        waterContent: showWaterContent
          ? get(get(waterContentValuesByDate, dataDate), 'value')
          : undefined,
        irrigationEvent,
        rainEvent,
      }
      this.setGuides(moment(e.target.dataset.date).toDate(), dataY, dataDatum)
    }
  }

  isCurrentX = d => {
    return (
      this.state.guideX &&
      moment(d.date)
        .toDate()
        .toString() === this.state.guideX.toString()
    )
  }

  render() {
    const { data, width, legend, margin, mini, t } = this.props
    if (!data) {
      return <Spinner className="text-muted" />
    }

    const {
      chartHeight,
      chartWidth,
      datesScale,
      irrigationsScale,
      irrigationsScaleHeight,
      irrigationsUom,
      irrigationsValues,
      irrigationsColor,
      rainScale,
      rainScaleHeight,
      rainUom,
      rainValues,
      rainColor,
      waterContentScale,
      waterContentUom,
      waterContentValues,
      waterContentColor,
      attainableWaterUom,
      attainableWaterValues,
      attainableWaterColor,
      today,
      irrigationsValuesByDate,
      waterContentValuesByDate,
      rainValuesByDate,
      attainableWaterValuesByDate,
      barWidth,
      innerBarWidth,
      fromDate,
      toDate,
      showWaterContent = true,
      showAttainableWater = true,
      numberFormat,
    } = this.props

    const graphData = get(data, 'data')
    let chartBands = get(graphData, 'chart_bands')

    //filter chart bands
    const attainableWaterBands = ['AWMin', 'AWMax', 'CML']
    const waterContentBands = ['FC', 'WP']
    if (!showWaterContent) {
      chartBands = chartBands.filter(
        item => waterContentBands.indexOf(item.type) === -1
      )
    }
    if (!showAttainableWater) {
      chartBands = chartBands.filter(
        item => attainableWaterBands.indexOf(item.type) === -1
      )
    }

    return (
      <Group
        onMouseLeave={mini ? undefined : this.resetGuides}
        onMouseEnter={mini ? undefined : this.mouseEnter}
        onMouseMove={mini ? undefined : this.mouseEnter}
      >
        <AxisBottom
          scale={datesScale}
          left={margin.left}
          top={margin.top + chartHeight}
          tickLength={4}
          tickFormat={
            mini
              ? timeFormat('%m/%d')
              : timeFormat(this.props.dayHourUserTimeFormat)
          }
        />
        {!mini && (
          <g>
            <AxisLeft
              scale={waterContentScale}
              left={margin.left}
              top={margin.top}
              tickFormat={numberFormat}
              tickLength={4}
              label={showWaterContent ? waterContentUom : attainableWaterUom}
            />
            <AxisRight
              scale={irrigationsScaleHeight}
              left={margin.left + chartWidth}
              top={margin.top + chartHeight - irrigationsScaleHeight.range()[0]}
              tickFormat={numberFormat}
              tickLength={4}
              label={`${t('irrigation')} ${irrigationsUom || ''}`}
              labelProps={{
                width: 50,
                fontSize: 10,
                verticalAnchor: 'start',
                textAnchor: 'middle',
              }}
              numTicks={5}
            />
            <AxisLeft
              scale={rainScaleHeight}
              left={margin.left}
              top={margin.top + chartHeight - rainScaleHeight.range()[0]}
              tickFormat={numberFormat}
              tickLength={4}
              label={`${t('rain')} ${rainUom || ''}`}
              labelProps={{
                width: 50,
                fontSize: 10,
                verticalAnchor: 'start',
                textAnchor: 'middle',
              }}
              numTicks={5}
            />
            {today && today.isBefore(toDate) && (
              <Group top={margin.top} left={margin.left}>
                <rect
                  width={barWidth * 3}
                  x={datesScale(
                    today
                      .clone()
                      .subtract(1, 'hours')
                      .toDate()
                  )}
                  height={chartHeight}
                  className="today-bar"
                />
                <Text
                  className="chart-legend-label"
                  textAnchor={'middle'}
                  y={-10}
                  x={datesScale(
                    today
                      .clone()
                      .add(30, 'minutes')
                      .toDate()
                  )}
                >
                                {t('now')}
                </Text>
              </Group>
                    )}

                    {today && today.isBefore(toDate) &&  (
                        <Group top={margin.top} left={margin.left}>
                            <rect
                                width={
                                    datesScale(
                                        toDate
                                    ) - datesScale(
                                        today
                                            .clone()
                                            .add(2, 'hours')
                                            .toDate()
                                    )}
                                x={datesScale(
                                    today
                                        .clone()
                                        .add(2, 'hours')
                                        .toDate()
                                )}
                                height={chartHeight}
                                className="forecast-bar"
                            />
                            <Text
                                className="chart-legend-label"
                                textAnchor={'start'}
                                y={-10}
                                x={datesScale(
                                    today
                                        .clone()
                                        .add(150, 'minutes')
                                        .toDate()
                                )}
                            >
                                {t('forecast')}
            </Text>
                        </Group>
                    )}
                    {' '}
          </g>
        )}
        {/* guides */}
        {this.state.guideX && (
          <Group top={margin.top} left={margin.left}>
            <line
              x1={datesScale(this.state.guideX)}
              x2={datesScale(this.state.guideX)}
              y1={0}
              y2={chartHeight}
              className="chart-guide"
            ></line>

            {get(this.state.datum, 'irrigation') && (
              <Group top={(chartHeight / 3) * 2}>
                <line
                  y1={irrigationsScaleHeight(this.state.datum.irrigation)}
                  y2={irrigationsScaleHeight(this.state.datum.irrigation)}
                  x1={datesScale(this.state.guideX)}
                  x2={chartWidth}
                  className="chart-guide"
                ></line>
                <circle
                  cy={irrigationsScaleHeight(this.state.datum.irrigation)}
                  className="rain-fill"
                  r={4}
                  cx={chartWidth}
                ></circle>
              </Group>
            )}

            {get(this.state.datum, 'waterContent') && (
              <Group>
                <line
                  y1={waterContentScale(this.state.datum.waterContent)}
                  y2={waterContentScale(this.state.datum.waterContent)}
                  x1={datesScale(this.state.guideX)}
                  x2={0}
                  className="chart-guide"
                ></line>
                <circle
                  cy={waterContentScale(this.state.datum.waterContent)}
                  className="humidity-fill"
                  r={4}
                  cx={0}
                ></circle>
              </Group>
            )}

            {get(this.state.datum, 'attainableWater') && (
              <Group>
                <line
                  y1={waterContentScale(this.state.datum.attainableWater)}
                  y2={waterContentScale(this.state.datum.attainableWater)}
                  x1={datesScale(this.state.guideX)}
                  x2={0}
                  className="chart-guide"
                ></line>
                <circle
                  cy={waterContentScale(this.state.datum.attainableWater)}
                  className="humidity-fill"
                  r={4}
                  cx={0}
                ></circle>
              </Group>
            )}
          </Group>
        )}

        {/* rain */}
        {rainValues.length && (
          <Group top={margin.top + chartHeight} left={margin.left}>
            {rainValues.map((d, i) => {
              let x = datesScale(moment(d.date)) - innerBarWidth / 2
              const neg = x < 0 ? -x : 0
              x = Math.max(0, x)
              let width = Math.max(innerBarWidth - neg, 0)
              width = Math.min(chartWidth - x, width)
              const height = rainScale(d.value)

              return (
                <g key={i}>
                  <Bar
                    data-y={height}
                    data-date={d.date}
                    width={width}
                    height={height}
                    x={x}
                    y={-height}
                    fill={rainColor}
                    strokeWidth={1}
                  ></Bar>
                </g>
              )
            })}
          </Group>
        )}

        {/* irrigation */}
        {irrigationsValues.length && (
          <Group top={margin.top + chartHeight} left={margin.left}>
            {irrigationsValues.map((d, i) => {
              let x = datesScale(moment(d.date)) - innerBarWidth / 2
              const neg = x < 0 ? -x : 0
              x = Math.max(0, x)
              let width = Math.max(innerBarWidth - neg, 0)
              width = Math.min(chartWidth - x, width)
              const height = irrigationsScale(d.value)

              return (
                <g key={i}>
                  <Bar
                    data-y={height}
                    data-date={d.date}
                    width={width}
                    height={height}
                    x={x}
                    y={-height}
                    fill={irrigationsColor}
                    strokeWidth={1}
                  ></Bar>
                </g>
              )
            })}
          </Group>
        )}

        {/* thresholds */}
        {
          <Group left={margin.left} top={margin.top}>
            {chartBands.length > 0 &&
              chartBands.map((band, bi) => (
                <g key={bi}>
                  <line
                    stroke={band.color}
                    strokeWidth={1.5}
                    x1={0}
                    x2={chartWidth}
                    y1={waterContentScale(band.value)}
                    y2={waterContentScale(band.value)}
                  />
                  {!mini && (
                    <Text
                      textAnchor={'start'}
                      verticalAnchor={'middle'}
                      className="axis-annotation"
                      width={100}
                      fill={'#666'}
                      x={chartWidth + 10}
                      y={waterContentScale(band.value)}
                    >
                      {band.label}
                    </Text>
                  )}
                </g>
              ))}
          </Group>
        }

        {/* water content */}
        {showWaterContent && waterContentValues.length && (
          <Group top={margin.top} left={margin.left}>
            <LinePath
              data={waterContentValues}
              xScale={datesScale}
              yScale={waterContentScale}
              // curve={curveNatural}
              y={i => i.value}
              x={i => moment(i.date).toDate()}
              stroke={waterContentColor}
              strokeWidth={2}
              glyph={(d, i) => {
                return (
                  waterContentScale(d.value) && (
                    <GlyphDot
                      className={`glyph-dots ${
                        this.isCurrentX(d)
                          ? 'humidity-fill'
                          : 'transparent-fill'
                      }`}
                      key={`line-dot-${i}`}
                      cx={datesScale(moment(d.date).toDate())}
                      cy={waterContentScale(d.value)}
                      r={4}
                      data-y={waterContentScale(d.value)}
                      data-date={d.date}
                    />
                  )
                )
              }}
            />
          </Group>
        )}

        {/* attainable_water */}
        {showAttainableWater && attainableWaterValues.length && (
          <Group top={margin.top} left={margin.left}>
            <LinePath
              data={attainableWaterValues}
              xScale={datesScale}
              yScale={waterContentScale}
              // curve={curveNatural}
              y={i => i.value}
              x={i => moment(i.date).toDate()}
              stroke={attainableWaterColor}
              strokeWidth={2}
              glyph={(d, i) => {
                return (
                  waterContentScale(d.value) && (
                    <GlyphDot
                      className={`glyph-dots ${
                        this.isCurrentX(d)
                          ? 'humidity-fill'
                          : 'transparent-fill'
                      }`}
                      key={`line-dot-${i}`}
                      cx={datesScale(moment(d.date).toDate())}
                      cy={waterContentScale(d.value)}
                      r={4}
                      data-y={waterContentScale(d.value)}
                      data-date={d.date}
                    />
                  )
                )
              }}
            />
          </Group>
        )}

        {/* tooltip */}
        {!mini && this.state.guideX && (
          <Group
            top={
              this.state.guideY < chartHeight / 2
                ? this.state.guideY + 70
                : this.state.guideY - 110
            }
            left={Math.max(0, datesScale(this.state.guideX) - 100)}
          >
            <rect
              style={{ fill: '#efefef', stroke: '#ccc' }}
              x={0}
              y={0}
              width={200}
              height={80}
              rx="4"
              ry="4"
            ></rect>
            <text className="chart-guide-label" y={16}>
              <tspan className="chart-guide-label-big" x={100}>
                {timeFormat('%a %m/%d %H:%M')(this.state.guideX)}
              </tspan>
              {!!this.state.datum.waterContent && (
                <tspan x={100} dy={14}>
                  {t('waterContent')}:{' '}
                  {numberFormat(this.state.datum.waterContent)}{' '}
                  {waterContentUom}
                </tspan>
              )}
              {!!this.state.datum.attainableWater && (
                <tspan x={100} dy={14}>
                  {t('attainableWaterContent')}:{' '}
                  {numberFormat(this.state.datum.attainableWater)}{' '}
                  {attainableWaterUom}
                </tspan>
              )}
              {!!this.state.datum.irrigation && (
                <tspan x={100} dy={14}>
                  {t('irrigation')}: {numberFormat(this.state.datum.irrigation)}{' '}
                  {irrigationsUom}{' '}
                </tspan>
              )}
              {!!this.state.datum.irrigationEvent && (
                <tspan x={100} dy={14}>
                  {t('irrigationCumulated')}:{' '}
                  {numberFormat(this.state.datum.irrigationEvent.value)}{' '}
                  {irrigationsUom} ({this.state.datum.irrigationEvent.duration}{' '}
                  {t('hours')})
                </tspan>
              )}
              {!!this.state.datum.rain && (
                <tspan x={100} dy={14}>
                  {t('rain')}: {numberFormat(this.state.datum.rain)} {rainUom}
                </tspan>
              )}
              {!!this.state.datum.rainEvent && (
                <tspan x={100} dy={14}>
                  {t('rainCumulated')}:{' '}
                  {numberFormat(this.state.datum.rainEvent.value)} {rainUom} (
                  {numberFormat(this.state.datum.rainEvent.duration)}{' '}
                  {t('hours')})
                </tspan>
              )}
            </text>
          </Group>
        )}
      </Group>
    )
  }
}

export const IWGraph = connect(state => ({
  dayHourUserTimeFormat: getDayHourUserTimeFormat(state),
  numberFormat: getNumberFormat(state),
}))(localize()(IWGraph_))

class ChartWrapper_ extends React.PureComponent {
  downloadPng = e => {
    const { chartLabel = 'download' } = this.props
    var node = ReactDOM.findDOMNode(this)
    function filter(node) {
      return node.tagName !== 'I'
    }

    domtoimage
      .toBlob(node, { bgcolor: '#fff', filter: filter })
      .then(function(blob) {
        FileSaver.saveAs(blob, `${chartLabel}.png`)
      })
  }

  makeChunkDatum = (chunk, operator = meanBy) => {
    const d = first(chunk)
    const lastD = last(chunk)
    const datesDiff = moment(lastD.date).diff(moment(d.date))
    const midDate = moment(d.date).add(datesDiff / 2, 'milliseconds')

    const k = keys(d)
    let out = {
      type: d.type,
      date: midDate.toDate(),
    }
    k.filter(x => ['date', 'type'].indexOf(x) === -1).forEach(x => {
      out[x] = operator(chunk, x)
    })
    return out
  }

  chunkSerie = (serie, operator = meanBy) => {
    const serieValues = get(serie, 'values', [])
    const dataRatio = Math.ceil(serieValues.length / this.props.width)
    if (dataRatio > 1) {
      return {
        ...serie,
        values: chunk(serieValues, dataRatio).map(x =>
          this.makeChunkDatum(x, operator)
        ),
      }
    }
    return serie
  }

  updateDataSeries = (
    data,
    mini,
    margin,
    height,
    width,
    toDate,
    fromDate,
    showAttainableWater
  ) => {
    const chartHeight = height - margin.top - margin.bottom
    const chartWidth = width - margin.left - margin.right

    const datesScale = scaleTime()
      .range([0, chartWidth])
      .domain([fromDate.toDate(), toDate.toDate()])

    const irrigationsUom = get(data, 'data.irrigations.unit_of_measure')
    const irrigationsColor = get(data, 'data.irrigations.color')
    const irrigationsLabel = get(data, 'data.irrigations.label')
    const irrigationsValuesRaw = get(
      data,
      'data.irrigations.values',
      []
    ).filter(x => {
      const d = moment(x.date)
      return d.isSameOrAfter(fromDate) && d.isSameOrBefore(toDate)
    })
    const irrigationsValues = this.chunkSerie(irrigationsValuesRaw, sumBy)

    const waterContentUom = get(data, 'data.water_content.unit_of_measure')
    const waterContentColor = get(data, 'data.water_content.color')
    const waterContentLabel = get(data, 'data.water_content.label')
    const waterContentValuesRaw = get(
      data,
      'data.water_content.values',
      []
    ).filter(x => {
      const d = moment(x.date)
      return d.isSameOrAfter(fromDate) && d.isSameOrBefore(toDate)
    })
    const waterContentValues = this.chunkSerie(waterContentValuesRaw, meanBy)
    const waterContentValuesRange = extent(waterContentValuesRaw, x => x.value)

    // WORKING ON SCALE FIX issue #
    const attainableWaterBands = ['AWMin', 'AWMax', 'CML']
    const waterContentBands = ['FC', 'WP']
    const chartBands = get(data, 'data.chart_bands')
    const bands = showAttainableWater
      ? chartBands.filter(x => attainableWaterBands.indexOf(x.type) !== -1)
      : chartBands.filter(x => waterContentBands.indexOf(x.type) !== -1)
    const bandsExtent = extent(bands.map(x => x.value))

    const attainableWaterUom = get(
      data,
      'data.attainable_water_content.unit_of_measure'
    )
    const attainableWaterColor = get(
      data,
      'data.attainable_water_content.color'
    )
    const attainableWaterLabel = get(
      data,
      'data.attainable_water_content.label'
    )
    const attainableWaterValuesRaw = get(
      data,
      'data.attainable_water_content.values',
      []
    ).filter(x => {
      const d = moment(x.date)
      return d.isSameOrAfter(fromDate) && d.isSameOrBefore(toDate)
    })
    const attainableWaterValues = this.chunkSerie(
      attainableWaterValuesRaw,
      meanBy
    )
    const attainableWaterValuesRange = extent(
      attainableWaterValuesRaw,
      x => x.value
    )

    const rangeForWaterContentScale = showAttainableWater
      ? attainableWaterValuesRange
      : waterContentValuesRange

    const minWaterContentForScale = Math.min(
      rangeForWaterContentScale[0] - rangeForWaterContentScale[0] * 0.1,
      bandsExtent[0] - bandsExtent[0] * 0.1
    )
    const maxWaterContentForScale = Math.max(
      rangeForWaterContentScale[1] + rangeForWaterContentScale[1] * 0.1,
      bandsExtent[1] + bandsExtent[1] * 0.1
    )
    const waterContentScaleDomain = waterContentValues.length
      ? [minWaterContentForScale, maxWaterContentForScale]
      : [0, 100]

    //const wcDomain = mini ? [0, 100] : waterContentScaleDomain
    const wcDomain = waterContentScaleDomain

    const waterContentScale = scaleLinear()
      .range([(chartHeight / 4) * 3, 0])
      .domain(wcDomain)

    const rainUom = get(data, 'data.rain.unit_of_measure')
    const rainColor = get(data, 'data.rain.color')
    const rainLabel = get(data, 'data.rain.label')
    const rainValuesRaw = get(data, 'data.rain.values', []).filter(x => {
      const d = moment(x.date)
      return d.isSameOrAfter(fromDate) && d.isSameOrBefore(toDate)
    })
    const rainValues = this.chunkSerie(rainValuesRaw, sumBy)

    //same scale for irrigation and rain
    let irrigationsValuesRange = extent(
      irrigationsValues.concat(rainValues),
      x => x.value
    )
    // fix for scale with [0,0] range
    if (irrigationsValuesRange[1] === irrigationsValuesRange[0]) {
      irrigationsValuesRange[1] = 10
    }

    const irrigationsScale = scaleLinear()
      .range([0, chartHeight / 4 - 10])
      .domain([0, irrigationsValuesRange[1] * 1.1])
    const irrigationsScaleHeight = scaleLinear()
      .range([chartHeight / 4 - 10, 0])
      .domain([0, irrigationsValuesRange[1] * 1.1])

    let rainValuesRange = extent(rainValues.concat(rainValues), x => x.value)
    // fix for scale with [0,0] range
    if (rainValuesRange[1] === rainValuesRange[0]) {
      rainValuesRange[1] = 10
    }
    const rainScale = scaleLinear()
      .range([0, chartHeight / 4 - 10])
      .domain([0, rainValuesRange[1] * 1.1])
    const rainScaleHeight = scaleLinear()
      .range([chartHeight / 4 - 10, 0])
      .domain([0, rainValuesRange[1] * 1.1])

    let barWidth
    const dates = uniq(
      irrigationsValues.concat(rainValues).map(i => moment(i.date).toDate())
    ).sort()
    if (dates.length > 1) {
      barWidth = datesScale(dates[1]) - datesScale(dates[0])
    } else {
      // one data point only ..
      barWidth = width - margin.left + margin.right
    }
    let innerBarWidth = barWidth - 2
    innerBarWidth = innerBarWidth < 1 ? 1 : innerBarWidth
    barWidth = barWidth < 1 ? 1 : barWidth

    const today = moment(moment().format('YYYY-MM-DDTHH') + ':00:00')
    const irrigationsValuesByDate = keyBy(irrigationsValues, x => x.date)
    const waterContentValuesByDate = keyBy(waterContentValues, x => x.date)
    const rainValuesByDate = keyBy(rainValues, x => x.date)
    const attainableWaterValuesByDate = keyBy(
      attainableWaterValues,
      x => x.date
      )

    return {
      chartHeight,
      chartWidth,
      datesScale,
      irrigationsScale,
      irrigationsScaleHeight,
      irrigationsUom,
      irrigationsValues,
      irrigationsColor,
      irrigationsLabel,
      rainScale,
      rainScaleHeight,
      rainUom,
      rainValues,
      rainColor,
      rainLabel,
      waterContentScale,
      waterContentUom,
      waterContentValues,
      waterContentColor,
      waterContentLabel,
      attainableWaterUom,
      attainableWaterValues,
      attainableWaterColor,
      attainableWaterLabel,
      today,
      irrigationsValuesByDate,
      waterContentValuesByDate,
      rainValuesByDate,
      attainableWaterValuesByDate,
      barWidth,
      innerBarWidth,
      fromDate,
      toDate,
    }
  }

  render() {
    const {
      minDate,
      maxDate,
      dragCallbackLeft,
      dragCallbackRight,
      dragCallbackCenter,
      data,
      dateRange,
      fromDate,
      toDate,
      mini,
      t,
      currentParams,
      width,
      height,
      chartId,
      ...passProps
    } = this.props

    if (!get(data, 'data.water_content')) {
      return <div className="text-center">{t('noDataAvailable')}</div>
    }

    const margin = getMargin(this.props.mini)
    const { showWaterContent = true, showAttainableWater = true } = this.props
    const downProps = this.updateDataSeries(
      data,
      mini,
      margin,
      height,
      width,
      toDate,
      fromDate,
      showAttainableWater
      )

    return (
      <div>
        {!mini && (
          <React.Fragment>
            <i
              className="text-muted pointer float-right fa fa-download download-button"
              id={chartId}
              onClick={() => this.downloadPng()}
            ></i>
            <UncontrolledTooltip placement="right" target={chartId}>
              {t('downloadChart')}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        <svg width={width} height={height} style={{ position: 'relative' }}>
          {!mini &&
            this.props.legend &&
            this.props.legend(
              width,
              {
                waterContent: showWaterContent
                  ? downProps.waterContentColor
                  : undefined,
                attainableWater: showAttainableWater
                  ? downProps.attainableWaterColor
                  : undefined,
                rain: downProps.rainColor,
                irrigations: downProps.irrigationsColor,
              },
              {
                waterContent: showWaterContent
                  ? downProps.waterContentLabel
                  : undefined,
                attainableWater: showAttainableWater
                  ? downProps.attainableWaterLabel
                  : undefined,
                rain: downProps.rainLabel,
                irrigations: downProps.irrigationsLabel,
              }
            )}
          <IWGraph
            width={width}
            height={height}
            fromDate={fromDate}
            toDate={toDate}
            mini={mini}
            margin={margin}
            data={data}
            {...passProps}
            {...downProps}
          />
          {this.props.mini && (
            <Brush
              dateRange={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              dragCallbackLeft={dragCallbackLeft}
              dragCallbackRight={dragCallbackRight}
              dragCallbackCenter={dragCallbackCenter}
              margin={margin}
              chartPadding={10}
              height={height - 1}
              width={width}
            />
          )}
        </svg>
      </div>
    )
  }
}

export const ChartWrapper = connect()(localize()(ChartWrapper_))

export default class OuterWrapper extends React.PureComponent {
  state = {
    dateRange: null,
  }

computeDataState = memoizeOne(data => {
    const momentFrom = moment(data.from_date)
    const momentTo = moment(data.to_date)
    const dateRange = [momentFrom.toDate(), momentTo.toDate()]
    // initialy center the chart to current date instead of last 2 days if today is inside data range
    const today = moment();
    if (today.isBefore(momentTo) && today.isAfter(momentFrom)) {
        const newMin = moment().subtract(1, 'days') //moment(dateRange[1]).subtract(2, 'days')
        const newMax = moment().add(1, 'days') //moment(dateRange[1])
        const minDateForDetail = newMin.isAfter(momentFrom) ? newMin : momentFrom
        const maxDateForDetail = newMax.isBefore(momentTo) ? newMax : momentTo
        return { dateRange, minDateForDetail, maxDateForDetail }
    }
    else {
        const newMin = moment(dateRange[1]).subtract(2, 'days')
        const newMax = moment(dateRange[1])
        const minDateForDetail = newMin.isAfter(momentFrom) ? newMin : momentFrom
        const maxDateForDetail = newMax.isBefore(momentTo) ? newMax : momentTo
        return { dateRange, minDateForDetail, maxDateForDetail }
    }
  })

  componentDidMount() {
    const { data, showAllAtStart = false } = this.props
    const {
      dateRange,
      minDateForDetail,
      maxDateForDetail,
    } = this.computeDataState(data)
    this.setState({
      dateRange,
      minDateForDetail: showAllAtStart
        ? moment(dateRange[0])
        : minDateForDetail,
      maxDateForDetail,
    })
  }

  componentDidUpdate(oldProps) {
    if (
      oldProps.width !== this.props.width ||
      oldProps.data !== this.props.data
    ) {
      const { data, showAllAtStart } = this.props
      const {
        dateRange,
        minDateForDetail,
        maxDateForDetail,
      } = this.computeDataState(data)
      this.setState({
        dateRange,
        minDateForDetail: showAllAtStart
          ? moment(dateRange[0])
          : minDateForDetail,
        maxDateForDetail,
      })
    }
  }
  //

  dragCallbackLeft = minDate => {
    this.setState({ minDateForDetail: minDate })
  }

  dragCallbackRight = maxDate => {
    this.setState({ maxDateForDetail: maxDate })
  }

  dragCallbackCenter = (minDate, maxDate) => {
    this.setState({ minDateForDetail: minDate, maxDateForDetail: maxDate })
  }

  render() {
    if (!this.props.width || !this.state.dateRange) {
      return null
    }
    const { height, width, data, currentParams, ...passProps } = this.props
    const { dateRange, minDateForDetail, maxDateForDetail } = this.state
    const miniHeight = height > 300 ? 150 : 100

    return (
      <div>
        {
          <ChartWrapper
            data={data}
            fromDate={minDateForDetail}
            toDate={maxDateForDetail}
            currentParams={currentParams}
            dateRange={dateRange}
            height={height - miniHeight}
            width={width}
            {...passProps}
          />
        }
        <ChartWrapper
          mini={true}
          data={data}
          minDate={minDateForDetail.toDate()}
          maxDate={maxDateForDetail.toDate()}
          fromDate={moment(dateRange[0])}
          toDate={moment(dateRange[1])}
          currentParams={currentParams}
          height={miniHeight}
          width={width}
          {...passProps}
          dragCallbackLeft={this.dragCallbackLeft}
          dragCallbackRight={this.dragCallbackRight}
          dragCallbackCenter={this.dragCallbackCenter}
          dateRange={dateRange}
        />
      </div>
    )
  }
}
