import React from 'react'
import ReactDOM from 'react-dom'
import { get, keys, values, isEqual } from 'lodash'
import { Group } from '@vx/group'

export default class SoilProviderLegend extends React.PureComponent {
  state = {
    rects: {},
  }

  refsGroups = {}
  updateRects = () => {
    let rects = {}
    keys(this.refsGroups).forEach(key => {
      const node = ReactDOM.findDOMNode(this.refsGroups[key])
      if (node) {
        const rect = node.getBoundingClientRect()
        rects[key] = {
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        }
      }
    })
    if (!isEqual(rects, this.state.rects)) {
      this.setState({ rects })
    }
  }

  componentDidUpdate() {
    this.updateRects()
  }

  componentDidMount() {
    this.updateRects()
  }

  getOffsets = (margin = 10) => {
    const { rects } = this.state
    if (!rects) {
      return {}
    }
    const legendKeys = keys(rects)
    return legendKeys.reduce((acc, item, idx) => {
      if (idx === 0) {
        acc[item] = 0
      } else {
        const value =
          acc[legendKeys[idx - 1]] + rects[legendKeys[idx - 1]].width + margin
        acc[item] = value
      }
      return acc
    }, {})
  }

  render() {
    const { rects } = this.state
    const { width, top, dataSeries } = this.props
    if (!this.props.width) {
      return null
    }
    const offsets = this.getOffsets()
    const legendWidth = values(rects)
      .map(r => r.width)
      .reduce((acc, item) => acc + item, 0)
    const left = (width - legendWidth) / 2

    return (
      <Group left={left} top={top}>
        {dataSeries.map((serie, s) => (
          <g
            key={s}
            ref={node => (this.refsGroups[s] = node)}
            transform={`translate(${get(offsets, s, 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={serie.color}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {serie.name}
            </text>
          </g>
        ))}
      </Group>
    )
  }
}
