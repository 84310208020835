import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import { rj } from 'redux-rocketjump'
import { rjDict } from './utils'

// Namespace for actions
const NS = '@nodeproviders/'

// Dict
const GET_NODE_PROVIDERS_DATA_DICT = `${NS}GET_NODE_PROVIDERS_DATA_DICT`
export const {
  actions: {
    load: loadNodeProvidersDataDict,
    unload: unloadNodeProvidersDataDict,
  },
  selectors: {
    getData: getNodeProviderData,
    makeGetData: makeGetNodeProviderData,
    makeGetLoading: makeGetNodeProviderDataLoading,
  },
  reducer: nodeProvidersDataDictReducer,
  saga: nodeProvidersDataDictSaga,
} = rj(rjDict(), {
  type: GET_NODE_PROVIDERS_DATA_DICT,
  state: 'nodeProviders.dataDict',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}data-providers/nodes/${id}/data`))
      .query(params)
      .then(({ body }) => ({ ...body, id })),
})()

// Dict
const GET_NODE_PROVIDERS_DICT = `${NS}GET_NODE_PROVIDERS_DICT`
export const {
  actions: { load: loadNodeProvidersDict, unload: unloadNodeProvidersDict },
  selectors: {
    makeGetData: makeGetNodeProvider,
    makeGetLoading: makeGetNodeProviderLoading,
  },
  reducer: nodeProvidersDictReducer,
  saga: nodeProvidersDictSaga,
} = rj(rjDict(), {
  type: GET_NODE_PROVIDERS_DICT,
  state: 'nodeProviders.dict',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}data-providers/nodes/${id}/data`))
      .query(params)
      .then(({ body }) => ({ ...body, id })),
})()

export const reducer = combineReducers({
  dataDict: nodeProvidersDataDictReducer,
  dict: nodeProvidersDictReducer,
})

export const saga = function*() {
  yield fork(nodeProvidersDataDictSaga)
  yield fork(nodeProvidersDictSaga)
}
