import React from 'react'
import moment from 'moment'

export class SimpleDataTable extends React.PureComponent {
  render() {
    const { columns, columnsClass = 'col-sm-6' } = this.props
    return (
      <div className="w-100">
        <div className="row no-gutters">
          {columns.map((column, c) => (
            <div className={columnsClass} key={c}>
              <div className="list-group">
                {column.map((item, i) => (
                  <div className="list-group-item" key={i}>
                    {item.label}
                    {item.label && item.value && ': '}
                    <b>{item.value}</b>
                    {(!item.label || !item.value) && <span>&nbsp;</span>}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export class MomentSpan extends React.PureComponent {
  render() {
    const { date, parseFormat = undefined, format = 'YYYY-MM-DD' } = this.props
    const m = moment(date, parseFormat).format(format)
    return <span>{m}</span>
  }
}
