import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Input,
  UncontrolledTooltip,
} from 'reactstrap'
import {
  getEstate,
  loadMeteoDict,
  unloadMeteoDict,
  makeGetStationMeteo,
  makeGetStationMeteoLoading,
} from '../state/estates'
import { getUiCache, setUiCache } from '../state/ui'
import moment from 'moment'
import get from 'lodash/get'
import { ParentSize } from '@vx/responsive'
import MeteoVx from './MeteoVx'
import WindSpeedChart from './WindSpeedChart'
import DatePicker from 'react-datepicker'
import { getDayMonthYearMomentUserFormat } from '../state/languages'
import { downloadMeteoCSV } from '../api'
import { getAuthAccessToken } from 'eazy-auth'

const makeChartMapStateToProps = () => {
  const getFieldSimulation = makeGetStationMeteo()
  const getFieldSimulationLoading = makeGetStationMeteoLoading()
  const mapStateToProps = (state, props) => {
    return {
      estate: getEstate(state),
      simulation: getFieldSimulation(state, props.station.id),
      simulationLoading: getFieldSimulationLoading(state, props.station.id),
      dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
      uiCache: getUiCache(state),
      accessToken: getAuthAccessToken(state),
    }
  }
  return mapStateToProps
}

class EstateMeteoChart_ extends React.PureComponent {
  state = {
    clientRect: {},
    fromDate: null,
    toDate: null,
    minDate: null,
    maxDate: null,
  }

  // measure = () => {
  //   const node = ReactDOM.findDOMNode(this.chartCointainer)
  //   if (node) {
  //     const clientRect = node.getBoundingClientRect()
  //     const { width, height } = this.state.clientRect
  //     if (clientRect.width !== width || clientRect.height !== height) {
  //       this.setState({ clientRect })
  //     }
  //   }
  // }

  componentDidUpdate(oldProps) {
    // this.measure()

    if (
      (oldProps.fromDate &&
        oldProps.fromDate.format('YYYY-MM-DD') !==
          this.props.fromDate.format('YYYY-MM-DD')) ||
      (oldProps.toDate &&
        oldProps.toDate.format('YYYY-MM-DD') !==
          this.props.toDate.format('YYYY-MM-DD'))
    ) {
      const { station } = this.props
      this.props.loadMeteoDict(station.id, {
        from_date: this.props.fromDate.format('YYYY-MM-DD'),
        to_date: this.props.toDate.format('YYYY-MM-DD'),
        estate_id: this.props.estate.id,
      })
    }
    if (
      this.props.station &&
      this.props.currentStationId === this.props.station.id.toString()
    ) {
      ReactDOM.findDOMNode(this).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.simulation !== this.props.simulation) {
      const { simulation } = nextProps
      const newState = {
        minDate: moment(simulation.min_date),
        maxDate: moment(simulation.max_date),
        fromDate: moment(simulation.from_date),
        toDate: moment(simulation.to_date),
        maxRange: get(simulation, 'max_range', 5),
      }
      this.setState(newState)

      this.props.setDates({
        minDate: moment(simulation.min_date),
        maxDate: moment(simulation.max_date),
        fromDate: moment(simulation.from_date),
        toDate: moment(simulation.to_date),
        maxRange: get(simulation, 'max_range', 5),
      })
      this.props.setUiCache('meteoDates', {
        from_date: newState.fromDate.format('YYYY-MM-DD'),
        to_date: newState.toDate.format('YYYY-MM-DD'),
      })
    }
  }

  componentDidMount() {
    const { station, estate } = this.props
    let loadParams = { estate_id: estate.id }
    const { uiCache } = this.props
    if (uiCache['meteoDates']) {
      loadParams = {
        ...loadParams,
        ...uiCache['meteoDates'],
      }
    }
    this.props.loadMeteoDict(station.id, loadParams)
  }

  render() {
    const {
      estate,
      simulation,
      simulationLoading,
      station,
      height,
      t,
      accessToken,
    } = this.props
    const { fromDate, toDate } = this.state
    if (!simulation || !fromDate || !toDate) {
      return null
    }

    const currentParams =
      this.props.fromDate && this.props.toDate
        ? {
            from_date: this.props.fromDate.format(),
            to_date: this.props.toDate.format(),
            estate_id: estate.id,
          }
        : {
            estate_id: estate.id,
          }

    return (
      simulation && (
        <div className="my-4">
          <Row>
            <Col sm={12}>
              <Card>
                <CardHeader tag="h6">
                  <i className="fa fa-bar-chart"></i> {station.name}{' '}
                  {simulationLoading && (
                    <span className="">
                      {' '}
                      <i className="fa fa-refresh fa-spin text-primary"></i>
                    </span>
                  )}
                  <small
                    id={`csv-download-${station.id}`}
                    className="text-primary pointer float-right"
                    href={null}
                    onClick={() => {
                      downloadMeteoCSV(
                        station.id,
                        currentParams,
                        `${station.name}.csv`,
                        accessToken
                      )
                    }}
                  >
                    {t('downloadCSV')}
                  </small>
                  <UncontrolledTooltip target={`csv-download-${station.id}`}>
                    {t('downloadDataAsCSV')}
                  </UncontrolledTooltip>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12} sm={12} xs={12}>
                      <div
                      // ref={node => (this.chartCointainer = node)}
                      >
                        <ParentSize>
                          {({ width: w, height: h }) => {
                            return (
                              <div>
                                <MeteoVx
                                  stationId={station.id}
                                  chartLabel={`meteo-${
                                    station.name
                                  }-${fromDate.format(
                                    this.props.dayMonthYearMomentUserFormat
                                  )}-${toDate.format(
                                    this.props.dayMonthYearMomentUserFormat
                                  )}`}
                                  width={w}
                                  height={height}
                                  simulation={simulation}
                                  showVariables={this.props.showVariables}
                                />
                              </div>
                            )
                          }}
                        </ParentSize>
                      </div>
                    </Col>
                  </Row>
                  {simulation.wind && (
                    <Row className="mt-2">
                      <Col md={12} sm={12} xs={12}>
                        <div>
                          <ParentSize>
                            {({ width: w, height: h }) => {
                              return (
                                <div>
                                  <WindSpeedChart
                                    stationId={station.id}
                                    chartLabel={`wind-meteo-${
                                      station.name
                                    }-${fromDate.format(
                                      this.props.dayMonthYearMomentUserFormat
                                    )}-${toDate.format(
                                      this.props.dayMonthYearMomentUserFormat
                                    )}`}
                                    width={w}
                                    height={(height / 3) * 2}
                                    data={simulation.wind}
                                  />
                                </div>
                              )
                            }}
                          </ParentSize>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    )
  }
}

export const EstateMeteoChart = connect(
  makeChartMapStateToProps,
  { loadMeteoDict, unloadMeteoDict, setUiCache }
)(localize()(EstateMeteoChart_))

class CustomDateInput extends React.Component {
  render() {
    return (
      <div className="col-sm-9">
        <Input
          bsSize="sm"
          value={this.props.value}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
        />
      </div>
    )
  }
}

class MeteoVarsSelector_ extends React.PureComponent {
  render() {
    const {
      showVariables,
      toggleVariable,
      fromDate,
      toDate,
      minDate,
      maxDate,
      handleChangeFromDate,
      handleChangeToDate,
      t,
    } = this.props

    return (
      <div className="my-4">
        <Row>
          <Col sm={12}>
            <Card>
              <CardHeader>
                <i className="fa fa-bar-chart"></i> {t('meteoData')}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6} sm={6} xs={12}>
                    <div>
                      <div className="form-group row d-flex align-items-center">
                        <label className="col-sm-3 col-form-label">
                          {t('fromDate')}
                        </label>
                        <DatePicker
                          customInput={<CustomDateInput />}
                          selected={fromDate}
                          selectsStart
                          startDate={fromDate}
                          minDate={minDate}
                          maxDate={maxDate}
                          endDate={toDate}
                          dateFormat={this.props.dayMonthYearMomentUserFormat}
                          onChange={handleChangeFromDate}
                        />
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-3 col-form-label">
                          {t('toDate')}
                        </label>
                        <DatePicker
                          selected={toDate}
                          customInput={<CustomDateInput />}
                          selectsEnd
                          startDate={fromDate}
                          minDate={fromDate}
                          maxDate={maxDate}
                          endDate={toDate}
                          dateFormat={this.props.dayMonthYearMomentUserFormat}
                          onChange={handleChangeToDate}
                          todayButton={'Today'}
                        />
                      </div>
                    </div>
                    <div className="">
                      {maxDate && minDate && (
                        <strong>
                          {t('dataAvailableFrom')}{' '}
                          {minDate.format(
                            this.props.dayMonthYearMomentUserFormat
                          )}{' '}
                          {t('to')}{' '}
                          {maxDate.format(
                            this.props.dayMonthYearMomentUserFormat
                          )}
                        </strong>
                      )}
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div className="smaller-fonts">
                      <div className="">
                        <input
                          type="checkbox"
                          checked={showVariables.temperature}
                          onChange={() => toggleVariable('temperature')}
                          className="mr-2"
                        />
                        {t('temperature')}
                      </div>
                      <div className="">
                        <input
                          type="checkbox"
                          checked={showVariables.humidity}
                          onChange={() => toggleVariable('humidity')}
                          className="mr-2"
                        />
                        {t('relativeHumidity')}
                      </div>
                      <div className="">
                        <input
                          type="checkbox"
                          checked={showVariables.solar_rad}
                          onChange={() => toggleVariable('solar_rad')}
                          className="mr-2"
                        />
                        {t('solarRadiation')}
                      </div>
                      <div className="">
                        <input
                          type="checkbox"
                          checked={showVariables.rain}
                          onChange={() => toggleVariable('rain')}
                          className="mr-2"
                        />
                        {t('rain')}
                      </div>
                      <div className="">
                        <input
                          type="checkbox"
                          checked={showVariables.wind_speed}
                          onChange={() => toggleVariable('wind_speed')}
                          className="mr-2"
                        />
                        {t('windSpeed')}
                      </div>
                    </div>
                  </Col>
                  {/* <Col md={2} sm={12} xs={12} className="h-100">
                <h5>Params</h5>
              </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export const MeteoVarsSelector = connect(state => ({
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
}))(localize()(MeteoVarsSelector_))

export default class EstateMeteoCharts_ extends React.PureComponent {
  state = {
    showVariables: {
      temperature: true,
      humidity: true,
      wind_speed: false,
      rain: true,
      solar_rad: false,
    },

    fromDate: null,
    toDate: null,
    minDate: null,
    maxDate: null,
  }

  toggleVariable = variable => {
    this.setState({
      showVariables: {
        ...this.state.showVariables,
        [variable]: !this.state.showVariables[variable],
      },
    })
  }

  handleChangeFromDate = fromDate => {
    const { maxRange } = this.state
    const maxToDate = fromDate.clone().add(maxRange, 'days')
    const newToDate =
      this.state.toDate.isBefore(maxToDate) &&
      this.state.toDate.isAfter(fromDate)
        ? this.state.toDate
        : fromDate
            .clone()
            .add(maxRange, 'days')
            .add(1, 'hours')
    let newState = { fromDate, toDate: newToDate }
    this.setState(newState)
  }

  handleChangeToDate = toDate => {
    const { maxRange, fromDate } = this.state
    const maxFromDate = toDate.clone().subtract(maxRange, 'days')
    let newState = { toDate }
    if (maxFromDate.isAfter(fromDate)) {
      const newFromDate =
        this.state.fromDate.isAfter(maxFromDate) &&
        this.state.fromDate.isAfter(toDate)
          ? this.state.fromDate
          : toDate
              .clone()
              .subtract(maxRange, 'days')
              .subtract(1, 'hours')
      newState.fromDate = newFromDate
    }
    this.setState(newState)
  }

  setDates = (dates, setCache = true) => {
    this.setState({ ...dates })
  }

  render() {
    const { stations, height, currentStationId } = this.props
    if (!stations) {
      return null
    }
    return (
      <div>
        {stations.length > 0 && (
          <MeteoVarsSelector
            showVariables={this.state.showVariables}
            toggleVariable={this.toggleVariable}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            minDate={this.state.minDate}
            maxDate={this.state.maxDate}
            maxRange={this.state.maxRange}
            handleChangeFromDate={this.handleChangeFromDate}
            handleChangeToDate={this.handleChangeToDate}
          />
        )}
        {stations.length > 0 &&
          stations.map(station => (
            <EstateMeteoChart
              currentStationId={currentStationId}
              key={station.id}
              station={station}
              height={height}
              setDates={this.setDates}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              showVariables={this.state.showVariables}
            />
          ))}
      </div>
    )
  }
}
