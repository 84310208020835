import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { getAuthUser, logout } from 'eazy-auth'
import Topbar from './Topbar'

const LayoutContent = ({
  children,
  contentClassName = 'layout-content scroll-content',
  className = 'container-fluid h-100',
  ...props
}) => (
  <div className={contentClassName}>
    <div className={className} {...props}>
      {children}
    </div>
  </div>
)

class Layout extends PureComponent {
  static Content = LayoutContent

  render() {
    const { children, user, logout, t, style = {} } = this.props
    let links = user
      ? [{ label: 'Home', to: '/' }, { label: t('estates'), to: '/estates' }]
      : []
    const rightLinks = user
      ? [
          {
            label: (
              <span>
                <i className="fa fa-user" /> {user.first_name} {user.last_name}
              </span>
            ),
            links: [
              {
                label: t('profile'),
                to: '/profile',
              },
              {
                label: 'Logout',
                onClick: logout,
              },
            ],
          },
        ]
      : []

    return (
      <div className="layout" style={{ height: '100vh', ...style }}>
        <Topbar
          // brand={<span><img src="/IrriSmart_Logo_CMYK.svg" alt="logo" height="40"/></span>}
          brand={
            <span className="logo-color">
              <b>IRRI</b>Smart
            </span>
          }
          togglerPosition="left"
          navbarClass="navbar-light bg-light"
          leftLinks={links}
          rightLinks={rightLinks}
          breakpoint="xs"
          togglerClassName="text-white"
          offCanvasPosition="left"
        />
        {children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: getAuthUser(state),
})
export default connect(
  mapStateToProps,
  {
    logout,
  }
)(localize()(Layout))
