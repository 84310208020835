import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../../localize'
import { Button, Form, FormGroup, Col, Label } from 'reactstrap'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import {
  FieldInput,
  FieldDatepickerSplit,
  FieldCheckbox,
  required,
  number,
} from '../forms'
import { getEstateFields, getEstateSectors } from '../../state/estates'
import { getDayMonthYearMomentUserFormat } from '../../state/languages'

class IrrigationForm_ extends React.PureComponent {
  render() {
    const {
      handleSubmit,
      submitting,
      t,
      appliedTo,
      useDuration,
      estateFields,
      estateSectors,
      updating = false,
    } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label sm={4} className="textm-right">
            <b>{t('appliedTo')}</b>
          </Label>
          <Col sm={8}>
            <Field
              size="sm"
              component={FieldInput}
              name="applied_to"
              disabled={updating || this.props.initialValues.fixTarget}
              validate={required}
              onChange={e => {
                if (e.target.value === 'irrigation_sector') {
                  this.props.changeFieldValue('use_duration', true)
                }
              }}
              type="select"
            >
              <option value="">{t('pleaseSelectAnOption')}</option>
              <option value="field">{t('field')}</option>
              <option value="irrigation_sector">{t('sector')}</option>
            </Field>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} className="textm-right">
            <b>{t('date')} *</b>
          </Label>
          <Col sm={8}>
            <Field
              size="sm"
              component={FieldDatepickerSplit}
              // disabled={updating}
              name="date"
              // fullWidth
              validate={required}
              dateFormat={`${this.props.dayMonthYearMomentUserFormat}`}
              timeFormat="HH:mm"
              // showTimeSelect
            />
          </Col>
        </FormGroup>
        {appliedTo === 'field' && (
          <FormGroup row>
            <Label sm={4} className="textm-right">
              <b>{t('field')} *</b>
            </Label>
            <Col sm={8}>
              <Field
                size="sm"
                component={FieldInput}
                disabled={updating || this.props.initialValues.fixTarget}
                name="id_field"
                validate={required}
                type="select"
              >
                <option value="">{t('selectField')}</option>
                {estateFields &&
                  estateFields.map(field => (
                    <option key={field.id} value={field.id}>
                      {field.name}
                    </option>
                  ))}
              </Field>
            </Col>
          </FormGroup>
        )}
        {appliedTo === 'irrigation_sector' && (
          <FormGroup row>
            <Label sm={4} className="textm-right">
              <b>{t('sector')} *</b>
            </Label>
            <Col sm={8}>
              <Field
                size="sm"
                component={FieldInput}
                disabled={updating || this.props.initialValues.fixTarget}
                name="id_irrigation_sector"
                validate={required}
                type="select"
              >
                <option value="">{t('selectIrrigationSector')}</option>
                {estateSectors &&
                  estateSectors.map(sector => (
                    <option key={sector.id} value={sector.id}>
                      {sector.name}
                    </option>
                  ))}
              </Field>
            </Col>
          </FormGroup>
        )}

        <FormGroup row>
          <Label sm={4} className="textm-right">
            <b
              className={appliedTo === 'irrigation_sector' ? 'text-muted' : ''}
            >
              {t('useDuration')}
            </b>
          </Label>
          <Col sm={8}>
            <Field
              size="sm"
              component={FieldCheckbox}
              name="use_duration"
              disabled={appliedTo === 'irrigation_sector'}
            />
          </Col>
        </FormGroup>

        {useDuration && (
          <FormGroup row>
            <Label sm={4} className="textm-right">
              <b>{t('durationMin')}</b>
            </Label>
            <Col sm={8}>
              <Field
                size="sm"
                component={FieldInput}
                name="duration"
                placeholder={t('insertDurationMinutes')}
                validate={[required, number]}
                type="text"
              />
            </Col>
          </FormGroup>
        )}

        {!useDuration && (
          <FormGroup row>
            <Label sm={4} className="textm-right">
              <b>{t('amountMM')}</b>
            </Label>
            <Col sm={8}>
              <Field
                size="sm"
                component={FieldInput}
                placeholder={t('insertAmountMm')}
                name="amount"
                validate={[required, number]}
                type="text"
              />
            </Col>
          </FormGroup>
        )}

        <div className="d-inline-flex justify-content-between pt-2 w-100">
          <Button type="button" onClick={this.props.closeModal}>
            {t('cancel')}
          </Button>
          <Button color="success" disabled={submitting}>
            {submitting ? t('saving') : t('save')}
          </Button>
        </div>
      </Form>
    )
  }
}

const selector = formValueSelector('irrigation')
const mapStateToProps = state => ({
  appliedTo: selector(state, 'applied_to'),
  useDuration: selector(state, 'use_duration'),
  estateFields: getEstateFields(state),
  estateSectors: getEstateSectors(state),
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
})

const IrrigationForm = reduxForm({
  form: 'irrigation',
})(IrrigationForm_)

export default connect(
  mapStateToProps,
  { changeFieldValue: (field, value) => change('irrigation', field, value) }
)(localize()(IrrigationForm))
