import React, { useRef } from 'react'
import get from 'lodash/get'
import { scaleLinear } from 'd3-scale'
import range from 'lodash/range'
import { arc } from 'd3-shape'
import max from 'lodash/max'
import { localize } from 'redux-i18n'

function WindSpeedChart({ width, height, data, t }) {
  const margin = 40
  const chartWidth = (width / 3) * 2

  const cx = chartWidth / 2
  const cy = height / 2

  const sectionsData = get(data, 'data.sections_data', [])
  const maxSize = Math.min(chartWidth, height) - margin * 2
  // const maxSpeed = get(data, 'data.max_wind_speed', 1)
  const maxSpeed = Math.ceil(max(sectionsData.map(x => x.value)))
  const colorScale = scaleLinear()
    .domain([0, maxSpeed])
    .range(['blue', 'lime'])

  const radiusScale = scaleLinear()
    .domain([0, maxSpeed])
    .range([0, maxSize / 2])
  const steps = range(0, maxSpeed + 1)

  const node = useRef()
  const arcGenerator = arc()

  const legendHeight = maxSize / 2
  const legendBlock = legendHeight / maxSpeed

  return (
    !!data &&
    width && (
      <div className="chart-container">
        {/* <React.Fragment>
            <i
              alt="xxx"
              className={`text-muted pointer float-right fa ${
                downloadingPng ? 'fa-spinner fa-spin' : 'fa-download'
              } download-button`}
              id={`stat-${stationId}`}
              onClick={() => this.downloadPng()}
            ></i>
            <UncontrolledTooltip placement="right" target={`stat-${stationId}`}>
              {t('downloadChart')}
            </UncontrolledTooltip>
          </React.Fragment> */}

        <svg
          ref={node}
          width={width}
          height={height}
          className="wind-chart"
          style={{ position: 'relative' }}
        >
          <text y="20" x={cx - maxSize} className="wind-chart-title">
            {t('windChartTitle')}
          </text>

          <text
            className="wind-cardinal"
            x={cx - maxSize / 2 - 20}
            y={cy}
            stroke="#222"
          >
            O
          </text>
          <text
            className="wind-cardinal"
            x={cx + maxSize / 2 + 20}
            y={cy}
            stroke="#222"
          >
            E
          </text>
          <text
            className="wind-cardinal"
            y={cy + maxSize / 2 + 20}
            x={cx}
            stroke="#222"
          >
            S
          </text>
          <text
            className="wind-cardinal"
            y={cy - maxSize / 2 - 20}
            x={cx}
            stroke="#222"
          >
            N
          </text>

          <g
            style={{
              transform: 'rotate(45deg)',
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <text
              className="wind-cardinal"
              y={cy - maxSize / 2 - 20}
              x={cx}
              stroke="#222"
            >
              NE
            </text>
          </g>

          <g
            style={{
              transform: 'rotate(-45deg)',
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <text
              className="wind-cardinal"
              y={cy - maxSize / 2 - 20}
              x={cx}
              stroke="#222"
            >
              NO
            </text>
          </g>

          <g
            style={{
              transform: 'rotate(135deg)',
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <text
              className="wind-cardinal"
              y={cy - maxSize / 2 - 20}
              x={cx}
              stroke="#222"
            >
              SE
            </text>
          </g>

          <g
            style={{
              transform: 'rotate(-135deg)',
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <text
              className="wind-cardinal"
              y={cy - maxSize / 2 - 20}
              x={cx}
              stroke="#222"
            >
              SO
            </text>
          </g>

          <line
            x1={cx - maxSize / 2 - 10}
            x2={cx + maxSize / 2 + 10}
            y1={cy}
            y2={cy}
            stroke="#222"
          ></line>
          <line
            y1={cy - maxSize / 2 - 10}
            y2={cy + maxSize / 2 + 10}
            x1={cx}
            x2={cx}
            stroke="#222"
          ></line>

          <g
            style={{
              transform: `rotate(45deg)`,
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <line
              y1={cy - maxSize / 2 - 10}
              y2={cy + maxSize / 2 + 10}
              x1={cx}
              x2={cx}
              stroke="#222"
            ></line>
          </g>

          <g
            style={{
              transform: `rotate(-45deg)`,
              transformOrigin: `${cx}px ${cy}px`,
            }}
          >
            <line
              y1={cy - maxSize / 2 - 10}
              y2={cy + maxSize / 2 + 10}
              x1={cx}
              x2={cx}
              stroke="#222"
            ></line>
          </g>

          {steps.map((step, i) => (
            <circle
              key={i}
              fill="none"
              stroke="#222"
              cx={cx}
              cy={cy}
              r={radiusScale(step)}
            ></circle>
          ))}
          <g transform={`translate(${cx}, ${cy})`}>
            {sectionsData.map((section, i) => {
              const angle = (Math.PI / 180) * section.section
              const delta = (Math.PI / 180) * (20 / 2)
              const d = arcGenerator({
                innerRadius: 0,
                outerRadius: radiusScale(section.value),
                startAngle: angle - delta,
                endAngle: angle + delta,
              })
              return (
                <path
                  key={i}
                  d={d}
                  stroke="black"
                  fill={colorScale(section.value)}
                  style={{ fillOpacity: 0.7 }}
                ></path>
              )
            })}
          </g>

          <g
            className="wind-speed-legend"
            transform={`translate(${(width / 3) * 2}, ${margin})`}
          >
            <text className="wind-speed-legend-title">{t('windSpeed')}</text>
            <g transform={`translate(0, 20)`}>
              {steps.map((step, i) => {
                return (
                  <g key={i}>
                    <rect
                      y={i * legendBlock}
                      width={20}
                      height={legendBlock}
                      style={{ fillOpacity: 0.7 }}
                      stroke={'#222'}
                      fill={colorScale(step)}
                    ></rect>
                    {(i === 0 || i === steps.length - 1) && (
                      <text
                        y={i * legendBlock + legendBlock / 2}
                        x={30}
                        className="wind-speed-legend-value"
                      >
                        {step} m/s
                      </text>
                    )}
                  </g>
                )
              })}
            </g>
          </g>
        </svg>
      </div>
    )
  )
}

export default localize()(WindSpeedChart)
