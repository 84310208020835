import React from 'react'
import ReactDOM from 'react-dom'
import { localize } from '../localize'
import keys from 'lodash/keys'
import isEqual from 'lodash/isEqual'
import values from 'lodash/values'
import get from 'lodash/get'
import { Group } from '@vx/group'

class ChartLegend extends React.PureComponent {
  state = {
    rects: {},
  }

  refsGroups = {}
  updateRects = () => {
    let rects = {}
    keys(this.refsGroups).forEach(key => {
      const node = ReactDOM.findDOMNode(this.refsGroups[key])
      if (node) {
        const rect = node.getBoundingClientRect()
        rects[key] = { width: rect.width, height: rect.height }
      }
    })
    if (!isEqual(values(rects).join('-'), values(this.state.rects).join('-'))) {
      this.setState({ rects })
    }
  }

  componentDidUpdate() {
    this.updateRects()
  }

  componentDidMount() {
    this.updateRects()
  }

  getOffsets = (margin = 10) => {
    const { rects } = this.state
    if (!rects) {
      return {}
    }

    const legendKeys = keys(rects)
    return legendKeys.reduce((acc, item, idx) => {
      if (idx === 0) {
        acc[item] = 0
      } else {
        const value =
          acc[legendKeys[idx - 1]] + rects[legendKeys[idx - 1]].width + margin
        acc[item] = value
      }

      return acc
    }, {})
  }

  render() {
    const { rects } = this.state
    const { showVariables, width, top, colors, t } = this.props
    const offsets = this.getOffsets()

    const legendWidth = values(rects)
      .map(r => r.width)
      .reduce((acc, item) => acc + item, 0)
    const left = (width - legendWidth) / 2

    return (
      <Group left={left} top={top}>
        {showVariables.temperature && (
          <g
            ref={node => (this.refsGroups['temperature'] = node)}
            transform={`translate(${get(offsets, 'temperature', 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={colors.temperature}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {t('temperature')}
            </text>
          </g>
        )}
        {showVariables.humidity && (
          <g
            ref={node => (this.refsGroups['humidity'] = node)}
            transform={`translate(${get(offsets, 'humidity', 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={colors.humidity}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {t('relativeHumidity')}
            </text>
          </g>
        )}
        {showVariables.solar_rad && (
          <g
            ref={node => (this.refsGroups['solar-rad'] = node)}
            transform={`translate(${get(offsets, 'solar-rad', 0)},0)`}
          >
            <rect
              fill={colors.solarRad}
              x={0}
              y={2}
              height={6}
              width={20}
            ></rect>
            <text x={23} y={5} className="chart-legend-label">
              {t('solarRadiation')}
            </text>
          </g>
        )}
        {showVariables.rain && (
          <g
            ref={node => (this.refsGroups['rain'] = node)}
            transform={`translate(${get(offsets, 'rain', 0)},0)`}
          >
            <rect fill={colors.rain} x={0} y={1} height={8} width={20}></rect>
            <text x={23} y={5} className="chart-legend-label">
              {t('rain')}
            </text>
          </g>
        )}
        {showVariables.wind_speed && (
          <g
            ref={node => (this.refsGroups['wind-speed'] = node)}
            transform={`translate(${get(offsets, 'wind-speed', 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={colors.windSpeed}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {t('windSpeed')}
            </text>
          </g>
        )}
      </Group>
    )
  }
}

export default localize()(ChartLegend)
