import React from 'react'
import { localize } from '../../localize'
import { connect } from 'react-redux'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  FormGroup,
  Label,
} from 'reactstrap'
import moment from 'moment'
import XLSX from 'xlsx'
import {
  getDayMonthYearMomentUserFormat,
  getNumberFormat,
} from '../../state/languages'
import {
  makeCreateBulkIrrigations,
  irrigationCreated,
  getEstateFields,
  getEstateSectors,
  getEstate,
  getEstateFieldsIrrigationTemplate,
  loadEstateFieldsIrrigationTemplate,
  getEstateSectorsIrrigationTemplate,
  loadEstateSectorsIrrigationTemplate,
} from '../../state/estates'
import find from 'lodash/find'

class UploadForm_ extends React.Component {
  state = {
    file: null,
  }
  render() {
    const {
      submitFile,
      fileError,
      appliedTo,
      t,
      estate,
      fieldsIrrigationTemplate,
      sectorsIrrigationTemplate,
    } = this.props

    const fieldsTemplateUrl = new Blob([fieldsIrrigationTemplate], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    const sectorsTemplateUrl = new Blob([sectorsIrrigationTemplate], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    return (
      <form>
        <ModalBody>
          <FormGroup row>
            <Label sm={2} className="textm-right">
              XLSX File
            </Label>
            <Col sm={10}>
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={e => {
                  e.preventDefault()
                  const { fields } = this.props
                  // convert files to an array
                  const files = [...e.target.files]
                  this.setState({ file: files[0] })
                }}
              />
            </Col>
          </FormGroup>

          <div className="p-1">
            {appliedTo === 'field' && (
              <small>
                Template {t('fields')}:{' '}
                <a
                  download={`template-fields-estate-${estate.id}.xlsx`}
                  href={`${URL.createObjectURL(fieldsTemplateUrl)}`}
                >
                  {t('download')}
                </a>
              </small>
            )}
            {appliedTo === 'irrigation_sector' && (
              <small>
                Template {t('irrigationSectors')}: :{' '}
                <a
                  download={`template-sectors-estate-${estate.id}.xlsx`}
                  href={`${URL.createObjectURL(sectorsTemplateUrl)}`}
                >
                  Download
                </a>
              </small>
            )}
          </div>

          {fileError && <div className="alert alert-danger">{fileError}</div>}
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            onClick={() => {
              submitFile(this.state.file)
            }}
            disabled={!this.state.file}
            className="btn btn-primary"
          >
            {t('loadFile')}
          </button>
        </ModalFooter>
      </form>
    )
  }
}

const UploadForm = localize()(UploadForm_)

class IrrigationsUpload extends React.PureComponent {
  state = {
    fileData: null,
    fileError: null,
    dataErrors: null,
    appliedTo: 'field',
    success: false,
  }

  componentDidMount() {
    const {
      estate,
      loadEstateFieldsIrrigationTemplate,
      loadEstateSectorsIrrigationTemplate,
    } = this.props
    loadEstateFieldsIrrigationTemplate({ id: estate.id })
    loadEstateSectorsIrrigationTemplate({ id: estate.id })
  }

  changeApplyTo = appliedTo => () => {
    this.setState({ appliedTo })
  }

  makeData = rows => {
    const {
      dayMonthYearMomentUserFormat,
      estateFields,
      estateSectors,
    } = this.props
    const { appliedTo } = this.state

    return rows.map(row => {
      let lookup = appliedTo === 'field' ? estateFields : estateSectors
      //checking if field or sector with given name and id exists
      const validTarget =
        +row['A'] &&
        row['B'] &&
        !!find(
          lookup,
          item =>
            item.id === +row['A'] &&
            item.name.toLowerCase() === row['B'].toLowerCase()
        )

      let out = {
        id: +row['A'],
        name: row['B'],
        date: row['C']
          ? moment(row['C'], dayMonthYearMomentUserFormat).format(
              dayMonthYearMomentUserFormat
            )
          : null,
        time: row['D']
          ? moment(row['D'])
              .add(moment(row['D']).utcOffset(), 'minutes')
              .format('HH:mm')
          : null,
        duration: +row['E'],
        validTarget,
      }
      out.valid =
        validTarget &&
        !isNaN(out.id) &&
        !!out.name &&
        !!out.date &&
        !!out.time &&
        !!out.duration &&
        !isNaN(out.duration)
      return out
    })
  }

  submitFile = file => {
    const fileReader = new FileReader()
    fileReader.onload = e => {
      // pre-process data
      let binary = ''
      const bytes = new Uint8Array(e.target.result)
      const length = bytes.byteLength
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i])
      }
      // call 'xlsx' to read the file
      const workbook = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      })
      // check errors
      const first_sheet_name = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[first_sheet_name]
      const jsonRows = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        header: 'A',
      })

      if (!jsonRows || !jsonRows.length || jsonRows.length < 1) {
        this.setState({
          fileError: 'Wrong file!',
        })
        return
      }
      const header = jsonRows[0]
      if (header['A'] !== 'ID') {
        this.setState({
          fileError: 'Wrong file',
          dataErrors: null,
        })
        return
      }

      const validRows = jsonRows.filter((x, i) => i > 0 && !!x['A'])
      const fileData = this.makeData(validRows)
      const dataErrors = fileData.filter(x => x.valid === false)
      this.setState({
        fileData,
        dataErrors,
      })
    }
    fileReader.readAsArrayBuffer(file)
  }

  confirmData = data => {
    const {
      estate,
      dayMonthYearMomentUserFormat,
      createBulkIrrigations,
      toggle,
      updateEvents,
    } = this.props
    const { appliedTo } = this.state
    const bulkData = data.map(item => ({
      applied_to: appliedTo,
      date:
        moment(item.date, dayMonthYearMomentUserFormat).format('YYYY-MM-DD') +
        'T' +
        item.time,
      duration: item.duration,
      id_estate: estate.id,
      id_field: appliedTo === 'irrigation_sector' ? 0 : item.id,
      id_irrigation_sector: appliedTo === 'irrigation_sector' ? item.id : 0,
      id: 0,
      type: 'irrigation',
      use_duration: true,
    }))

    createBulkIrrigations(bulkData)
      .then(resp => {
        updateEvents()
        this.setState({ success: true, fileData: null })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          dataErrors: ['Server Error:' + err],
        })
      })
  }

  render() {
    const {
      isOpen,
      toggle,
      t,
      estate,
      fieldsIrrigationTemplate,
      sectorsIrrigationTemplate,
    } = this.props
    const { fileData, fileError, dataErrors, appliedTo, success } = this.state
    const hasErrors = dataErrors && dataErrors.length > 0

    const tabFieldsDisabled = appliedTo === 'irrigation_sector' && fileData
    const tabSectorsDisabled = appliedTo === 'field' && fileData

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} toggle={toggle} size="lg">
          <ModalHeader toggle={toggle}>{t('irrigationsUpload')}</ModalHeader>
          <div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <a
                  onClick={
                    tabFieldsDisabled ? undefined : this.changeApplyTo('field')
                  }
                  className={`nav-link ${
                    appliedTo === 'field' ? 'active' : ''
                  } ${tabFieldsDisabled ? 'disabled' : ''}`}
                >
                  {t('fields')}
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={
                    tabSectorsDisabled
                      ? undefined
                      : this.changeApplyTo('irrigation_sector')
                  }
                  className={`nav-link ${
                    appliedTo === 'irrigation_sector' ? 'active' : ''
                  } ${tabSectorsDisabled ? 'disabled' : ''}`}
                >
                  {t('irrigationSectors')}
                </a>
              </li>
            </ul>
          </div>

          {!success && !fileData && (
            <UploadForm
              fieldsIrrigationTemplate={fieldsIrrigationTemplate}
              sectorsIrrigationTemplate={sectorsIrrigationTemplate}
              estate={estate}
              submitFile={this.submitFile}
              fileError={fileError}
              appliedTo={appliedTo}
            />
          )}
          {!success && fileData && (
            <React.Fragment>
              <ModalBody>
                {fileData.length > 0 && (
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>{t('Name')}</th>
                        <th>{t('Date')}</th>
                        <th>{t('Time')}</th>
                        <th>{t('Duration')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fileData.map((row, i) => (
                        <tr key={i}>
                          <td className={`${!row.valid ? 'table-danger' : ''}`}>
                            {row.id || '?'}
                          </td>
                          <td className={`${!row.valid ? 'table-danger' : ''}`}>
                            {row.name || '?'}
                            {!row.validTarget && (
                              <div>
                                <small className="text-danger">
                                  {t(
                                    appliedTo === 'field'
                                      ? 'invalid-field'
                                      : 'invalid-sector'
                                  )}
                                </small>
                              </div>
                            )}
                          </td>
                          <td className={`${!row.valid ? 'table-danger' : ''}`}>
                            {row.date || '?'}
                          </td>
                          <td className={`${!row.valid ? 'table-danger' : ''}`}>
                            {row.time || '?'}
                          </td>
                          <td className={`${!row.valid ? 'table-danger' : ''}`}>
                            {row.duration || '?'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {dataErrors && dataErrors.length > 0 && (
                  <div className="mt-2 alert alert-danger">
                    {t('irrigation-data-errors')}
                  </div>
                )}
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <button
                  className="btn btn-dark"
                  onClick={() => {
                    this.setState({
                      fileData: null,
                      fileError: null,
                      dataErrors: null,
                    })
                  }}
                >
                  CANCEL
                </button>
                <button
                  disabled={hasErrors}
                  onClick={() => this.confirmData(fileData)}
                  className="btn btn-success"
                >
                  {t('CONFIRM')}
                </button>
              </ModalFooter>
            </React.Fragment>
          )}
          {success && (
            <React.Fragment>
              <ModalBody>
                <p className="alert alert-success">
                  {t('irrigationsBulkInsertOk')}
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    toggle()
                    this.setState({ fileData: null, success: false })
                  }}
                >
                  OK
                </Button>
              </ModalFooter>
            </React.Fragment>
          )}
        </Modal>
      </React.Fragment>
    )
  }
}

export default localize()(
  connect(
    state => ({
      dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
      numberFormat: getNumberFormat(state),
      createBulkIrrigations: makeCreateBulkIrrigations(state),
      estate: getEstate(state),
      estateFields: getEstateFields(state),
      estateSectors: getEstateSectors(state),
      fieldsIrrigationTemplate: getEstateFieldsIrrigationTemplate(state),
      sectorsIrrigationTemplate: getEstateSectorsIrrigationTemplate(state),
    }),
    {
      loadEstateFieldsIrrigationTemplate,
      loadEstateSectorsIrrigationTemplate,
    }
  )(IrrigationsUpload)
)
