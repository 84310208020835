import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { ParentSize } from '@vx/responsive'
import moment from 'moment'
import { Card, CardBody, CardHeader } from 'reactstrap'
import { getEstate } from '../state/estates'
import { getUiCache, setUiCache } from '../state/ui'
import {
  makeGetNodeProviderData,
  makeGetNodeProviderDataLoading,
  loadNodeProvidersDataDict,
  unloadNodeProvidersDataDict,
} from '../state/nodeProviders'
import { get, keys, groupBy } from 'lodash'
import SoilProviderGraph from './SoilProviderGraph'
import DatesSelector from './DatesSelector'
import Spinner from './Spinner'
import { getChartHeight, getSortedProviders, getChartHasRain } from '../utils'
import { downloadNodeCSV } from '../api'
import { getAuthAccessToken } from 'eazy-auth'

class SensorNodeChart_ extends React.PureComponent {
  state = {
    fromDate: null,
    toDate: null,
    minDate: null,
    maxDate: null,
  }

  componentDidUpdate(oldProps) {
    if (oldProps.providerData !== this.props.providerData) {
      const { providerData } = this.props
      this.setState({
        minDate: moment(providerData.min_date),
        maxDate: moment(providerData.max_date),
        fromDate: moment(providerData.from_date),
        toDate: moment(providerData.to_date),
      })
    }
  }

  handleChangeFromDate = fromDate => {
    const { providerData, estate, nodeProvider } = this.props
    const maxRange = get(providerData, 'max_range', 5)
    let newState = {
      fromDate,
      toDate: fromDate
        .clone()
        .add(maxRange, 'days')
        .add(1, 'hours'),
    }
    this.setState(newState, () => {
      this.props.loadNodeProvidersDataDict(providerData.id, {
        estate_id: estate.id,
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
      })
      this.props.setUiCache(`sensor-node-page-${nodeProvider.id}`, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
      })
    })
  }

  handleChangeToDate = toDate => {
    const { providerData, estate, nodeProvider } = this.props
    const { fromDate } = this.state
    const maxRange = get(providerData, 'max_range', 5)
    const maxFromDate = toDate.clone().subtract(maxRange, 'days')
    let newState = {
      toDate,
      fromDate: toDate
        .clone()
        .subtract(maxRange, 'days')
        .subtract(1, 'hours'),
    }
    if (maxFromDate.isAfter(fromDate)) {
      newState.fromDate = maxFromDate
    }
    this.setState(newState, () => {
      this.props.loadNodeProvidersDataDict(providerData.id, {
        estate_id: estate.id,
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
      })

      this.props.setUiCache(`sensor-node-page-${nodeProvider.id}`, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
      })
    })
  }

  render() {
    const {
      nodeProvider,
      excludeSensors,
      providerData,
      providerDataLoading,
      showTitle = true,
      accessToken,
      t,
    } = this.props
    if (!nodeProvider || !providerData) {
      return (
        <div className="text-center p-5">
          <Spinner></Spinner>
        </div>
      )
    }

    let data_providers = get(providerData, 'data.data_providers', [])
    if (excludeSensors && excludeSensors.length) {
      data_providers = data_providers.filter(
        x => excludeSensors.indexOf(x.provider_type) === -1
      )
    }

    let dataProvidersByType = groupBy(data_providers, 'provider_type')
    if (dataProvidersByType['SoilWaterContent']) {
      if (dataProvidersByType['Rain']) {
        dataProvidersByType['SoilWaterContent'] = dataProvidersByType[
          'SoilWaterContent'
        ].concat(dataProvidersByType['Rain'])
      }
      if (dataProvidersByType['irrigation']) {
        dataProvidersByType['SoilWaterContent'] = dataProvidersByType[
          'SoilWaterContent'
        ].concat(dataProvidersByType['irrigation'])
      }
    }

    if (dataProvidersByType['AttainableWater']) {
      if (dataProvidersByType['Rain']) {
        dataProvidersByType['AttainableWater'] = dataProvidersByType[
          'AttainableWater'
        ].concat(dataProvidersByType['Rain'])
      }
      if (dataProvidersByType['irrigation']) {
        dataProvidersByType['AttainableWater'] = dataProvidersByType[
          'AttainableWater'
        ].concat(dataProvidersByType['irrigation'])
      }
    }
    if (dataProvidersByType['Rain']) {
      delete dataProvidersByType['Rain']
    }
    if (dataProvidersByType['irrigation']) {
      delete dataProvidersByType['irrigation']
    }

    const currentParams =
      this.state.fromDate && this.state.toDate
        ? {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
            estate_id: this.props.estate.id,
          }
        : {
            estate_id: this.props.estate.id,
          }

    return (
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <DatesSelector
              title={
                showTitle
                  ? `Sensor Node ${nodeProvider.name} (${data_providers.length} sensors)`
                  : undefined
              }
              flexDirection="flex-direction-row"
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              handleChangeFromDate={this.handleChangeFromDate}
              handleChangeToDate={this.handleChangeToDate}
              right={
                <small
                  id={`download-${nodeProvider.name}`}
                  className="text-primary pointer"
                  href={null}
                  onClick={() => {
                    downloadNodeCSV(
                      nodeProvider.id,
                      currentParams,
                      `${nodeProvider.id}.csv`,
                      accessToken
                    )
                  }}
                >
                  {t('downloadCSV')}
                </small>
              }
              rightTooltip={t('downloadCSV')}
              rightTooltipTarget={`download-${nodeProvider.id}`}
              rightTooltipPlacement="bottom-start"
              loading={providerDataLoading}
            />
          </div>
        </CardHeader>

        <CardBody>
          {data_providers &&
            data_providers.length > 0 &&
            getSortedProviders(keys(dataProvidersByType)).map(
              (providerType, i) => (
                <div key={i} className="mb-4 pb-3 border-bottom">
                  <ParentSize>
                    {({ width: w, height: h }) => {
                      return (
                        <SoilProviderGraph
                          width={w}
                          height={getChartHeight(providerType)}
                          hasRain={getChartHasRain(providerType)}
                          dataProvidersByType={dataProvidersByType}
                          currentParams={currentParams}
                          providerType={providerType}
                          providerData={providerData}
                        />
                      )
                    }}
                  </ParentSize>
                </div>
              )
            )}
        </CardBody>
      </Card>
    )
  }

  componentDidMount() {
    const { nodeProvider, estate, uiCache } = this.props
    let loadParams = {
      estate_id: estate.id,
    }
    if (uiCache[`sensor-node-page-${nodeProvider.id}`]) {
      loadParams = {
        ...loadParams,
        ...uiCache[`sensor-node-page-${nodeProvider.id}`],
      }
    }

    this.props.loadNodeProvidersDataDict(nodeProvider.id, loadParams)
  }

  componentWillUnmount() {
    const { nodeProvider } = this.props
    this.props.unloadNodeProvidersDataDict(nodeProvider.id)
  }
}

SensorNodeChart_.defaultProps = {
  height: 400,
}

const makeMapStateToPropsNode = () => {
  const mapStateToProps = (state, props) => {
    const getNodeProviderData = makeGetNodeProviderData()
    const getNodeProviderDataLoading = makeGetNodeProviderDataLoading()
    return {
      providerData: props.nodeProvider
        ? getNodeProviderData(state, props.nodeProvider.id)
        : null,
      providerDataLoading: props.nodeProvider
        ? getNodeProviderDataLoading(state, props.nodeProvider.id)
        : null,
      estate: getEstate(state),
      uiCache: getUiCache(state),
      accessToken: getAuthAccessToken(state),
    }
  }
  return mapStateToProps
}

const mapDispatchToPropsNode = {
  loadNodeProvidersDataDict,
  unloadNodeProvidersDataDict,
  setUiCache,
}

export const SensorNodeChart = connect(
  makeMapStateToPropsNode,
  mapDispatchToPropsNode
)(localize()(SensorNodeChart_))
