import React, { useState, useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Table,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverBody,
  PopoverHeader,
  PopoverFooter,
} from 'reactstrap'
import { connect } from 'react-redux'
import {
  getFullDatetimeUserFormat,
  getNumberFormat,
} from '../../state/languages'
import { setCurrentIrrigation } from '../../state/irrigationEdit'
import {
  getEstate,
  deleteEstateAllegedIrrigationEvent,
} from '../../state/estates'
import { localize } from '../../localize'
import moment from 'moment'

const ThWithOrder = ({ children, orderBy, setter, className, orderKey }) => {
  const isNeg = orderBy && orderBy[0] === '-'
  const currentOrderKey = orderBy ? (isNeg ? orderBy.slice(1) : orderBy) : null

  const faClass =
    orderBy && currentOrderKey === orderKey
      ? isNeg
        ? 'fa-caret-down'
        : 'fa-caret-up'
      : 'fa-sort'

  return (
    <th className={`pointer ${className || ''}`} onClick={setter}>
      {children}
      <span>
        {' '}
        <i className={`fa ${faClass}`}></i>
      </span>
    </th>
  )
}

const AllegedIrrigationsPanel = ({
  allegedIrrigations,
  fullDatetimeUserFormat,
  estate,
  setCurrentIrrigation,
  t,
  deleteEstateAllegedIrrigationEvent,
}) => {
  const events = allegedIrrigations || []

  const setIrrigation = event => {
    return setCurrentIrrigation({
      id_estate: estate.id,
      type: 'irrigation',
      date: event.date,
      use_duration: false,
      amount: 0,
      duration: 0,
      id_irrigation_sector: 0,
      id: 0,
      id_field: event.id_field,
      applied_to: 'field',
      alleged_irrigation_event: event,
    })
  }

  const [dropCandidate, setDropCandidate] = useState(null)

  const [orderBy, setOrderBy] = useState('-date')

  const setter = key => () => {
    const isNeg = orderBy && orderBy[0] === '-'
    const currentOrderKey = orderBy
      ? isNeg
        ? orderBy.slice(1)
        : orderBy
      : null
    if (key === currentOrderKey) {
      setOrderBy(isNeg ? `${key}` : `-${key}`)
    } else {
      setOrderBy(`${key}`)
    }
  }

  const orderedEvents = useMemo(() => {
    if (!events) {
      return events
    }
    const isNeg = orderBy && orderBy[0] === '-'
    const currentOrderKey = orderBy
      ? isNeg
        ? orderBy.slice(1)
        : orderBy
      : null
    if (currentOrderKey === 'date') {
      return sortBy(events, i =>
        isNeg ? -moment(i.date).valueOf() : moment(i.date).valueOf()
      )
    }
    if (currentOrderKey === 'field') {
      let out = sortBy(events, i => i.field_name)
      if (isNeg) {
        out = out.reverse()
      }
      return out
    }
    return events
  }, [events, orderBy])

  return (
    <div>
      <Card className="mt-4" style={{ maxHeight: '400px', overflow: 'auto' }}>
        <div className="text-center bg-light p-1" style={{ fontSize: 14 }}>
          <b>{t('allegedIrrigations')}</b>
        </div>

        <Table size="sm" style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <ThWithOrder
                orderBy={orderBy}
                orderKey={'date'}
                setter={setter('date')}
              >
                {t('date')}
              </ThWithOrder>
              <ThWithOrder
                orderBy={orderBy}
                orderKey={'field'}
                setter={setter('field')}
              >
                {t('field')}
              </ThWithOrder>
              <th className="text-center">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {orderedEvents.map((event, i) => (
              <tr key={i}>
                <td>{moment(event.date).format(fullDatetimeUserFormat)}</td>
                <td>{event.field_name}</td>
                <td>
                  <Button
                    size="sm mr-2"
                    color="danger"
                    id={`trash-${event.id}`}
                    onClick={() => setDropCandidate(event)}
                  >
                    <small>
                      <span className="fa fa-trash"></span>
                    </small>
                  </Button>
                  <UncontrolledTooltip
                    target={`trash-${event.id}`}
                    placement="bottom"
                  >
                    <small>{t('removeAllegedIrrigation')}</small>
                  </UncontrolledTooltip>

                  <Button
                    size="sm"
                    color="success"
                    id={`accept-${event.id}`}
                    onClick={() => setIrrigation(event)}
                  >
                    <small>
                      <span className="fa fa-check"></span>
                    </small>
                  </Button>
                  <UncontrolledTooltip
                    target={`accept-${event.id}`}
                    placement="bottom"
                  >
                    <small>{t('insertAllegedIrrigation')}</small>
                  </UncontrolledTooltip>
                  <Popover
                    placement="bottom-end"
                    boundariesElement="#root"
                    isOpen={dropCandidate && dropCandidate.id === event.id}
                    target={`trash-${event.id}`}
                    toggle={() => setDropCandidate(null)}
                  >
                    <PopoverHeader className="bg-danger text-white">
                      {t('removeAllegedIrrigation')}
                    </PopoverHeader>
                    {dropCandidate && (
                      <PopoverBody>
                        {t('removeAllegedIrrigationConfirm')}
                        <div className="p-2 mt-2">
                          <b>Date:</b>{' '}
                          {moment(dropCandidate.date).format(
                            fullDatetimeUserFormat
                          )}
                          <br />
                          <b>{t('field')}:</b> {dropCandidate.field_name}
                          <br />
                        </div>
                        <div className="d-flex justify-content-between">
                          <Button
                            size="sm"
                            color="secondary"
                            onClick={() => setDropCandidate(null)}
                          >
                            {t('cancel')}
                          </Button>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => {
                              deleteEstateAllegedIrrigationEvent({
                                id: estate.id,
                                idEvent: dropCandidate.id,
                              }).then(resp => {
                                setDropCandidate(null)
                              })
                            }}
                          >
                            {t('delete suggestion')}
                          </Button>{' '}
                        </div>
                      </PopoverBody>
                    )}
                  </Popover>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  fullDatetimeUserFormat: getFullDatetimeUserFormat(state),
  numberFormat: getNumberFormat(state),
})

export default connect(
  mapStateToProps,
  { setCurrentIrrigation, deleteEstateAllegedIrrigationEvent }
)(localize()(AllegedIrrigationsPanel))
