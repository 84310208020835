import React, { PureComponent } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import Layout from '../components/Layout'
import { Row, Col } from 'reactstrap'
import {
  loadEstates,
  unloadEstates,
  getEstates,
  getEstatesLoading,
} from '../state/estates'
import Spinner from '../components/Spinner'
import EstateMiniMap from '../components/EstateMiniMap'

class Estates extends PureComponent {
  componentDidMount() {
    this.props.loadEstates()
  }

  componentWillUnmount() {
    this.props.unloadEstates()
  }

  render() {
    const { estates, loading, t } = this.props

    return (
      <Layout>
        <Layout.Content className="container-fluid h-100">
          <div className="mt-3">
            <h4>{t('estates')}</h4>
            <div className="list-group mt-3">
              {loading && (
                <div className="text-center my-5 py-5">
                  <Spinner className="text-muted" />
                </div>
              )}
              {estates && estates.length === 1 && (
                <Redirect to={`/estates/${estates[0].id}`} />
              )}
              {estates &&
                estates.map(estate => (
                  <Link
                    key={estate.id}
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    to={`/estates/${estate.id}`}
                  >
                    <Row className="">
                      <Col md={8} className="mb-2">
                        <h5 className="mb-1 text-dark">{estate.name}</h5>
                        <p className="mb-1">
                          {estate.address}, {estate.city} ({estate.province}) -{' '}
                          {estate.nation}
                        </p>
                        {/* <small>Something small we'd like to say.</small> */}
                      </Col>
                      <Col md={4} className="d-flex d-row justify-content-end">
                        <EstateMiniMap
                          width={300}
                          height={200}
                          estate={estate}
                          className=""
                        />
                      </Col>
                      {/* <small>3 days ago</small> */}
                    </Row>
                  </Link>
                ))}
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  estates: getEstates(state),
  loading: getEstatesLoading(state),
})
export default connect(
  mapStateToProps,
  { loadEstates, unloadEstates }
)(localize()(Estates))
