import React, { useState } from 'react'
import {
  Table,
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { Link } from 'react-router-dom'
import { setMapBounds, setMapObject, setSensorNodeModal } from '../state/ui'
import { get } from 'lodash'
import DeviceData from './DeviceData'
import classNames from 'classnames'
import moment from 'moment'
import {
  getDayMonthYearMomentUserFormat,
  getFullDatetimeUserFormat,
} from '../state/languages'
import Toggle from 'react-bootstrap-toggle'
import {
  getEstate,
  toggleSensorNodeOnOff,
  toggleSensorNodeOnOffLoading,
  toggleSensorNodeNotificationOnOff,
  toggleSensorNodeNotificationOnOffLoading,
} from '../state/estates'

const getWealthBackground = wealth => {
  if (!wealth) {
    return '#fff'
  }

  const w = wealth.toLowerCase()
  return w
}

const SensorDataList = ({ data }) => {
  if (!data) {
    return null
  }

  return (
    <div>
      {data.map((datum, i) => {
        return (
          <div key={i}>
            <i>{datum.label}</i>: {get(datum, 'values[0].value', '-')}{' '}
            {datum.unit_of_measure}{' '}
            <span
              className="device-datum"
              style={{ background: get(datum, 'color') }}
            ></span>
          </div>
        )
      })}
    </div>
  )
}

const DeviceManagementRow = ({
  device,
  fullDatetimeUserFormat,
  estate,
  toggleSensorNodeOnOff,
  toggleSensorNodeOnOffLoading,
  toggleSensorNodeNotificationOnOff,
  toggleSensorNodeNotificationOnOffLoading,
}) => {
  return (
    <tr key={device.id}>
      <td>{device.name}</td>
      <td
        style={{
          background: getWealthBackground(device.wealth_color),
        }}
      ></td>
      <td>
        {moment(device.device.last_transmission.date).format(
          fullDatetimeUserFormat
        )}
      </td>
      <td className="text-center">
        <Toggle
          onClick={() => {
            toggleSensorNodeOnOff({
              estateId: estate.id,
              id: device.id,
              turnOn: device.is_turned_off,
            })
          }}
          on={'on'}
          off={'off'}
          size="sm"
          offstyle="danger"
          onstyle="success"
          disabled={toggleSensorNodeOnOffLoading}
          active={!device.is_turned_off}
        />
      </td>
      <td className="text-center">
        <Toggle
          onClick={() => {
            toggleSensorNodeNotificationOnOff({
              estateId: estate.id,
              id: device.id,
              turnOn: !!get(device, 'notification_status'),
            })
          }}
          on={'on'}
          off={'off'}
          size="sm"
          offstyle="danger"
          onstyle="success"
          disabled={toggleSensorNodeNotificationOnOffLoading}
          active={!get(device, 'notification_status')}
        />
      </td>
    </tr>
  )
}

const DeviceManagementRowUnmanaged = ({ device }) => {
  return (
    <tr>
      <td>{device.name}</td>
      <td
        style={{
          background: getWealthBackground(device.wealth),
        }}
      ></td>
      <td className="text-center">-</td>
      <td className="text-center">
        <Toggle
          on={'on'}
          off={'off'}
          size="sm"
          offstyle="danger"
          onstyle="success"
          disabled
          active={!device.is_turned_off}
        />
      </td>
      <td className="text-center">
        <Toggle
          on={'on'}
          off={'off'}
          size="sm"
          offstyle="danger"
          onstyle="success"
          disabled
          active={!device.notification_status}
        />
      </td>
    </tr>
  )
}

const DevicesList = ({
  t,
  devices,
  fullDatetimeUserFormat,
  zoomToObject,
  estate,
  toggleSensorNodeOnOff,
  toggleSensorNodeOnOffLoading,
  toggleSensorNodeNotificationOnOff,
  toggleSensorNodeNotificationOnOffLoading,
  meteoDevices = false,
}) => {
  const [currentPanel, setCurrentPanel] = useState('info')
  return (
    <div>
      <Nav tabs>
        <NavItem className="pointer">
          <NavLink
            className={classNames({ active: currentPanel === 'info' })}
            onClick={() => {
              setCurrentPanel('info')
            }}
          >
            <i className="fa fa-info"></i>{' '}
            <span className="text-capitalize">{t('info')}</span>
          </NavLink>
        </NavItem>

        <NavItem className="pointer">
          <NavLink
            className={classNames({ active: currentPanel === 'management' })}
            onClick={() => {
              setCurrentPanel('management')
            }}
          >
            <i className="fa fa-wrench"></i>{' '}
            <span className="text-capitalize">{t('management')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <div className="my-3">
        {currentPanel === 'info' && (
          <div>
            <table className="table table-bordered table-striped table-condensed table-responsive">
              <thead className="thead-white">
                <tr>
                  <th className="text-capitalize">{t('name')}</th>
                  <th className="text-capitalize">{t('serialNumber')}</th>
                  <th className="text-capitalize">
                    {t('lastTransmissionDate')}
                  </th>
                  <th className="text-capitalize">
                    {t('lastTransmissionSensorValues')}
                  </th>
                  <th className="text-capitalize">
                    RSSI (dBm) {t('lastTransmission')}
                  </th>
                  <th className="text-capitalize">
                    s/n (dB) {t('lastTransmission')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {devices &&
                  devices.length > 0 &&
                  devices.map(device => (
                    <tr key={device.id}>
                      <td>
                        <span
                          className="text-primary"
                          id={`UncontrolledPopover-${device.id}`}
                          type="button"
                        >
                          {device.name}
                        </span>
                        <UncontrolledPopover
                          trigger="hover"
                          placement="right"
                          target={`UncontrolledPopover-${device.id}`}
                        >
                          <PopoverHeader>{device.name}</PopoverHeader>
                          <PopoverBody>
                            <DeviceData
                              columnClassName={'col-sm-12'}
                              device={device.device}
                              latitude={device.latitude}
                              longitude={device.longitude}
                              elevation={device.elevation}
                            ></DeviceData>
                          </PopoverBody>
                        </UncontrolledPopover>{' '}
                        <i
                          className="fa fa-globe pointer"
                          onClick={() => zoomToObject(device, 'device')}
                        ></i>
                      </td>
                      <td>{device.device && device.device.serial_number}</td>
                      <td>
                        {device.device &&
                          device.device.last_transmission &&
                          moment(device.device.last_transmission.date).format(
                            fullDatetimeUserFormat
                          )}
                      </td>
                      <td>
                        <SensorDataList
                          data={get(device, 'device.last_transmission.data')}
                        ></SensorDataList>
                        {device.device && (
                          <div>
                            <small>
                              <Link
                                to={
                                  meteoDevices
                                    ? `/estates/${estate.id}/?tabName=meteo`
                                    : `/estates/${estate.id}/sensor-nodes/${device.id}`
                                }
                              >
                                {t('showData')}
                              </Link>
                            </small>
                          </div>
                        )}
                      </td>
                      <td>
                        {device.device &&
                          device.device.last_transmission &&
                          device.device.last_transmission.rssi}
                      </td>
                      <td>
                        {device.device &&
                          device.device.last_transmission &&
                          device.device.last_transmission.snr}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {currentPanel === 'management' && (
          <div>
            <table className="table table-bordered table-striped table-condensed table-responsive">
              <thead className="thead-white">
                <tr>
                  <th className="text-capitalize">{t('name')}</th>
                  <th className="text-capitalize">{t('wealth')}</th>
                  <th className="text-capitalize">
                    {t('lastTransmissionDate')}
                  </th>
                  <th className="text-capitalize">{t('nodeOnOffStatus')}</th>
                  <th className="text-capitalize">
                    {t('nodeNotificationsOnOffStatus')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {devices &&
                  devices.length > 0 &&
                  devices.map(device => {
                    return device.device ? (
                      <DeviceManagementRow
                        device={device}
                        fullDatetimeUserFormat={fullDatetimeUserFormat}
                        estate={estate}
                        toggleSensorNodeOnOff={toggleSensorNodeOnOff}
                        toggleSensorNodeOnOffLoading={
                          toggleSensorNodeOnOffLoading
                        }
                        toggleSensorNodeNotificationOnOff={
                          toggleSensorNodeNotificationOnOff
                        }
                        toggleSensorNodeNotificationOnOffLoading={
                          toggleSensorNodeNotificationOnOffLoading
                        }
                      />
                    ) : (
                      <DeviceManagementRowUnmanaged device={device} />
                    )
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

const GatewaysList = ({ t, estate, gateways, zoomToObject }) => {
  return (
    <div>
      <Nav tabs>
        <NavItem className="pointer">
          <NavLink className="active">
            <i className="fa fa-info"></i>{' '}
            <span className="text-capitalize">{t('info')}</span>
          </NavLink>
        </NavItem>
      </Nav>

      <div className="my-3">
        <table className="table table-bordered table-striped table-condensed xtable-responsive">
          <thead className="thead-white">
            <tr>
              <th className="text-capitalize">{t('name')}</th>
              <th className="text-capitalize">{t('tag')}</th>
            </tr>
          </thead>
          <tbody>
            {gateways &&
              gateways.length > 0 &&
              gateways.map(gateway => (
                <tr key={gateway.id}>
                  <td>
                    <span
                      className="text-primary"
                      id={`UncontrolledPopover-gateway${gateway.id}`}
                      type="button"
                    >
                      {gateway.name}
                    </span>
                    <UncontrolledPopover
                      trigger="hover"
                      placement="right"
                      target={`UncontrolledPopover-gateway${gateway.id}`}
                    >
                      <PopoverHeader>{gateway.name}</PopoverHeader>
                      <PopoverBody>
                        <DeviceData
                          columnClassName={'col-sm-12'}
                          device={gateway.device}
                          latitude={gateway.latitude}
                          longitude={gateway.longitude}
                          elevation={gateway.elevation}
                        ></DeviceData>
                      </PopoverBody>
                    </UncontrolledPopover>{' '}
                    <i
                      className="fa fa-globe pointer"
                      onClick={() => zoomToObject(gateway, 'gateway')}
                    ></i>
                  </td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

class EstateConfigurationHardwarePlain extends React.PureComponent {
  state = {
    sensor: {},
    meteo_station: {},
    gateway: {},
    currentPanel: 'devices',
  }

  togglePanel = panel => {
    const { currentPanel } = this.state
    if (currentPanel !== panel) {
      this.setState({ currentPanel: panel })
    }
  }

  toggleInfo = (type, id) => {
    const currentStatus = get(this.state, type, {})
    this.setState({
      [type]: {
        ...currentStatus,
        [id]: !get(currentStatus, id),
      },
    })
  }

  isInfoShown = (type, id) => {
    const currentStatus = get(this.state, type, {})
    return !!get(currentStatus, id)
  }

  zoomToObject = (obj, type) => {
    this.props.setMapBounds({
      center: [obj.latitude, obj.longitude],
      zoom: 15,
    })
    this.props.setMapObject({ type: type, id: obj.id, item: obj })
  }

  render() {
    const {
      hardware,
      estate,
      t,
      fullDatetimeUserFormat,
      toggleSensorNodeOnOff,
      toggleSensorNodeOnOffLoading,
      toggleSensorNodeNotificationOnOff,
      toggleSensorNodeNotificationOnOffLoading,
    } = this.props
    const { sensor_nodes, gateways, meteo_stations } = hardware
    const { currentPanel } = this.state

    return (
      estate && (
        <div className="mt-4 pb-4">
          <Nav tabs>
            <NavItem className="pointer">
              <NavLink
                className={classNames({ active: currentPanel === 'devices' })}
                onClick={() => {
                  this.togglePanel('devices')
                }}
              >
                <i className="fa fa-tablet"></i>{' '}
                <span className="text-capitalize">{t('devices')}</span>
              </NavLink>
            </NavItem>

            <NavItem className="pointer">
              <NavLink
                className={classNames({
                  active: currentPanel === 'meteo-stations',
                })}
                onClick={() => {
                  this.togglePanel('meteo-stations')
                }}
              >
                <i className="fa fa-bar-chart"></i>{' '}
                <span className="text-capitalize">{t('meteoStations')}</span>
              </NavLink>
            </NavItem>

            <NavItem className="pointer">
              <NavLink
                className={classNames({ active: currentPanel === 'gateways' })}
                onClick={() => {
                  this.togglePanel('gateways')
                }}
              >
                <i className="fa fa-random"></i>{' '}
                <span className="text-capitalize">{t('gateways')}</span>
              </NavLink>
            </NavItem>
          </Nav>

          <div className="my-3">
            {currentPanel === 'devices' && (
              <DevicesList
                t={t}
                estate={estate}
                devices={sensor_nodes}
                fullDatetimeUserFormat={fullDatetimeUserFormat}
                zoomToObject={this.zoomToObject}
                toggleSensorNodeOnOff={toggleSensorNodeOnOff}
                toggleSensorNodeOnOffLoading={toggleSensorNodeOnOffLoading}
                toggleSensorNodeNotificationOnOff={
                  toggleSensorNodeNotificationOnOff
                }
                toggleSensorNodeNotificationOnOffLoading={
                  toggleSensorNodeNotificationOnOffLoading
                }
              ></DevicesList>
            )}
            {currentPanel === 'meteo-stations' && (
              <DevicesList
                t={t}
                estate={estate}
                devices={meteo_stations}
                fullDatetimeUserFormat={fullDatetimeUserFormat}
                zoomToObject={this.zoomToObject}
                toggleSensorNodeOnOff={toggleSensorNodeOnOff}
                toggleSensorNodeOnOffLoading={toggleSensorNodeOnOffLoading}
                toggleSensorNodeNotificationOnOff={
                  toggleSensorNodeNotificationOnOff
                }
                toggleSensorNodeNotificationOnOffLoading={
                  toggleSensorNodeNotificationOnOffLoading
                }
                meteoDevices
              ></DevicesList>
            )}

            {currentPanel === 'gateways' && (
              <GatewaysList
                t={t}
                estate={estate}
                gateways={gateways}
                fullDatetimeUserFormat={fullDatetimeUserFormat}
                zoomToObject={this.zoomToObject}
              />
            )}
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
  fullDatetimeUserFormat: getFullDatetimeUserFormat(state),
  toggleSensorNodeOnOffLoading: toggleSensorNodeOnOffLoading(state),
  toggleSensorNodeNotificationOnOffLoading: toggleSensorNodeNotificationOnOffLoading(
    state
  ),
})

const EstateConfigurationHardware = connect(
  mapStateToProps,
  {
    setMapBounds,
    setMapObject,
    setSensorNodeModal,
    toggleSensorNodeOnOff,
    toggleSensorNodeNotificationOnOff,
  }
)(localize()(EstateConfigurationHardwarePlain))

export default class EstateConfiguration extends React.PureComponent {
  render() {
    const { estate } = this.props

    return (
      <div>
        {estate && estate.hardware && (
          <EstateConfigurationHardware
            estate={estate}
            hardware={estate.hardware}
          />
        )}
      </div>
    )
  }
}
