import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import get from 'lodash/get'
import { inferContinuousPeriods } from '../utils'
import { rj, composeReducers } from 'redux-rocketjump'
import { rjDetail, rjIrrigationEvents } from './utils'
import {
  createIrrigationReducer,
  updateIrrigationReducer,
  deleteIrrigationReducer,
} from './estates'

// Namespace for actions
const NS = '@fields/'

// List
const GET_FIELDS = `${NS}GET_FIELDS`
export const {
  actions: { load: loadFields, unload: unloadFields },
  selectors: { getData: getFields, isLoading: getFieldsLoading },
  reducer: listReducer,
  saga: listSaga,
} = rj({
  type: GET_FIELDS,
  state: 'fields.list',
  effectCaller: authApiCall,
  effect: t => params =>
    withToken(t, request.get(`${API_URL}fields`))
      .query(params)
      .then(({ body }) => body),
})()

// Detail
const GET_FIELD = `${NS}GET_FIELD`
export const {
  actions: { load: loadField, unload: unloadField },
  selectors: { getData: getField },
  reducer: detailReducer,
  saga: detailSaga,
} = rj(rjDetail(), {
  type: GET_FIELD,
  state: 'fields.detail',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}fields/${id}`).query(params)).then(
      ({ body }) => body
    ),
})()

// Notifications list
const GET_FIELDS_NOTIFICATIONS = `${NS}GET_FIELDS_NOTIFICATIONS`
export const {
  actions: { load: loadFieldNotifications, unload: unloadFieldNotifications },
  selectors: {
    getData: getFieldNotifications,
    isLoading: getFieldNotificationsLoading,
  },
  reducer: detailNotificationsReducer,
  saga: detailNotificationsSaga,
} = rj(rjDetail(), {
  type: GET_FIELDS_NOTIFICATIONS,
  state: 'fields.detailNotifications',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}fields/${id}/notifications`)).then(
      ({ body }) => body
    ),
})()

// Field data
const GET_FIELDS_DATA = `${NS}GET_FIELDS_DATA`
export const {
  actions: { load: loadFieldData, unload: unloadFieldData },
  selectors: { getData: getFieldData, isLoading: getFieldDataLoading },
  reducer: detailDataReducer,
  saga: detailDataSaga,
} = rj(rjDetail(), {
  type: GET_FIELDS_DATA,
  state: 'fields.detailData',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}fields/${id}/data`).query(params)).then(
      ({ body }) => body
    ),
  selectors: {
    getData: ({ getData }) =>
      createSelector(
        getData,
        data => {
          const rainValues = get(data, 'data.rain.values', [])
          const rainPeriods = inferContinuousPeriods(rainValues)
          const irrigationValues = get(data, 'data.irrigation.values', [])
          const irrigationPeriods = inferContinuousPeriods(irrigationValues)

          return { ...data, rainPeriods, irrigationPeriods }
        }
      ),
  },
})()

// Field Soil Svg Chart
const GET_FIELDS_SOIL_SVG_CHART = `${NS}GET_FIELDS_SOIL_SVG_CHART`
export const {
    actions: { load: loadFieldSoilSvgChart, unload: unloadFieldSoilSvgChart },
    selectors: { getData: getFieldSoilSvgChart, isLoading: getFieldSoilSvgChartLoading },
    reducer: fieldSoilSvgChartReducer,
    saga: fieldSoilSvgChartSaga,
} = rj(rjDetail(), {
    type: GET_FIELDS_SOIL_SVG_CHART,
    state: 'fields.soilSvgChart',
    effectCaller: authApiCall,
    effect: t => ({ id, ...params }) =>
        withToken(t, request.get(`${API_URL}fields/${id}/soil-svg-chart`).query(params)).then(
            ({ body }) => body
        ),
})()

// Irrigations list
const GET_FIELDS_IRRIGATIONS = `${NS}GET_FIELDS_IRRIGATIONS`
export const {
  actions: { load: loadFieldIrrigations, unload: unloadFieldIrrigations },
  selectors: {
    getData: getFieldIrrigations,
    isLoading: getFieldIrrigationsLoading,
  },
  reducer: detailIrrigationsReducer,
  saga: detailIrrigationsSaga,
} = rj(rjIrrigationEvents(), {
  type: GET_FIELDS_IRRIGATIONS,
  state: 'fields.detailIrrigations',
  effectCaller: authApiCall,
  reducer: reducer =>
    composeReducers(
      reducer,
      updateIrrigationReducer,
      createIrrigationReducer,
      deleteIrrigationReducer
    ),
  effect: t => ({ idEstate, idField, params }) =>
    withToken(
      t,
      request.get(
        `${API_URL}calendar/estates/${idEstate}/fields/${idField}/irrigations`
      )
    )
      .query(params)
      .then(({ body }) => body),
})()

// Soil providers list
const GET_FIELDS_SOIL_PROVIDERS = `${NS}GET_FIELDS_SOIL_PROVIDERS`
export const {
  actions: { load: loadFieldSoilProviders, unload: unloadFieldSoilProviders },
  selectors: {
    getData: getFieldSoilProviders,
    isLoading: getFieldSoilProvidersLoading,
  },
  reducer: detailSoilProvidersReducer,
  saga: detailSoilProvidersSaga,
} = rj(rjDetail(), {
  type: GET_FIELDS_SOIL_PROVIDERS,
  state: 'fields.detailSoilProviders',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}fields/${id}/soil-providers`)).then(
      ({ body }) => body
    ),
})()

// Dict
const GET_SIMULATIONS_DICT = `${NS}GET_SIMULATIONS_DICT`
export const {
  actions: { load: loadSimulationsDict, unload: unloadSimulationsDict },
  selectors: { makeGetData: makeGetFieldSimulation },
  reducer: simulationsDictReducer,
  saga: simulationsDictSaga,
} = rj(rjDetail(), {
  type: GET_SIMULATIONS_DICT,
  state: 'fields.simulationsDict',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}simulations/field/${id}/data`)).then(
      ({ body }) => ({ ...body, id })
    ),
})()

// Irrigation preview
const GET_FIELD_IRRIGATION_PREVIEW = `${NS}GET_FIELD_IRRIGATION_PREVIEW`
export const {
  actions: {
    load: loadFieldIrrigationPreview,
    unload: unloadFieldIrrigationPreview,
  },
  selectors: {
    getData: getFieldIrrigationPreview,
    isLoading: getFieldIrrigationPreviewLoading,
  },
  reducer: detailIrrigationPreviewReducer,
  saga: detailIrrigationPreviewSaga,
} = rj(rjDetail(), {
  type: GET_FIELD_IRRIGATION_PREVIEW,
  state: 'fields.detailIrrigationPreview',
  effectCaller: authApiCall,
  effect: t => ({ id, ...data }) => {
    return withToken(
      t,
      request.post(`${API_URL}fields/${id}/simulate-irrigation`)
    )
      .set('Content-Type', 'application/json')
      .send({ ...data, id: 0 })
      .then(({ body }) => body)
  },
})()

export const reducer = combineReducers({
  list: listReducer,
  detail: detailReducer,
  simulationsDict: simulationsDictReducer,
  detailNotifications: detailNotificationsReducer,
  detailData: detailDataReducer,
  soilSvgChart: fieldSoilSvgChartReducer,
  detailSoilProviders: detailSoilProvidersReducer,
  detailIrrigations: detailIrrigationsReducer,
  detailIrrigationPreview: detailIrrigationPreviewReducer,
})

export const saga = function*() {
  yield fork(listSaga)
  yield fork(detailSaga)
  yield fork(simulationsDictSaga)
  yield fork(detailNotificationsSaga)
  yield fork(detailDataSaga)
  yield fork(fieldSoilSvgChartSaga)
  yield fork(detailSoilProvidersSaga)
  yield fork(detailIrrigationsSaga)
  yield fork(detailIrrigationPreviewSaga)
}
