import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { localize } from '../../localize'
import { get } from 'lodash'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import {
  getCurrentIrrigation,
  setCurrentIrrigation,
  unsetCurrentIrrigation,
} from '../../state/irrigationEdit'
import { getDayMonthYearMomentUserFormat } from '../../state/languages'
import {
  makeCreateIrrigation,
  makeUpdateIrrigation,
  irrigationCreated,
  irrigationUpdated,
  deleteEstateIrrigationEvent,
} from '../../state/estates'
import IrrigationModal from './IrrigationModal'
import { Tooltip } from 'reactstrap'
import { getEstate } from '../../state/estates'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import qs from 'query-string'

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.

// #SEE ALSO App.js for locale!!
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const calendarEventMapStateToProps = state => ({})
const CalendarEvent = connect(
  calendarEventMapStateToProps,
  { setCurrentIrrigation }
)(
  localize()(
    class extends React.PureComponent {
      render() {
        const { event } = this.props
        return (
          <div className="d-flex justify-content-between">
            <div className="float-left h-100 d-flex align-items-center">
              <small>
                <i
                  onClick={() => this.props.setCurrentIrrigation(event.data)}
                  className="fa fa-edit "
                ></i>
              </small>
            </div>
            <small>
              {event.data ? get(event.data, 'name') : `id: ${event.targetId}`} -{' '}
              {event.data.use_duration
                ? `${event.data.duration} min`
                : `${event.data.amount} mm`}
            </small>
          </div>
        )
      }
    }
  )
)

const dateHeaderMapStateToProps = state => ({
  currentIrrigation: getCurrentIrrigation(state),
  estate: getEstate(state),
})

const MonthDateHeader = connect(
  dateHeaderMapStateToProps,
  { setCurrentIrrigation }
)(
  localize()(
    class extends React.PureComponent {
      state = {
        tooltipOpen: false,
      }

      toggleTooltip = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen })
      }

      render() {
        return (
          <span>
            <span>{this.props.label}</span>{' '}
            <i
              id={`date-header-${this.props.label}`}
              onClick={() =>
                this.props.setCurrentIrrigation({
                  id_estate: this.props.estate.id,
                  type: 'irrigation',
                  date: this.props.date,
                  use_duration: false,
                  amount: 0,
                  duration: 0,
                  id_field: 0,
                  id_irrigation_sector: 0,
                  id: 0,
                  ...this.props.fixedRecordProps,
                })
              }
              className="fa fa-plus-circle"
            ></i>
            <Tooltip
              placement="bottom"
              isOpen={this.state.tooltipOpen}
              target={`date-header-${this.props.label}`}
              toggle={this.toggleTooltip}
            >
              {this.props.t('addIrrigationEvent')}
            </Tooltip>
          </span>
        )
      }
    }
  )
)
MonthDateHeader.defaultProps = {
  fixedRecordProps: {},
}

const MonthWeekHeader = connect(
  dateHeaderMapStateToProps,
  { setCurrentIrrigation }
)(
  localize()(
    class extends React.PureComponent {
      state = {
        tooltipOpen: false,
      }

      toggleTooltip = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen })
      }

      render() {
        const k = moment(this.props.date).unix()
        return (
          <span>
            <span>{this.props.label}</span>{' '}
            <i
              id={`date-header-${k}`}
              onClick={() =>
                this.props.setCurrentIrrigation({
                  id_estate: this.props.estate.id,
                  type: 'irrigation',
                  date: this.props.date,
                  use_duration: false,
                  amount: 0,
                  duration: 0,
                  id_field: 0,
                  id_irrigation_sector: 0,
                  id: 0,
                  ...this.props.fixedRecordProps,
                })
              }
              className="fa fa-plus-circle"
            ></i>
            <Tooltip
              placement="bottom"
              isOpen={this.state.tooltipOpen}
              target={`date-header-${k}`}
              toggle={this.toggleTooltip}
            >
              {this.props.t('addIrrigationEvent')}
            </Tooltip>
          </span>
        )
      }
    }
  )
)
MonthWeekHeader.defaultProps = {
  fixedRecordProps: {},
}

const irrigationCalendarMapStateToProps = state => ({
  estate: getEstate(state),
  currentIrrigation: getCurrentIrrigation(state),
  createIrrigation: makeCreateIrrigation(state),
  updateIrrigation: makeUpdateIrrigation(state),
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
})
const IrrigationCalendar = connect(
  irrigationCalendarMapStateToProps,
  {
    unsetCurrentIrrigation,
    setCurrentIrrigation,
    irrigationCreated,
    irrigationUpdated,
    deleteEstateIrrigationEvent,
  }
)(
  localize()(
    class extends React.PureComponent {
      suggestionLoaded = false

      render() {
        const {
          currentIrrigation,
          dayMonthYearMomentUserFormat,
          events,
          t,
        } = this.props

        const eventStyles = {
          eventSector: {
            backgroundColor: '#1C7DDB',
            color: 'white',
            borderRadius: 0,
          },
          eventField: {
            backgroundColor: 'purple',
            color: 'white',
            borderRadius: 0,
          },
        }

        const urlParams = qs.parse(window.location.search)
        if (urlParams.suggested_date && !this.suggestionLoaded) {
          this.props.setCurrentIrrigation({
            id_estate: this.props.estate.id,
            type: 'irrigation',
            date: urlParams.suggested_date,
            use_duration: true,
            amount: 0,
            duration: urlParams.duration || 0,
            id_field: urlParams.id_field || 0,
            id_irrigation_sector: urlParams.id_irrigation_sector || 0,
            applied_to: urlParams.applied_to,
            id: 0,
            fixTarget: true,
            // ...this.props.fixedRecordProps,
          })
          this.suggestionLoaded = true
        }

        return (
          <div style={{ height: 600 }}>
            <Calendar
              localizer={localizer}
              events={events}
              eventPropGetter={(event, start, end, isSelected) => {
                return {
                  style:
                    event.data.applied_to === 'field'
                      ? eventStyles.eventField
                      : eventStyles.eventSector,
                }
              }}
              popup={true}
              startAccessor="start"
              endAccessor="end"
              views={['month', 'week', 'agenda']}
              drilldownView={null}
              length={60}
              defaultDate={new Date()}
              components={{
                event: CalendarEvent,
                month: {
                  dateHeader: connect(state => ({
                    fixedRecordProps: this.props.fixedRecordProps,
                  }))(MonthDateHeader),
                },
                week: {
                  header: connect(state => ({
                    fixedRecordProps: this.props.fixedRecordProps,
                  }))(MonthWeekHeader),
                },
              }}
              formats={{
                timeGutterFormat: 'HH:mm',
                timeRangeFormat: 'HH:mm',
                agendaDateFormat: dayMonthYearMomentUserFormat,
                agendaHeaderFormat: ({ start, end }) => {
                  return `${moment(start).format(
                    dayMonthYearMomentUserFormat
                  )} - ${moment(end).format(dayMonthYearMomentUserFormat)}`
                },
                // eventTimeRangeFormat:'HH:mm',
              }}
              messages={{
                week: t('calendarWeek'),
                month: t('calendarMonth'),
                year: t('calendarYear'),
                agenda: t('calendarAgenda'),
                today: t('calendarToday'),
                next: t('calendarNext'),
                previous: t('calendarPrevious'),
              }}
              showMultiDayTimes
              onRangeChange={x =>
                this.props.onRangeChange && this.props.onRangeChange(x)
              }
            />
            {currentIrrigation && (
              <IrrigationModal
                updateEvents={this.props.updateEvents}
                irrigationCreated={this.props.irrigationCreated}
                irrigationUpdated={this.props.irrigationUpdated}
                deleteEstateIrrigationEvent={
                  this.props.deleteEstateIrrigationEvent
                }
                createIrrigation={this.props.createIrrigation}
                updateIrrigation={this.props.updateIrrigation}
                record={currentIrrigation}
                closeModal={this.props.unsetCurrentIrrigation}
              />
            )}
          </div>
        )
      }
    }
  )
)

IrrigationCalendar.defaultProps = {
  fixedRecordProps: {},
}

export default IrrigationCalendar
