import React from 'react'
import { localize } from '../localize'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import { Input, UncontrolledTooltip } from 'reactstrap'
import { getDayMonthYearMomentUserFormat } from '../state/languages'

class CustomDateInput extends React.Component {
  render() {
    return (
      <div className="datepicker-custom-input">
        <Input
          bsSize="sm"
          value={this.props.value}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
        />
      </div>
    )
  }
}

class DatesSelector extends React.PureComponent {
  render() {
    const {
      fromDate,
      toDate,
      minDate,
      maxDate,
      loading,
      flexDirection = 'flex-direction-column',
      handleChangeFromDate,
      handleChangeToDate,
      title,
      right,
      rightTooltip,
      rightTooltipTarget,
      rightTooltipPlacement,
      t,
    } = this.props

    return (
      <div style={{ flex: 1 }}>
        {title && (
          <div>
            <h6>{title}</h6>
          </div>
        )}
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div>
              <label className="col-form-label m-2">{t('from')}</label>
              <DatePicker
                customInput={<CustomDateInput />}
                selected={fromDate}
                selectsStart
                startDate={fromDate}
                minDate={minDate}
                maxDate={maxDate}
                endDate={toDate}
                onChange={handleChangeFromDate}
                dateFormat={this.props.dayMonthYearMomentUserFormat}
                todayButton={'Today'}
              />
            </div>

            <label className="col-form-label m-2">{t('to')}</label>
            <DatePicker
              selected={toDate}
              customInput={<CustomDateInput />}
              selectsEnd
              startDate={fromDate}
              minDate={fromDate}
              maxDate={maxDate}
              endDate={toDate}
              onChange={handleChangeToDate}
              dateFormat={this.props.dayMonthYearMomentUserFormat}
              todayButton={'Today'}
            />
            {loading && (
              <span>
                <i className="ml-2 fa fa-spin fa-refresh text-primary"></i>
              </span>
            )}
            {!loading && (
              <span>
                <i className="ml-2 fa fa-refresh text-transparent"></i>
              </span>
            )}
          </div>
          <div className="col-md-6">
            <div className="text-muted">
              {maxDate && minDate && (
                <small>
                  <b>
                    {t('dataAvailableFrom')}{' '}
                    {minDate.format(this.props.dayMonthYearMomentUserFormat)}{' '}
                    {t('to')}{' '}
                    {maxDate.format(this.props.dayMonthYearMomentUserFormat)}
                  </b>
                </small>
              )}
            </div>
            {right && (
              <div id={rightTooltipTarget}>
                {right}
                {rightTooltip && rightTooltipTarget && (
                  <UncontrolledTooltip
                    placement={rightTooltipPlacement}
                    target={rightTooltipTarget}
                  >
                    {rightTooltip}
                  </UncontrolledTooltip>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default localize()(
  connect(state => ({
    dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
  }))(DatesSelector)
)
