import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { getStatusClass } from '../utils'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import find from 'lodash/find'
import { processGeoItems, fixCoordsPrecision } from '../utils'
import { setMapBounds, setMapObject } from '../state/ui'
import { geometryCollection } from '@turf/helpers'
import center from '@turf/center'
import { getEstate } from '../state/estates'
import { Link, withRouter } from 'react-router-dom'
import { localize } from '../localize'
import qs from 'query-string'
import SuggestedIrrigationLink from './SuggestedIrrigationLink'
import IrrigationPreview, {
  IrrigationPreviewLink,
} from '../components/IrrigationPreview'

const formatMin = n =>
  n.toLocaleString('en', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 0,
    useGrouping: false,
  })
const maybeFormatFloat = (x, precision = 2) =>
  x !== undefined && x !== null ? x.toFixed(precision) : x
const maybeFormatMinutes = x =>
  x !== undefined && x !== null ? `${parseInt(x / 60)}:${formatMin(x % 60)}` : x

const ThWithOrder = ({ children, orderBy, setter, className, orderKey }) => {
  const isNeg = orderBy && orderBy[0] === '-'
  const currentOrderKey = orderBy ? (isNeg ? orderBy.slice(1) : orderBy) : null

  const faClass =
    orderBy && currentOrderKey === orderKey
      ? isNeg
        ? 'fa-caret-down'
        : 'fa-caret-up'
      : 'fa-sort'

  return (
    <th className={`pointer ${className || ''}`} onClick={setter}>
      {children}
      <span>
        {' '}
        <i className={`fa ${faClass}`}></i>
      </span>
    </th>
  )
}

const FieldsTable = ({
  fieldsStatusOrdered,
  orderBy,
  estate,
  t,
  zoomTo,
  setOrderBy,
}) => {
  const [irrigationPreviewParams, setIrrigationPreviewParams] = useState(null)

  return (
    <div className="my-4">
      {fieldsStatusOrdered && fieldsStatusOrdered.length > 0 && (
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Card>
              <CardHeader>
                <h5>{t('fields_overview')}</h5>
              </CardHeader>
              <CardBody>
                <Table size="sm" responsive striped>
                  <thead className="thead-white">
                    <tr>
                      <ThWithOrder
                        orderBy={orderBy}
                        orderKey={'field_name'}
                        setter={() => {
                          setOrderBy('field_name')
                        }}
                      >
                        {t('field_name')}
                      </ThWithOrder>
                      <ThWithOrder
                        orderKey={'pedoclimatic_unit_name'}
                        className="text-center"
                        orderBy={orderBy}
                        setter={() => {
                          setOrderBy('pedoclimatic_unit_name')
                        }}
                      >
                        {t('pedoclimaticUnit')}
                      </ThWithOrder>
                      <ThWithOrder
                        orderKey={
                          'water_deficit_status.soil_water.attainable_water'
                        }
                        orderBy={orderBy}
                        setter={() => {
                          setOrderBy(
                            'water_deficit_status.soil_water.attainable_water'
                          )
                        }}
                      >
                        {t('usefulWater')}
                      </ThWithOrder>

                      <th className="text-center" colSpan="2">
                        {t('irrigation')}
                      </th>
                      <th className="text-center">{t('numHoursDeficit')}</th>
                      <th className="text-center">Info</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center">hh:mm</th>
                      <th className="text-center">
                        m<sup>3</sup>
                      </th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fieldsStatusOrdered.map(row => (
                      <tr key={row.id_field}>
                        <td>
                          <i
                            className="fa fa-globe pointer"
                            onClick={() => zoomTo(row.id_field)}
                          ></i>{' '}
                          <Link
                            to={`/estates/${estate.id}/fields/${row.id_field}`}
                          >
                            {row.field_name}
                          </Link>
                        </td>
                        <td className="text-center">
                          {row.pedoclimatic_unit_name}
                        </td>
                        <td className="text-center">
                          {maybeFormatFloat(
                            get(
                              row.water_deficit_status,
                              'soil_water.attainable_water'
                            )
                          )}
                        </td>
                        <td className="text-center">
                          {maybeFormatMinutes(
                            get(
                              row.water_deficit_status,
                              'suggested_irrigation.duration_min'
                            )
                          ) || '-'}
                        </td>
                        <td className="text-center">
                          {get(
                            row.water_deficit_status,
                            'suggested_irrigation.quantity_m3'
                          ) || '-'}
                        </td>
                        <td className="text-center">
                          {get(row.water_deficit_status, 'hours_in_deficit') ||
                            '-'}
                        </td>
                        <td
                          style={{
                            backgroundColor: get(
                              row.water_deficit_status,
                              'soil_water.status_color'
                            ),
                          }}
                        >
                          <div className="text-center">
                            <span className="badge">
                              {get(
                                row.water_deficit_status,
                                'soil_water.status_text'
                              )}
                            </span>
                          </div>
                          <div>{get(row.notification, 'message')}</div>
                          <SuggestedIrrigationLink
                            linkClassName="text-white"
                            estate={estate}
                            data={{ ...row }}
                          ></SuggestedIrrigationLink>
                          <IrrigationPreviewLink
                            setter={setIrrigationPreviewParams}
                            data={{ ...row }}
                          ></IrrigationPreviewLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <IrrigationPreview
        irrigationPreviewParams={irrigationPreviewParams}
        cancel={() => setIrrigationPreviewParams(null)}
      ></IrrigationPreview>
    </div>
  )
}

const SectorsTable = ({
  fieldsStatusOrdered,
  orderBy,
  estate,
  t,
  zoomTo,
  setOrderBy,
}) => {
  const [irrigationPreviewParams, setIrrigationPreviewParams] = useState(null)

  return (
    <div className="my-4">
      {fieldsStatusOrdered && fieldsStatusOrdered.length > 0 && (
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Card>
              <CardHeader>
                <h5>{t('sectors_overview')}</h5>
              </CardHeader>
              <CardBody>
                <Table size="sm" responsive striped>
                  <thead className="thead-white">
                    <tr>
                      <ThWithOrder
                        orderBy={orderBy}
                        orderKey={'name'}
                        setter={() => {
                          setOrderBy('name')
                        }}
                      >
                        {t('sector')}
                      </ThWithOrder>
                      <ThWithOrder
                        orderKey={'management_policy'}
                        className="text-center"
                        orderBy={orderBy}
                        setter={() => {
                          setOrderBy('management_policy')
                        }}
                      >
                        {t('management_policy')}
                      </ThWithOrder>
                      <ThWithOrder
                        orderKey={'managed_field_name'}
                        className="text-center"
                        orderBy={orderBy}
                        setter={() => {
                          setOrderBy('managed_field_name')
                        }}
                      >
                        {t('managed_field')}
                      </ThWithOrder>

                      <ThWithOrder
                        orderKey={
                          'water_deficit_status.soil_water.attainable_water'
                        }
                        orderBy={orderBy}
                        setter={() => {
                          setOrderBy(
                            'water_deficit_status.soil_water.attainable_water'
                          )
                        }}
                      >
                        {t('usefulWater')}
                      </ThWithOrder>

                      <th className="text-center" colSpan="2">
                        {t('irrigation')}
                      </th>
                      <th className="text-center">{t('numHoursDeficit')}</th>
                      <th className="text-center">Info</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>

                      <th className="" colSpan="2">
                        <div className="d-flex">
                          <div className="text-center w-50">HH:mm</div>
                          <div className="text-center w-50">
                            m<sup>3</sup>
                          </div>
                        </div>
                      </th>
                      <th className="text-center"></th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fieldsStatusOrdered.map(row => (
                      <tr key={row.id}>
                        <td>
                          <i
                            className="fa fa-globe pointer"
                            onClick={() => zoomTo(row.id)}
                          ></i>{' '}
                          <Link to={`/estates/${estate.id}/sectors/${row.id}`}>
                            {row.name}
                          </Link>
                        </td>
                        <td className="text-center">{row.management_policy}</td>
                        <td className="text-center">
                          {row.id_managed_field && (
                            <Link
                              to={`/estates/${estate.id}/fields/${row.id_managed_field}`}
                            >
                              {row.managed_field_name}
                            </Link>
                          )}
                        </td>
                        <td className="text-center">
                          {maybeFormatFloat(
                            get(
                              row.water_deficit_status,
                              'soil_water.attainable_water'
                            )
                          )}
                        </td>
                        <td className="text-center">
                          {maybeFormatMinutes(
                            get(
                              row.water_deficit_status,
                              'suggested_irrigation.duration_min'
                            )
                          ) || '-'}
                        </td>
                        <td className="text-center">
                          {get(
                            row.water_deficit_status,
                            'suggested_irrigation.quantity_m3'
                          ) || '-'}
                        </td>
                        <td className="text-center">
                          {get(row.water_deficit_status, 'hours_in_deficit') ||
                            '-'}
                        </td>
                        <td
                          style={{
                            backgroundColor: get(
                              row.water_deficit_status,
                              'soil_water.status_color'
                            ),
                          }}
                        >
                          <div className="text-center">
                            <span className="badge">
                              {get(
                                row.water_deficit_status,
                                'soil_water.status_text'
                              )}
                            </span>
                          </div>
                          <div>{get(row.notification, 'message')}</div>
                          <SuggestedIrrigationLink
                            linkClassName="text-white"
                            estate={estate}
                            data={row}
                          ></SuggestedIrrigationLink>
                          <IrrigationPreviewLink
                            setter={setIrrigationPreviewParams}
                            data={{ ...row, id_irrigation_sector: row.id }}
                          ></IrrigationPreviewLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <IrrigationPreview
        irrigationPreviewParams={irrigationPreviewParams}
        cancel={() => setIrrigationPreviewParams(null)}
      ></IrrigationPreview>
    </div>
  )
}

class EstateFieldsWithNotifications extends React.PureComponent {
  zoomToField = fieldId => {
    const { fields } = this.props
    const processedFields = processGeoItems(fields)
    const processedFieldsById = keyBy(processedFields, 'id')
    const processedField = processedFieldsById[fieldId]
    if (processedField) {
      this.props.setMapBounds({
        center: fixCoordsPrecision(processedField.center.reverse()),
        zoom: 15,
      })
      this.props.setMapObject({
        type: 'field',
        id: processedField.id,
        item: processedField,
      })
    }
  }

  zoomToSector = sectorId => {
    const { fields, sectors } = this.props
    const sector = find(sectors, x => x.id === sectorId)
    if (!sector) {
      return
    }
    const processedFields = processGeoItems(fields).filter(
      field => (field.id_irrigation_sector = sectorId)
    )
    const fieldsCollection = geometryCollection(
      processedFields.map(f => f.geomJson)
    )
    const collectionCenter = processedFields.length
      ? center(fieldsCollection)
      : null

    if (collectionCenter) {
      this.props.setMapBounds({
        center: collectionCenter.geometry.coordinates.reverse(),
        zoom: 15,
      })
    }
    this.props.setMapObject({ type: 'sector', id: sectorId, item: sector })
  }

  zoomToSensorNode = sensorNodeId => {
    const { estate } = this.props
    const sensorNodes = get(estate, 'hardware.sensor_nodes', [])
    const sensorNode = find(sensorNodes, item => item.id === sensorNodeId)
    if (sensorNode) {
      this.props.setMapBounds({
        center: [sensorNode.latitude, sensorNode.longitude],
        zoom: 15,
      })
      this.props.setMapObject({
        type: 'sensor',
        id: sensorNode.id,
        item: sensorNode,
      })
    }
  }

  setOrderBy = fieldName => {
    const { location } = this.props
    const params = qs.parse(location.search)
    const oldOrderBy = get(params, 'orderBy')
    const negatedFieldName = `-${fieldName}`
    let newOrderBy = fieldName
    if (fieldName === oldOrderBy) {
      newOrderBy = negatedFieldName
    }
    if (negatedFieldName === oldOrderBy) {
      newOrderBy = undefined
    }

    const newParams = {
      ...params,
      orderBy: newOrderBy,
    }
    this.props.history.push({
      pathname: location.pathname,
      search: qs.stringify(newParams),
    })
  }

  zoomTo = id => {
    const { subject } = this.props
    if (subject === 'sectors') {
      return this.zoomToSector(id)
    }
    return this.zoomToField(id)
  }

  render() {
    const { subject, estate, fields, fieldsStatus, t, location } = this.props
    const params = qs.parse(location.search)
    let orderBy = get(params, 'orderBy')

    let fieldsStatusOrdered
    if (orderBy) {
      if (orderBy[0] === '-') {
        fieldsStatusOrdered = sortBy(fieldsStatus || [], orderBy.slice(1))
        fieldsStatusOrdered = fieldsStatusOrdered.reverse()
      } else {
        fieldsStatusOrdered = sortBy(fieldsStatus || [], orderBy)
      }
    } else {
      // fieldsStatusOrdered = sortBy(
      //   fieldsStatus || [],
      //   row =>
      //     get(row.water_deficit_status, 'soil_water.attainable_water', 0) -
      //     (get(row.water_deficit_status, 'status', 'OK') === 'OK' ? 0 : 1000)
      // )
      fieldsStatusOrdered = fieldsStatus ? [...fieldsStatus] : []
    }

    return subject === 'fields' ? (
      <FieldsTable
        t={t}
        fieldsStatusOrdered={fieldsStatusOrdered}
        orderBy={orderBy}
        estate={estate}
        zoomTo={this.zoomTo}
        setOrderBy={this.setOrderBy}
      ></FieldsTable>
    ) : (
      <SectorsTable
        t={t}
        fieldsStatusOrdered={fieldsStatusOrdered}
        orderBy={orderBy}
        estate={estate}
        zoomTo={this.zoomTo}
        setOrderBy={this.setOrderBy}
      ></SectorsTable>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      estate: getEstate(state),
    }),
    { setMapBounds, setMapObject }
  )(localize()(EstateFieldsWithNotifications))
)
