import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import { rj } from 'redux-rocketjump'
import { rjDict } from './utils'

// Namespace for actions
const NS = '@providers/'

// Dict
const GET_PROVIDERS_DATA_DICT = `${NS}GET_PROVIDERS_DATA_DICT`
export const {
  actions: { load: loadProvidersDataDict, unload: unloadProvidersDataDict },
  selectors: { makeGetData: makeGetProviderData },
  reducer: providersDataDictReducer,
  saga: providersDataDictSaga,
} = rj(rjDict(), {
  type: GET_PROVIDERS_DATA_DICT,
  state: 'providers.dataDict',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}data-providers/sensors/${id}/data`))
      .query(params)
      .then(({ body }) => ({ ...body, id })),
})()

// Dict
const GET_PROVIDERS_DICT = `${NS}GET_PROVIDERS_DICT`
export const {
  actions: { load: loadProvidersDict, unload: unloadProvidersDict },
  selectors: {
    makeGetData: makeGetProvider,
    makeGetLoading: makeGetProviderLoading,
  },
  reducer: providersDictReducer,
  saga: providersDictSaga,
} = rj(rjDict(), {
  type: GET_PROVIDERS_DICT,
  state: 'providers.dict',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}data-providers/sensors/${id}/data`))
      .query(params)
      .then(({ body }) => ({ ...body, id })),
})()

export const reducer = combineReducers({
  dict: providersDictReducer,
  dataDict: providersDataDictReducer,
})

export const saga = function*() {
  yield fork(providersDataDictSaga)
  yield fork(providersDictSaga)
}
