import React, { PureComponent } from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { get, keyBy } from 'lodash'
import Layout from '../components/Layout'
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  Row,
  Col,
  Nav,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
} from 'reactstrap'
import { getEstate, getEstateFields } from '../state/estates'
import {
  getField,
  getFieldDataLoading,
  loadField,
  unloadField,
  loadSimulationsDict,
  unloadSimulationsDict,
  loadFieldData,
  unloadFieldData,
  loadFieldSoilProviders,
  unloadFieldSoilProviders,
  getFieldSoilProviders,
  loadFieldIrrigations,
  unloadFieldIrrigations,
  getFieldIrrigations,
} from '../state/fields'
import { getEstateSectors } from '../state/estates'
import { setMapObject, unsetMapObject, setMapBounds } from '../state/ui'
import FieldDataChart from '../components/FieldDataChart'
import PhenologyChart from '../components/PhenologyChart'
import { FieldsMapLeaflet } from '../components/FieldsMap'
import { getStatusClass } from '../utils'
import { ParentSize } from '@vx/responsive'
import { SimpleDataTable } from '../components/Helpers'
import {
  getDayMonthYearMomentUserFormat,
  getNumberFormat,
} from '../state/languages'
import IrrigationCalendar from '../components/EstateIrrigation/IrrigationCalendar'

class PhenologyChartRow extends React.PureComponent {
  render() {
    const { data, field, title, additionalData, showTable = true } = this.props
    const phenology_chart_values = get(data, 'phenology_chart_values', [])
    return (
      <Row className="border-bottom">
        <Col md={6} sm={12} xs={12}>
          <div className="p-3">
            <h4>{title}</h4>
            {showTable &&
              phenology_chart_values &&
              phenology_chart_values.length > 0 && (
                <div className="list-group mb-3">
                  <div className="list-group-item bg-light p-2">
                    <div className="d-flex flex-row">
                      <div style={{ flex: 1 }}>Day</div>
                      <div style={{ flex: 1 }}>Value</div>
                    </div>
                  </div>
                  {phenology_chart_values.map(v => (
                    <div key={v.doy} className="list-group-item p-2">
                      <div className="d-flex flex-row">
                        <div style={{ flex: 1 }}>{v.doy}</div>
                        <div style={{ flex: 1 }}>{v.value}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            {additionalData && (
              <div className="">
                <SimpleDataTable
                  columnsClass="col-sm-6"
                  columns={additionalData}
                />
              </div>
            )}
          </div>
        </Col>
        <Col md={6} sm={12} xs={12} className="text-center">
          <div className="p-2">
            <ParentSize>
              {({ width: w, height: h }) => {
                return (
                  <PhenologyChart
                    xLabel={'days'}
                    yLabel={'Kc'}
                    data={data}
                    width={w}
                    height={400}
                    chartLabel={`kc-diagram-${field.name}.${field.estate_crop_name}.png`}
                  />
                )
              }}
            </ParentSize>
          </div>
        </Col>
      </Row>
    )
  }
}

class EstateField extends PureComponent {
  state = { currentTab: 'status' }

  selectTab = currentTab => {
    this.setState({ currentTab })
  }

  componentDidMount() {
    const { match } = this.props
    this.props.loadField(match.params.fieldId, { includeStatus: true })
    this.props.loadFieldSoilProviders(match.params.fieldId)
    this.props.loadFieldIrrigations({
      idEstate: match.params.estateId,
      idField: match.params.fieldId,
    })
  }

  componentWillUnmount() {
    this.props.unloadField()
    this.props.unloadFieldSoilProviders()
    this.props.unloadFieldIrrigations()
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if (match.params.fieldId !== nextProps.match.params.fieldId) {
      this.props.unloadField()
      this.props.unloadSimulationsDict()
      this.props.unloadFieldSoilProviders()
      this.props.loadField(nextProps.match.params.fieldId, {
        includeStatus: true,
      })
      this.props.loadSimulationsDict(nextProps.match.params.fieldId)
      this.props.loadFieldSoilProviders(nextProps.match.params.fieldId)
    }

    if (nextProps.field && nextProps.field !== this.props.field) {
      const { field } = nextProps
      this.props.setMapObject({ type: 'field', id: field.id, item: field })
    }
  }

  onRangeChange = range => {
    const fromDate = Array.isArray(range) ? range[0] : range.start
    const toDate = Array.isArray(range) ? range[range.length - 1] : range.end
    const { match } = this.props
    const params = {
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).format('YYYY-MM-DD'),
    }
    this.props.loadFieldIrrigations({
      idEstate: match.params.estateId,
      idField: match.params.fieldId,
      params,
    })
  }

  getSuggestedIrrigation = (data, subFieldName) => {
    const subField = get(data, subFieldName)
    const suggestedIrrigation = get(subField, 'suggested_irrigation')
    if (!suggestedIrrigation) {
      return undefined
    }
    if (!suggestedIrrigation.duration_min && !suggestedIrrigation.quantity_m3) {
      return undefined
    }
    return suggestedIrrigation
  }

  render() {
    const {
      estate,
      estateFields,
      field,
      sectors,
      t,
      numberFormat,
      irrigations,
    } = this.props
    const { currentTab } = this.state

    if (!estate || !field) {
      return null
    }
    const fieldCropData = get(field, 'crop')
    const kcData = get(fieldCropData, 'kc')
    const kcbData = get(fieldCropData, 'kcb')
    const kcbMaxData = get(fieldCropData, 'kcb_max')
    const fao56Data = get(fieldCropData, 'fao_56')

    const sectorsById = keyBy(sectors, 'id')
    const currentObjectSectorId = get(field, 'id_irrigation_sector')
    const currentSector = get(sectorsById, currentObjectSectorId)

    const deficitStatus = get(field, 'water_deficit_status.status')
    const soilWater = get(field, 'water_deficit_status.soil_water')
    const deficitStatus24 = get(field, 'water_deficit_status_24.status')
    const soilWater24 = get(field, 'water_deficit_status_24.soil_water')
    const deficitStatus48 = get(field, 'water_deficit_status_48.status')
    const soilWater48 = get(field, 'water_deficit_status_48.soil_water')
    const suggestedIrrigation = this.getSuggestedIrrigation(
      field,
      'water_deficit_status'
    )

    const alertClass =
      deficitStatus === 'Irri_Warning'
        ? 'alert-warning'
        : deficitStatus === 'Irri_Critical'
        ? 'alert-danger'
        : 'alert-info'
    const anyDeficitStatus =
      deficitStatus !== undefined ||
      deficitStatus24 !== undefined ||
      deficitStatus48 !== undefined

    // get sensor nodes
    // const { hardware } = field
    // const { sensor_nodes } = hardware

    const events = get(irrigations, 'events', []).map(irrigation => ({
      ...irrigation,
      target: field,
    }))

    return (
      <Layout>
        <Layout.Content className="container-fluid h-100">
          {/* { loading && <div className="text-center py-5 my-5">
            <Spinner className="text-muted"/>
          </div>} */}

          {estate && field && (
            <div>
              <div className="mt-3">
                <h5>
                  <Link to={`/estates`} className="text-muted">
                    {t('estates')}
                  </Link>
                  {' / '}
                  <Link to={`/estates/${estate.id}`} className="text-muted">
                    {estate.name}
                  </Link>
                  {' / '}
                  {field.name}
                </h5>
              </div>
              {currentSector && (
                <div className="my-2">
                  <Link
                    to={`/estates/${estate.id}/sectors/${currentSector.id}`}
                  >
                    {t('irrigationSector')} {currentSector.name}
                  </Link>
                </div>
              )}

              <div className="mt-3 mb-3">
                <Nav tabs>
                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({
                        active: currentTab === 'status',
                      })}
                      onClick={() => {
                        this.selectTab('status')
                      }}
                    >
                      <i className="fa fa-tint"></i> {t('status')}
                    </NavLink>
                  </NavItem>

                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({
                        active: currentTab === 'configuration',
                      })}
                      onClick={() => {
                        this.selectTab('configuration')
                      }}
                    >
                      <i className="fa fa-sliders"></i> {t('configuration')}
                    </NavLink>
                  </NavItem>

                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({
                        active: currentTab === 'irrigations',
                      })}
                      onClick={() => {
                        this.selectTab('irrigations')
                      }}
                    >
                      <i className="fa fa-sliders"></i> {t('irrigations')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent activeTab={currentTab}>
                {currentTab === 'status' && (
                  <TabPane tabId="status">
                    <div className="row">
                      <div className="col-md-8">
                        <Card className="mb-2">
                          <CardHeader className={`${alertClass}`}>
                            {t('status')} <b>{deficitStatus || ''}</b>
                          </CardHeader>
                          <CardBody style={{ minHeight: 196 }}>
                            {!anyDeficitStatus && (
                              <div>{'No deficit data for this sector.'}</div>
                            )}
                            {deficitStatus && (
                              <div>
                                {t('deficitStatus')}:{' '}
                                <span
                                  className={`badge ${getStatusClass(
                                    deficitStatus,
                                    'badge-'
                                  )}`}
                                >
                                  {deficitStatus}
                                </span>
                              </div>
                            )}
                            {soilWater && (
                              <div className="mb-1">
                                <small>
                                  {t('waterDeficit')}:{' '}
                                  {numberFormat(soilWater.water_deficit)} mm.
                                  <br />
                                  {t('soilWaterContent')}:{' '}
                                  {numberFormat(soilWater.available_water)} m
                                  <sup>3</sup>/m<sup>3</sup>
                                </small>
                              </div>
                            )}
                            {deficitStatus24 && (
                              <div className="mt-1">
                                {t('deficitStatus')} (24h):{' '}
                                <span
                                  className={`badge ${getStatusClass(
                                    deficitStatus24,
                                    'badge-'
                                  )}`}
                                >
                                  {deficitStatus24}
                                </span>
                              </div>
                            )}
                            {soilWater24 && (
                              <div className="mb-1">
                                <small>
                                  {t('waterDeficit')}:{' '}
                                  {numberFormat(soilWater24.water_deficit)} mm.
                                  <br />
                                  {t('soilWaterContent')}:{' '}
                                  {numberFormat(soilWater24.available_water)} m
                                  <sup>3</sup>/m<sup>3</sup>
                                </small>
                              </div>
                            )}
                            {deficitStatus48 && (
                              <div className="mt-1">
                                {t('deficitStatus')} (48h):{' '}
                                <span
                                  className={`badge ${getStatusClass(
                                    deficitStatus48,
                                    'badge-'
                                  )}`}
                                >
                                  {deficitStatus48}
                                </span>
                              </div>
                            )}
                            {soilWater48 && (
                              <div className="mb-1">
                                <small>
                                  {t('waterDeficit')}:{' '}
                                  {numberFormat(soilWater48.water_deficit)} mm.
                                  <br />
                                  {t('soilWaterContent')}:{' '}
                                  {numberFormat(soilWater48.available_water)} m
                                  <sup>3</sup>/m<sup>3</sup>
                                </small>
                              </div>
                            )}
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader className={`alert ${alertClass}`}>
                            {t('nextIrrigations')}
                          </CardHeader>
                          <CardBody>
                            {!suggestedIrrigation && (
                              <div>
                                {t('noSuggestedIrrigationForThisSector')}
                              </div>
                            )}
                            {suggestedIrrigation && (
                              <CardText>
                                {t('anIrrigationOf')}{' '}
                                <b>
                                  {numberFormat(
                                    suggestedIrrigation.quantity_m3
                                  )}{' '}
                                  m<sup>3</sup>
                                </b>{' '}
                                {t('for')}{' '}
                                <b>
                                  {suggestedIrrigation.duration_min}{' '}
                                  {t('minutes')}
                                </b>{' '}
                                {t('isExpectedOn')}{' '}
                                <b>
                                  {moment(suggestedIrrigation.date).format(
                                    this.props.dayMonthYearMomentUserFormat
                                  )}
                                </b>{' '}
                                {t('at')}{' '}
                                <b>
                                  {moment(suggestedIrrigation.date).format(
                                    'HH:mm'
                                  )}
                                </b>
                              </CardText>
                            )}
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-md-4" style={{ minHeight: 300 }}>
                        {/* <EstateItemMiniMap  estate={estate} className="w-100 h-100" height='100%'/> */}
                        <FieldsMapLeaflet
                          estate={estate}
                          fields={estateFields}
                          sectorsById={sectorsById}
                          height="100%"
                          mapPanel="fields"
                          allowSetObject={false}
                        />
                      </div>
                    </div>

                    <FieldDataChart />
                  </TabPane>
                )}

                {currentTab === 'configuration' && (
                  <TabPane tabId="configuration">
                    <div className="row">
                      <div className="col-md-8">
                        <Card className="mb-2">
                          <CardHeader>{t('description')}</CardHeader>
                          <CardBody style={{ minHeight: 120 }}>
                            {field.description || <span>&nbsp;</span>}
                          </CardBody>
                        </Card>
                        <Card>
                          <CardHeader>{t('cropInformation')}</CardHeader>
                          <SimpleDataTable
                            columnsClass="col-sm-6"
                            columns={[
                              [
                                { label: t('name'), value: fieldCropData.name },
                                {
                                  label: t('cropType'),
                                  value: fieldCropData.crop_type,
                                },
                                {
                                  label: t('arboreousCultivationMethod'),
                                  value:
                                    fieldCropData.arboreous_cultivation_method,
                                },
                                {
                                  label: t('rootsEfficiency'),
                                  value: numberFormat(
                                    fieldCropData.roots_efficiency
                                  ),
                                },
                                {
                                  label: t('rootsMinDepth'),
                                  value: numberFormat(
                                    fieldCropData.roots_min_depth
                                  ),
                                },
                                {
                                  label: t('rootsMaxDepth'),
                                  value: numberFormat(
                                    fieldCropData.roots_max_depth
                                  ),
                                },
                                {
                                  label: t('criticalHumidityLevel'),
                                  value: numberFormat(
                                    fieldCropData.critical_humidity_level
                                  ),
                                },
                              ],
                              [
                                {
                                  label: t('spaceBetweenLines'),
                                  value: numberFormat(
                                    fieldCropData.space_between_lines
                                  ),
                                },
                                {
                                  label: t('spaceBetweenPlants'),
                                  value: numberFormat(
                                    fieldCropData.space_between_plants
                                  ),
                                },
                                {
                                  label: t('foliageDiameter'),
                                  value: numberFormat(
                                    fieldCropData.foliage_diameter
                                  ),
                                },
                                {
                                  label: t('cardinalMinTemperature'),
                                  value: numberFormat(
                                    fieldCropData.cardinal_min_temperature
                                  ),
                                },
                                {
                                  label: t('cardinalMaxTemperature'),
                                  value: numberFormat(
                                    fieldCropData.cardinal_max_temperature
                                  ),
                                },
                                {
                                  label: t('spaceBetweenPlants'),
                                  value: numberFormat(
                                    fieldCropData.space_between_plants
                                  ),
                                },
                              ],
                            ]}
                          />
                        </Card>
                      </div>

                      <div className="col-md-4" style={{ minHeight: 300 }}>
                        <FieldsMapLeaflet
                          estate={estate}
                          fields={estateFields}
                          sectorsById={sectorsById}
                          allowSetObject={false}
                          height="100%"
                          mapPanel="fields"
                        />
                      </div>
                    </div>

                    <Card className="mt-3">
                      <CardHeader>{t('phenologyCharts')}</CardHeader>
                      <CardBody>
                        {kcData && (
                          <PhenologyChartRow
                            data={kcData}
                            field={field}
                            title={'KC'}
                          />
                        )}
                        {kcbData && (
                          <PhenologyChartRow
                            data={kcbData}
                            field={field}
                            title={'KCB'}
                          />
                        )}
                        {kcbMaxData && (
                          <Row className="border-bottom">
                            <Col className="p-4">
                              <h4>{t('KCBMax')}</h4>
                              <SimpleDataTable
                                columnsClass="col-sm-6"
                                columns={[
                                  [
                                    {
                                      label: t('GDDEmergence'),
                                      value: kcbMaxData.gdd_emergence,
                                    },
                                    {
                                      label: t('GDDFlowering'),
                                      value: kcbMaxData.gdd_flowering,
                                    },
                                  ],
                                  [
                                    {
                                      label: t('GDDHarvest'),
                                      value: kcbMaxData.gdd_harvest,
                                    },
                                    {
                                      label: t('KCBMax'),
                                      value: kcbMaxData.kcb_max,
                                    },
                                  ],
                                ]}
                              />
                            </Col>
                          </Row>
                        )}
                        {fao56Data && (
                          <PhenologyChartRow
                            data={fao56Data}
                            field={field}
                            title={'FAO56'}
                            showTable={false}
                            additionalData={[
                              [
                                {
                                  label: t('initialPeriod'),
                                  value: fao56Data.initial_period,
                                },
                                {
                                  label: t('cropDevelopment'),
                                  value: fao56Data.crop_development,
                                },
                                {
                                  label: t('midSeason'),
                                  value: fao56Data.mid_season,
                                },
                                {
                                  label: t('lateSeason'),
                                  value: fao56Data.late_season,
                                },
                              ],
                              [
                                {
                                  label: t('KCBinitial'),
                                  value: fao56Data.kcb_initial,
                                },
                                { label: '', value: '' },
                                {
                                  label: t('KCBmid'),
                                  value: fao56Data.kcb_mid,
                                },
                                {
                                  label: t('KCBfinal'),
                                  value: fao56Data.kcb_final,
                                },
                              ],
                            ]}
                          />
                        )}
                      </CardBody>
                    </Card>
                  </TabPane>
                )}
                {currentTab === 'irrigations' && (
                  <TabPane tabId="irrigations">
                    {irrigations && (
                      <IrrigationCalendar
                        events={events}
                        onRangeChange={this.onRangeChange}
                        fixedRecordProps={{
                          id_field: field.id,
                          applied_to: 'field',
                          fixTarget: true,
                        }}
                        updateEvents={this.updateEvents}
                      />
                    )}
                  </TabPane>
                )}
              </TabContent>
            </div>
          )}
        </Layout.Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  estateFields: getEstateFields(state),
  field: getField(state),
  loading: getFieldDataLoading(state),
  sectors: getEstateSectors(state),
  soilProviders: getFieldSoilProviders(state),
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
  numberFormat: getNumberFormat(state),
  irrigations: getFieldIrrigations(state),
})
export default withRouter(
  connect(
    mapStateToProps,
    {
      loadField,
      unloadField,
      loadSimulationsDict,
      unloadSimulationsDict,
      loadFieldData,
      unloadFieldData,
      loadFieldSoilProviders,
      unloadFieldSoilProviders,
      setMapObject,
      unsetMapObject,
      setMapBounds,
      loadFieldIrrigations,
      unloadFieldIrrigations,
    }
  )(localize()(EstateField))
)
