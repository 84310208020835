import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import {
    getField,
    getFieldData,
    getFieldDataLoading,
    loadFieldData,
    unloadFieldData,
    getFieldSoilSvgChart,
    getFieldSoilSvgChartLoading,
    loadFieldSoilSvgChart,
    unloadFieldSoilSvgChart,
} from '../state/fields'
import { ParentSize } from '@vx/responsive'
import { get, keys, values, isEqual } from 'lodash'
import moment from 'moment'
import { Group } from '@vx/group'
import { Card, CardBody, CardHeader, Nav, TabPane, TabContent, NavItem, NavLink } from 'reactstrap'
import IWGraph from './IWGraph'
import DatesSelector from './DatesSelector'
import { downloadFieldCSV } from '../api'
import { getUiCache, setUiCache } from '../state/ui'
import { getAuthAccessToken } from 'eazy-auth'
import classNames from 'classnames'

class FieldDataLegend extends React.PureComponent {
  state = {
    rects: {},
  }

  refsGroups = {}
  updateRects = () => {
    let rects = {}
    keys(this.refsGroups).forEach(key => {
      const node = ReactDOM.findDOMNode(this.refsGroups[key])
      if (node) {
        const rect = node.getBoundingClientRect()
        rects[key] = {
          width: parseInt(rect.width),
          height: parseInt(rect.height),
        }
      }
    })
    console.log('rects', rects)
    if (!isEqual(rects, this.state.rects)) {
      this.setState({ rects })
    }
  }

  componentDidUpdate() {
    this.updateRects()
  }

  componentDidMount() {
    this.updateRects()
  }

  getOffsets = (margin = 10) => {
    const { rects } = this.state
    if (!rects) {
      return {}
    }
    const legendKeys = keys(rects)
    return legendKeys.reduce((acc, item, idx) => {
      if (idx === 0) {
        acc[item] = 0
      } else {
        const value =
          acc[legendKeys[idx - 1]] + rects[legendKeys[idx - 1]].width + margin
        acc[item] = value
      }
      return acc
    }, {})
  }

  render() {
    const { rects } = this.state
    const { width, top, colors, labels } = this.props
    const offsets = this.getOffsets()

    const legendWidth = values(rects)
      .map(r => r.width)
      .reduce((acc, item) => acc + item, 0)
    const left = (width - legendWidth) / 2

    return (
      <Group left={left} top={top}>
        {labels.waterContent && (
          <g
            ref={node => (this.refsGroups['water-content'] = node)}
            transform={`translate(${get(offsets, 'water-content', 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={colors.waterContent}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {labels.waterContent}
            </text>
          </g>
        )}
        {labels.attainableWater && (
          <g
            ref={node => (this.refsGroups['attainable-water'] = node)}
            transform={`translate(${get(offsets, 'attainable-water', 0)},0)`}
          >
            <line
              strokeWidth={2}
              stroke={colors.attainableWater}
              x1={0}
              x2={20}
              y1={5}
              y2={5}
            ></line>
            <text x={23} y={5} className="chart-legend-label">
              {labels.attainableWater}
            </text>
          </g>
        )}
        {labels.irrigations && (
          <g
            ref={node => (this.refsGroups['irrigations'] = node)}
            transform={`translate(${get(offsets, 'irrigations', 0)},0)`}
          >
            <rect
              fill={colors.irrigations}
              x={0}
              y={1}
              height={8}
              width={20}
            ></rect>
            <text x={23} y={5} className="chart-legend-label">
              {labels.irrigations}
            </text>
          </g>
        )}
        {labels.rain && (
          <g
            ref={node => (this.refsGroups['rain'] = node)}
            transform={`translate(${get(offsets, 'rain', 0)},0)`}
          >
            <rect fill={colors.rain} x={0} y={1} height={8} width={20}></rect>
            <text x={23} y={5} className="chart-legend-label">
              {labels.rain}
            </text>
          </g>
            )}
      </Group>
    )
  }
}

class FieldDataChart extends React.PureComponent {
  state = {
    fromDate: null,
    toDate: null,
    minDate: null,
    maxDate: null,
      currentTab: 'chart1'
    }

    selectTab = currentTab => {
        this.setState({ currentTab })
    }

  componentDidUpdate(oldProps) {
    if (oldProps.fieldData !== this.props.fieldData) {
      const { fieldData } = this.props
      this.setState({
        minDate: moment(fieldData.min_date),
        maxDate: moment(fieldData.max_date),
        fromDate: moment(fieldData.from_date),
        toDate: moment(fieldData.to_date),
        maxRange: get(fieldData, 'max_range', 5),
      })

      this.props.setDates &&
        this.props.setDates({
          minDate: moment(fieldData.min_date),
          maxDate: moment(fieldData.max_date),
          fromDate: moment(fieldData.from_date),
          toDate: moment(fieldData.to_date),
          maxRange: get(fieldData, 'max_range', 5),
        })
    }
  }

  handleChangeFromDate = fromDate => {
    const { field, fieldData } = this.props
    const maxRange = get(fieldData, 'max_range', 5)
    const maxToDate = fromDate.clone().add(maxRange, 'days')
    const newToDate =
      this.state.toDate.isBefore(maxToDate) &&
      this.state.toDate.isAfter(fromDate)
        ? this.state.toDate
        : fromDate
            .clone()
            .add(maxRange, 'days')
            .add(1, 'hours')
    let newState = { fromDate, toDate: newToDate }
    this.setState(newState, () => {
        this.props.loadFieldData(field.id, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
        })
        this.props.setUiCache(`field-data-chart-${field.id}`, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
        })
        this.props.loadFieldSoilSvgChart(field.id, {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
        })
        this.props.setUiCache(`field-SoilSvg-chart-${field.id}`, {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
        })
    })
  }

  handleChangeToDate = toDate => {
    const { field, fieldData } = this.props
    const { fromDate } = this.state
    let newState = { toDate }
    const maxRange = get(fieldData, 'max_range', 5)
    const maxFromDate = toDate.clone().subtract(maxRange, 'days')
    if (maxFromDate.isAfter(fromDate)) {
      newState.fromDate = maxFromDate
    }
    this.setState(newState, () => {
        this.props.loadFieldData(field.id, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
        })
        this.props.setUiCache(`field-data-chart-${field.id}`, {
        from_date: this.state.fromDate.format(),
        to_date: this.state.toDate.format(),
        })
        this.props.loadFieldSoilSvgChart(field.id, {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
        })
        this.props.setUiCache(`field-soilsvg-chart-${field.id}`, {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
        })
    })
  }

  render() {
    const {
      fieldData,
      fieldDataLoading,
      height,
      field,
      t,
      accessToken,
      fieldSoilSvgChart,
    } = this.props

    const { currentTab } = this.state

    const currentParams =
      this.state.fromDate && this.state.toDate
        ? {
            from_date: this.state.fromDate.format(),
            to_date: this.state.toDate.format(),
          }
        : {}

    return (
      <div>
        <Card className="my-3">
          <CardHeader>
            <DatesSelector
              title={t('waterContent')}
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              minDate={this.state.minDate}
              maxDate={this.state.maxDate}
              handleChangeFromDate={this.handleChangeFromDate}
              handleChangeToDate={this.handleChangeToDate}
              loading={fieldDataLoading}
              right={
                <small
                  id={`download-${field.name}`}
                  className="text-primary pointer"
                  href={null}
                  onClick={() => {
                    downloadFieldCSV(
                      field.id,
                      currentParams,
                      `${field.id}.csv`,
                      accessToken
                    )
                  }}
                >
                  {t('downloadCSV')}
                </small>
              }
              rightTooltip={t('downloadCSV')}
              rightTooltipTarget={`download-${field.id}`}
            />
          </CardHeader>
          <CardBody>
            {fieldData && (
              <ParentSize>
                {({ width: w, height: h }) => {
                 return (
                    <div>
                        <div className="mt-0 mb-0">
                            <Nav tabs>
                                <NavItem className="pointer">
                                    <NavLink
                                        className={classNames({
                                            active: currentTab === 'chart1',
                                        })}
                                        onClick={() => {
                                            this.selectTab('chart1')
                                        }}
                                    >
                                    <i className="fa fa-chart"></i> {t('contenuto_idrico')}
                                    </NavLink>
                                </NavItem>

                                <NavItem className="pointer">
                                    <NavLink
                                        className={classNames({
                                            active: currentTab === 'chart2',
                                        })}
                                        onClick={() => {
                                            this.selectTab('chart2')
                                        }}
                                    >
                                    <i className="fa fa-chart"></i> {t('variabili_giornaliere')}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                         </div>
                        <TabContent activeTab={currentTab}>
                             {currentTab === 'chart1' && (
                                 <TabPane tabId="chart1">
                                     <div className="text-center">
                                         <h6 className="mt-2">{t('attainableWater')}</h6>
                                         <IWGraph
                                             chartId={'attainable-water'}
                                             showWaterContent={false}
                                             width={w}
                                             height={height}
                                             data={fieldData}
                                             legend={(width, colors = {}, labels = {}) => (
                                                 <FieldDataLegend
                                                     width={width}
                                                     colors={colors}
                                                     labels={labels}
                                                     top={5}
                                                 />
                                             )}
                                         />
                                         <h6>{t('soilWaterContent')}</h6>
                                         <IWGraph
                                             chartId={'water-content'}
                                             showAttainableWater={false}
                                             width={w}
                                             height={height}
                                             data={fieldData}
                                             legend={(width, colors = {}, labels = {}) => (
                                                 <FieldDataLegend
                                                     width={width}
                                                     colors={colors}
                                                     labels={labels}
                                                     top={5}
                                                 />
                                             )}
                                         />
                                     </div>
                                 </TabPane>
                             )}

                             {currentTab === 'chart2' && (
                                 <TabPane tabId="chart2">
                                     {fieldSoilSvgChart && (
                                         <div style={{ width: '100 %', margin: '0 auto' }} dangerouslySetInnerHTML={{ __html: fieldSoilSvgChart.soil_svg_chart.svg_chart }}></div>
                                    )}
                                 </TabPane>
                             )}
                         </TabContent>
                     </div>
                  )
                }}
              </ParentSize>
            )}
          </CardBody>
        </Card>
      </div>
    )
  }

  componentDidMount() {
    const { field, uiCache } = this.props
        let loadParams = {}
        if (uiCache[`field-data-chart-${field.id}`]) {
            loadParams = { ...uiCache[`field-data-chart-${field.id}`] }
        }
      this.props.loadFieldData(field.id, loadParams)

        let loadSvgParams = {}
        if (uiCache[`field-soilsvg-chart-${field.id}`]) {
            loadSvgParams = { ...uiCache[`field-soilsvg-chart-${field.id}`] }
        }
      this.props.loadFieldSoilSvgChart(field.id, loadSvgParams)
  }

  componentWillUnmount() {
      this.props.unloadFieldData()
      this.props.unloadFieldSoilSvgChart()
  }
}

FieldDataChart.defaultProps = {
  height: 600,
}

const mapStateToProps = state => ({
  field: getField(state),
  fieldData: getFieldData(state),
  fieldDataLoading: getFieldDataLoading(state),
  fieldSoilSvgChart: getFieldSoilSvgChart(state),
  fieldSoilSvgChartLoading: getFieldSoilSvgChartLoading(state),
  uiCache: getUiCache(state),
  accessToken: getAuthAccessToken(state),
})

const mapDispatchToProps = {
  loadFieldData,
  unloadFieldData,
  setUiCache,
  loadFieldSoilSvgChart,
  unloadFieldSoilSvgChart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(localize()(FieldDataChart))
