import React from 'react'
import ReactDOM from 'react-dom'
// import 'bootstrap/dist/css/bootstrap.css'
import './theme/bootstrap.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'font-awesome/css/font-awesome.css'
import 'leaflet/dist/leaflet.css'
// https://danwild.github.io/leaflet-fa-markers/L.Icon.FontAwesome.css
import 'leaflet-fontawesome-markers/L.Icon.FontAwesome.css'
import App from './App'
import 'leaflet'
import 'leaflet.pattern'
import 'leaflet-fontawesome-markers'
// optional serviceWorker ... needs https
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'))
// optional serviceWorker ... needs https
// registerServiceWorker();
