import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { get, keyBy } from 'lodash'
import Layout from '../components/Layout'
import SectorFieldsTable from '../components/SectorFieldsTable'
import EstateFieldsTable from '../components/EstateFieldsTable'
import { FieldsMapLeaflet } from '../components/FieldsMap'
import { setMapObject, unsetMapObject } from '../state/ui'
import classNames from 'classnames'
import { getStatusClass } from '../utils'
import {
  getDayMonthYearMomentUserFormat,
  getNumberFormat,
} from '../state/languages'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardBody,
  CardText,
} from 'reactstrap'

import {
  getEstate,
  getEstateFields,
  getEstateSectors,
  getEstateNotifications,
  getEstateMeteoStations,
} from '../state/estates'
import {
  getSector,
  loadSector,
  unloadSector,
  loadSectorData,
  unloadSectorData,
  loadSectorIrrigations,
  unloadSectorIrrigations,
  getSectorIrrigations,
} from '../state/sectors'
import moment from 'moment'
import IrrigationCalendar from '../components/EstateIrrigation/IrrigationCalendar'

const SuggestedIrrigationFromSector = withRouter(
  ({ irrigation, estate, sector, label = 'Add irrigation' }) => {
    const state = {
      id_estate: estate.id,
      type: 'irrigation',
      date: irrigation.date,
      use_duration: true,
      duration: irrigation.duration_min,
      amount: 0,
      id: 0,
      id_field: 0,
      id_irrigation_sector: sector.id,
      applied_to: 'irrigation_sector',
    }

    const to = {
      pathname: `/estates/${estate.id}`,
      search: '?tabName=irrigation',
      state,
    }

    return (
      <span>
        {' '}
        <Link to={to}>{label}</Link>
      </span>
    )
  }
)

class EstateSector extends PureComponent {
  state = { currentTab: 'status' }

  componentDidMount() {
    const { match } = this.props
    this.props.loadSector(match.params.sectorId, { includeStatus: true })
    this.props.loadSectorIrrigations({
      idEstate: match.params.estateId,
      idSector: match.params.sectorId,
    })
    // this.props.loadSectorData(match.params.sectorId)
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props
    if (match.params.sectorId !== nextProps.match.params.sectorId) {
      this.props.unloadSector()
      // this.props.unloadSectorData()
      this.props.loadSector(nextProps.match.params.sectorId, {
        includeStatus: true,
      })
      // this.props.loadSectorData(nextProps.match.params.sectorId)
    }

    if (nextProps.sector && nextProps.sector !== this.props.sector) {
      const { sector } = nextProps
      this.props.setMapObject({ type: 'sector', id: sector.id, item: sector })
    }
  }

  selectTab = currentTab => {
    this.setState({ currentTab })
  }

  componentWillUnmount() {
    this.props.unloadSector()
    this.props.unloadSectorData()
    this.props.unsetMapObject()
    this.props.unloadSectorIrrigations()
  }

  getSuggestedIrrigation = (data, subFieldName) => {
    const subField = get(data, subFieldName)
    const suggestedIrrigation = get(subField, 'suggested_irrigation')
    if (!suggestedIrrigation) {
      return undefined
    }
    if (!suggestedIrrigation.duration_min && !suggestedIrrigation.quantity_m3) {
      return undefined
    }
    return suggestedIrrigation
  }

  onRangeChange = range => {
    const { match } = this.props
    const fromDate = Array.isArray(range) ? range[0] : range.start
    const toDate = Array.isArray(range) ? range[range.length - 1] : range.end
    const params = {
      from_date: moment(fromDate).format('YYYY-MM-DD'),
      to_date: moment(toDate).format('YYYY-MM-DD'),
    }
    this.props.loadSectorIrrigations({
      idEstate: match.params.estateId,
      idSector: match.params.sectorId,
      params,
    })
  }

  updateEvents = () => {
    const { match } = this.props
    const params = {
      from_date: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      to_date: moment()
        .endOf('month')
        .format('YYYY-MM-DD'),
    }
    this.props.loadSectorIrrigations({
      idEstate: match.params.estateId,
      idSector: match.params.sectorId,
      params,
    })
  }

  render() {
    const {
      estate,
      sector,
      estateFields,
      estateSectors,
      estateNotifications,
      t,
      numberFormat,
      irrigations,
    } = this.props
    const { currentTab } = this.state
    const sectorsById = keyBy(estateSectors, 'id')

    if (!estate || !sector) {
      return null
    }

    const events = get(irrigations, 'events', []).map(irrigation => ({
      ...irrigation,
      target: sector,
    }))

    // const waterDeficitStatus = get(sector, "water_deficit_status")
    // const deficitStatus = get(waterDeficitStatus, 'status')
    // const suggestedIrrigation = get(waterDeficitStatus, 'suggested_irrigation')

    const deficitStatus = get(sector, 'water_deficit_status.status')
    const soilWater = get(sector, 'water_deficit_status.soil_water')
    const deficitStatus24 = get(sector, 'water_deficit_status_24.status')
    const soilWater24 = get(sector, 'water_deficit_status_24.soil_water')
    const deficitStatus48 = get(sector, 'water_deficit_status_48.status')
    const soilWater48 = get(sector, 'water_deficit_status_48.soil_water')

    const suggestedIrrigation = this.getSuggestedIrrigation(
      sector,
      'water_deficit_status'
    )
    const alertClass =
      deficitStatus === 'Irri_Warning'
        ? 'alert-warning'
        : deficitStatus === 'Irri_Critical'
        ? 'alert-danger'
        : 'alert-info'
    const anyDeficitStatus =
      deficitStatus !== undefined ||
      deficitStatus24 !== undefined ||
      deficitStatus48 !== undefined

    return (
      <Layout>
        <Layout.Content className="container-fluid h-100">
          <div className="mt-3">
            <h5>
              <Link to={`/estates`} className="text-muted">
                {t('estates')}
              </Link>
              {' / '}
              <Link to={`/estates/${estate.id}`} className="text-muted">
                {estate.name}
              </Link>
              {' / '}
              {sector.name}
            </h5>
          </div>

          <div className="my-3">
            <Nav tabs>
              <NavItem className="pointer">
                <NavLink
                  className={classNames({ active: currentTab === 'status' })}
                  onClick={() => {
                    this.selectTab('status')
                  }}
                >
                  <i className="fa fa-tint"></i> {t('status')}
                </NavLink>
              </NavItem>

              <NavItem className="pointer">
                <NavLink
                  className={classNames({
                    active: currentTab === 'configuration',
                  })}
                  onClick={() => {
                    this.selectTab('configuration')
                  }}
                >
                  <i className="fa fa-sliders"></i> {t('configuration')}
                </NavLink>
              </NavItem>

              <NavItem className="pointer">
                <NavLink
                  className={classNames({
                    active: currentTab === 'irrigations',
                  })}
                  onClick={() => {
                    this.selectTab('irrigations')
                  }}
                >
                  <i className="fa fa-sliders"></i> {t('irrigations')}
                </NavLink>
              </NavItem>
            </Nav>
          </div>

          <TabContent activeTab={currentTab}>
            {currentTab === 'status' && (
              <TabPane tabId="status">
                <div className="row">
                  <div
                    className="col-md-8"
                    // style={{ flex: 2 }}
                  >
                    <Card className="mb-2">
                      <CardHeader className={`${alertClass}`}>
                        {t('status')} <b>{deficitStatus || ''}</b>
                      </CardHeader>
                      <CardBody style={{ minHeight: 120 }}>
                        {!anyDeficitStatus && (
                          <div>{t('noDeficitDataForThisSector')}</div>
                        )}
                        {deficitStatus && (
                          <div>
                            {t('deficitStatus')}:{' '}
                            <span
                              className={`badge ${getStatusClass(
                                deficitStatus,
                                'badge-'
                              )}`}
                            >
                              {deficitStatus}
                            </span>
                          </div>
                        )}
                        {soilWater && (
                          <div className="mb-1">
                            <small>
                              {t('waterDeficit')}:{' '}
                              {numberFormat(soilWater.water_deficit)} mm.
                              <br />
                              {t('soilWaterContent')}:{' '}
                              {numberFormat(soilWater.available_water)} m
                              <sup>3</sup>/m<sup>3</sup>
                            </small>
                          </div>
                        )}
                        {deficitStatus24 && (
                          <div className="mt-1">
                            {t('deficitStatus')} (24h):{' '}
                            <span
                              className={`badge ${getStatusClass(
                                deficitStatus24,
                                'badge-'
                              )}`}
                            >
                              {deficitStatus24}
                            </span>
                          </div>
                        )}
                        {soilWater24 && (
                          <div className="mb-1">
                            <small>
                              {t('waterDeficit')}:{' '}
                              {numberFormat(soilWater24.water_deficit)} mm.
                              <br />
                              {t('soilWaterContent')}:{' '}
                              {numberFormat(soilWater24.available_water)} m
                              <sup>3</sup>/m<sup>3</sup>
                            </small>
                          </div>
                        )}
                        {deficitStatus48 && (
                          <div className="mt-1">
                            {t('deficitStatus')} (48h):{' '}
                            <span
                              className={`badge ${getStatusClass(
                                deficitStatus48,
                                'badge-'
                              )}`}
                            >
                              {deficitStatus48}
                            </span>
                          </div>
                        )}
                        {soilWater48 && (
                          <div className="mb-1">
                            <small>
                              {t('waterDeficit')}:{' '}
                              {numberFormat(soilWater48.water_deficit)} mm.
                              <br />
                              {t('soilWaterContent')}:{' '}
                              {numberFormat(soilWater48.available_water)} m
                              <sup>3</sup>/m<sup>3</sup>
                            </small>
                          </div>
                        )}
                      </CardBody>
                    </Card>

                    <Card>
                      <CardHeader className={`${alertClass}`}>
                        {t('nextIrrigations')}
                      </CardHeader>
                      <CardBody>
                        {!suggestedIrrigation && (
                          <div>{t('noSuggestedIrrigationForThisSector')}</div>
                        )}
                        {suggestedIrrigation && (
                          <CardText>
                            {t('anIrrigationOf')}{' '}
                            <b>
                              {numberFormat(suggestedIrrigation.quantity_m3)} m
                              <sup>3</sup>
                            </b>{' '}
                            {t('for')}{' '}
                            <b>
                              {suggestedIrrigation.duration_min} {t('minutes')}
                            </b>{' '}
                            {t('isExpectedOn')}{' '}
                            <b>
                              {moment(suggestedIrrigation.date).format(
                                this.props.dayMonthYearMomentUserFormat
                              )}
                            </b>{' '}
                            {t('at')}{' '}
                            <b>
                              {moment(suggestedIrrigation.date).format('HH:mm')}
                            </b>{' '}
                            <SuggestedIrrigationFromSector
                              irrigation={suggestedIrrigation}
                              estate={estate}
                              sector={sector}
                            />
                          </CardText>
                        )}
                      </CardBody>
                    </Card>
                  </div>
                  <div className="col-md-4" style={{ minHeight: 300 }}>
                    <FieldsMapLeaflet
                      allowSetObject={false}
                      sectorsById={sectorsById}
                      height="100%"
                      mapPanel="sectors"
                      estate={estate}
                      fields={estateFields}
                    />
                  </div>
                </div>
                <SectorFieldsTable />
              </TabPane>
            )}

            {currentTab === 'configuration' && (
              <TabPane tabId="configuration">
                <div className="row">
                  <div className="col-md-9">
                    <Card className="mb-2">
                      <CardHeader>{t('description')}</CardHeader>
                      <CardBody style={{ minHeight: 120 }}>
                        {sector.description || <span>&nbsp;</span>}
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader>{t('managementPolicy')}</CardHeader>
                      <CardBody>
                        <CardText>{sector.management_policy || ' '}</CardText>
                      </CardBody>
                    </Card>
                  </div>

                  <div className="col-md-3" style={{ minHeight: 300 }}>
                    {/* <EstateItemMiniMap  estate={estate} className="w-100 h-100" height='100%'/> */}
                    <FieldsMapLeaflet
                      allowSetObject={false}
                      sectorsById={sectorsById}
                      height="100%"
                      mapPanel="sectors"
                      estate={estate}
                      fields={estateFields}
                    />
                  </div>
                </div>

                {estateFields && (
                  <div className="row">
                    <div className="col">
                      <EstateFieldsTable
                        estate={estate}
                        fields={estateFields.filter(
                          x => x.id_irrigation_sector === sector.id
                        )}
                        notifications={estateNotifications}
                      />
                    </div>
                  </div>
                )}
              </TabPane>
            )}

            {currentTab === 'irrigations' && (
              <TabPane tabId="irrigations">
                {irrigations && (
                  <IrrigationCalendar
                    events={events}
                    onRangeChange={this.onRangeChange}
                    fixedRecordProps={{
                      id_irrigation_sector: sector.id,
                      applied_to: 'irrigation_sector',
                      fixTarget: true,
                    }}
                    updateEvents={this.updateEvents}
                  />
                )}
              </TabPane>
            )}
          </TabContent>
        </Layout.Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  sector: getSector(state),
  estateFields: getEstateFields(state),
  estateSectors: getEstateSectors(state),
  estateNotifications: getEstateNotifications(state),
  estateMeteoStations: getEstateMeteoStations(state),
  dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
  numberFormat: getNumberFormat(state),
  irrigations: getSectorIrrigations(state),
})
export default connect(
  mapStateToProps,
  {
    loadSector,
    loadSectorIrrigations,
    unloadSectorIrrigations,
    unloadSector,
    loadSectorData,
    unloadSectorData,
    setMapObject,
    unsetMapObject,
  }
)(localize()(EstateSector))
