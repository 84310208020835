import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { authApiCall, withToken } from './auth'
import {
  createIrrigationReducer,
  updateIrrigationReducer,
  deleteIrrigationReducer,
} from './estates'
import { API_URL } from './consts'

import { rj, composeReducers } from 'redux-rocketjump'
import { rjDetail, rjDict, rjIrrigationEvents } from './utils'

// Namespace for actions
const NS = '@sectors/'

// List
const GET_SECTORS = `${NS}GET_SECTORS`
export const {
  actions: { load: loadSectors, unload: unloadSectors },
  selectors: { getData: getSectors, isLoading: getSectorsLoading },
  reducer: listReducer,
  saga: listSaga,
} = rj({
  type: GET_SECTORS,
  state: 'sectors.list',
  effectCaller: authApiCall,
  effect: t => params =>
    withToken(t, request.get(`${API_URL}irrigationsectors`))
      .query(params)
      .then(({ body }) => body),
})()

// Detail
const GET_SECTOR = `${NS}GET_SECTOR`
export const {
  actions: { load: loadSector, unload: unloadSector },
  selectors: { getData: getSector },
  reducer: detailReducer,
  saga: detailSaga,
} = rj(rjDetail(), {
  type: GET_SECTOR,
  state: 'sectors.detail',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(
      t,
      request.get(`${API_URL}irrigationsectors/${id}`).query(params)
    ).then(({ body }) => body),
})()

// Notifications list
const GET_SECTORS_NOTIFICATIONS = `${NS}GET_SECTORS_NOTIFICATIONS`
export const {
  actions: { load: loadSectorNotifications, unload: unloadSectorNotifications },
  selectors: {
    getData: getSectorNotifications,
    isLoading: getSectorNotificationsLoading,
  },
  reducer: detailNotificationsReducer,
  saga: detailNotificationsSaga,
} = rj(rjDetail(), {
  type: GET_SECTORS_NOTIFICATIONS,
  state: 'sectors.detailNotifications',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request.get(`${API_URL}irrigationsectors/${id}/notifications`)
    ).then(({ body }) => body),
})()

// Fields list
const GET_SECTORS_FIELDS = `${NS}GET_SECTORS_FIELDS`
export const {
  actions: { load: loadSectorFields, unload: unloadSectorFields },
  selectors: { getData: getSectorFields, isLoading: getSectorFieldsLoading },
  reducer: detailFieldsReducer,
  saga: detailFieldsSaga,
} = rj(rjDetail(), {
  type: GET_SECTORS_FIELDS,
  state: 'sectors.detailFields',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(
      t,
      request.get(`${API_URL}irrigationsectors/${id}/fields`).query(params)
    ).then(({ body }) => body),
})()

// Sector data
const GET_SECTORS_DATA = `${NS}GET_SECTORS_DATA`
export const {
  actions: { load: loadSectorData, unload: unloadSectorData },
  selectors: { getData: getSectorData, isLoading: getSectorDataLoading },
  reducer: detailDataReducer,
  saga: detailDataSaga,
} = rj(rjDetail(), {
  type: GET_SECTORS_DATA,
  state: 'sectors.detailData',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(
      t,
      request.get(`${API_URL}irrigationsectors/${id}/data`).query(params)
    ).then(({ body }) => body),
})()

// Dict
const GET_FIELDS_DATA_DICT = `${NS}GET_FIELDS_DATA_DICT`
export const {
  actions: {
    load: loadSectorFieldsDataDict,
    unload: unloadSectorFieldsDataDict,
  },
  selectors: { makeGetData: makeGetSectorsFieldData },
  reducer: detailFieldsDataDictReducer,
  saga: detailFieldsDataDictSaga,
} = rj(rjDict(), {
  type: GET_FIELDS_DATA_DICT,
  state: 'sectors.detailFieldsData',
  effectCaller: authApiCall,
  effect: t => ({ id, ...params }) =>
    withToken(t, request.get(`${API_URL}fields/${id}/data`).query(params)).then(
      ({ body }) => ({ ...body, id })
    ),
})()

// Irrigations list
const GET_SECTORS_IRRIGATIONS = `${NS}GET_SECTORS_IRRIGATIONS`
export const {
  actions: { load: loadSectorIrrigations, unload: unloadSectorIrrigations },
  selectors: {
    getData: getSectorIrrigations,
    isLoading: getSectorIrrigationsLoading,
  },
  reducer: detailIrrigationsReducer,
  saga: detailIrrigationsSaga,
} = rj(rjIrrigationEvents(), {
  type: GET_SECTORS_IRRIGATIONS,
  state: 'sectors.detailIrrigations',
  effectCaller: authApiCall,
  reducer: reducer =>
    composeReducers(
      reducer,
      updateIrrigationReducer,
      createIrrigationReducer,
      deleteIrrigationReducer
    ),
  effect: t => ({ idEstate, idSector, params }) =>
    withToken(
      t,
      request.get(
        `${API_URL}calendar/estates/${idEstate}/sectors/${idSector}/irrigations`
      )
    )
      .query(params)
      .then(({ body }) => body),
})()

// Irrigation preview
const GET_SECTOR_IRRIGATION_PREVIEW = `${NS}GET_SECTOR_IRRIGATION_PREVIEW`
export const {
  actions: {
    load: loadSectorIrrigationPreview,
    unload: unloadSectorIrrigationPreview,
  },
  selectors: {
    getData: getSectorIrrigationPreview,
    isLoading: getSectorIrrigationPreviewLoading,
  },
  reducer: detailIrrigationPreviewReducer,
  saga: detailIrrigationPreviewSaga,
} = rj(rjDetail(), {
  type: GET_SECTOR_IRRIGATION_PREVIEW,
  state: 'sectors.detailIrrigationPreview',
  effectCaller: authApiCall,
  effect: t => ({ id, ...data }) => {
    return withToken(
      t,
      request.post(`${API_URL}irrigationsectors/${id}/simulate-irrigation`)
    )
      .set('Content-Type', 'application/json')
      .send({ ...data, id: 0 })
      .then(({ body }) => body)
  },
})()

export const reducer = combineReducers({
  list: listReducer,
  detail: detailReducer,
  detailNotifications: detailNotificationsReducer,
  detailData: detailDataReducer,
  detailFields: detailFieldsReducer,
  detailFieldsData: detailFieldsDataDictReducer,
  detailIrrigations: detailIrrigationsReducer,
  detailIrrigationPreview: detailIrrigationPreviewReducer,
})

export const saga = function*() {
  yield fork(listSaga)
  yield fork(detailSaga)
  yield fork(detailNotificationsSaga)
  yield fork(detailDataSaga)
  yield fork(detailFieldsSaga)
  yield fork(detailFieldsDataDictSaga)
  yield fork(detailIrrigationsSaga)
  yield fork(detailIrrigationPreviewSaga)
}
