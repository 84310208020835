import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../../localize'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import IrrigationForm from './IrrigationForm'
import moment from 'moment'
import omit from 'lodash/omit'
import { getDayMonthYearMomentUserFormat } from '../../state/languages'
import {
  loadEstateAllegedIrrigationEvents,
  getEstate,
} from '../../state/estates'

class IrrigationModal extends React.PureComponent {
  state = {}
  render() {
    const {
      record,
      createIrrigation,
      updateIrrigation,
      t,
      updateEvents,
      dayMonthYearMomentUserFormat,
      estate,
    } = this.props

    console.log('r', record)

    return (
      <Modal size="lg" isOpen={true} toggle={this.props.closeModal}>
        <ModalHeader toggle={this.props.closeModal} className="bg-light">
          {record.id ? t('editIrrigation') : t('addIrrigation')}
        </ModalHeader>
        <ModalBody>
          {!!record.alleged_irrigation_event && (
            <div className="alert alert-info mb-2">
              <small>
                {t('allegedIrrigationInfo')} (
                {record.alleged_irrigation_event.field_name}){' '}
                {moment(record.alleged_irrigation_event.date).format(
                  dayMonthYearMomentUserFormat
                )}
              </small>
            </div>
          )}
          <IrrigationForm
            onSubmit={record.id ? updateIrrigation : createIrrigation}
            onSubmitSuccess={savedRecord => {
              this.props.closeModal()
              if (record.id) {
                this.props.irrigationUpdated(savedRecord)
                updateEvents()
              } else {
                this.props.irrigationCreated(savedRecord)
              }
              //updating alleged irrigations on save..
              this.props.loadEstateAllegedIrrigationEvents({ id: estate.id })
            }}
            closeModal={this.props.closeModal}
            initialValues={{
              ...omit(record, 'alleged_irrigation_event'),
              alleged_irrigation: record.alleged_irrigation_event
                ? record.alleged_irrigation_event.id
                : null,
              date: moment(record.date).format('YYYY-MM-DDTHH:mm'),
              use_duration: true,
            }}
            updating={!!record.id}
          />
        </ModalBody>
        {!!record.id && (
          <ModalFooter className="text-center">
            <Button
              size="sm"
              color="danger"
              onClick={() => {
                this.setState({ askConfirm: true })
              }}
            >
              {t('deleteRecord')}
            </Button>{' '}
          </ModalFooter>
        )}
        <Modal isOpen={this.state.askConfirm}>
          <ModalBody>
            <div className="p-2">{t('irrigationWillBeDeleted')}</div>
            <div className="d-flex justify-content-between">
              <Button
                color="secondary"
                onClick={() => this.setState({ askConfirm: false })}
              >
                {t('cancel')}
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.props.deleteEstateIrrigationEvent({ id: record.id })
                }}
              >
                {t('confirm')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    dayMonthYearMomentUserFormat: getDayMonthYearMomentUserFormat(state),
    estate: getEstate(state),
  }),
  { loadEstateAllegedIrrigationEvents }
)(localize()(IrrigationModal))
