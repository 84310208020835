import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { getAuthAccessToken as getToken } from 'eazy-auth'
import { createSelector } from 'reselect'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import { rj } from 'redux-rocketjump'

// Namespace for actions
const NS = '@profile/'

// fields list
const GET_PROFILE = `${NS}GET_PROFILE`
export const {
  actions: { load: loadProfile, unload: unloadProfile },
  selectors: { getData: getProfile, isLoading: getProfileLoading },
  reducer: profileReducer,
  saga: profileSaga,
} = rj({
  type: GET_PROFILE,
  state: 'profile',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}profile`))
      // .query(params)
      .then(({ body }) => body),
})()

export const reducer = combineReducers({
  profile: profileReducer,
})

export const saga = function*() {
  yield fork(profileSaga)
}

export const makeChangePassword = createSelector(
  getToken,
  token => record =>
    withToken(
      token,
      request.put(`${API_URL}change-password`).send(record)
    ).then(({ body }) => body)
)
