import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import {
  loadEstate,
  unloadEstate,
  getEstate,
  getEstates,
  unloadEstates,
  loadEstateNotifications,
  unloadEstateNotifications,
  getEstateNotifications,
  loadEstateFieldsNotifications,
  unloadEstateFieldsNotifications,
  loadEstateSectorsNotifications,
  unloadEstateSectorsNotifications,
  loadEstateMeteoStations,
  unloadEstateMeteoStations,
  loadEstateFields,
  unloadEstateFields,
  getEstateFields,
  loadEstateSectors,
  unloadEstateSectors,
  loadEstateAllegedIrrigationEvents,
  unloadEstateAllegedIrrigationEvents,
  loadEstateFieldsStatus,
  unloadEstateFieldsStatus,
  loadEstateUnits,
  unloadEstateUnits,
} from '../state/estates'
import { getSensorNodeModal, unsetSensorNodeModal } from '../state/ui'
import EstateDetail from './EstateDetail'
import EstateField from './EstateField'
import EstateSector from './EstateSector'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { SensorNodeChart } from '../components/SoilProviderChart'
import SensorNode from '../pages/SensorNode'
import { get } from 'lodash'

class Estate extends React.Component {
  componentDidMount() {
    const { match } = this.props
    this.props.loadEstate(match.params.estateId)
    this.props.loadEstateFields({ id: match.params.estateId })
    this.props.loadEstateSectors({ id: match.params.estateId })
    this.props.loadEstateNotifications({ id: match.params.estateId })
    this.props.loadEstateFieldsNotifications({ id: match.params.estateId })
    this.props.loadEstateFieldsStatus({ id: match.params.estateId })
    this.props.loadEstateSectorsNotifications({ id: match.params.estateId })
    this.props.loadEstateMeteoStations({ id: match.params.estateId })
    this.props.loadEstateAllegedIrrigationEvents({ id: match.params.estateId })
    this.props.loadEstateUnits({ id: match.params.estateId })
  }

  componentWillUnmount() {
    this.props.unloadEstate()
    this.props.unloadEstateFields()
    this.props.unloadEstateSectors()
    this.props.unloadEstateNotifications()
    this.props.unloadEstateFieldsNotifications()
    this.props.unloadEstateFieldsStatus()
    this.props.unloadEstateSectorsNotifications()
    this.props.unloadEstateMeteoStations()
    this.props.unloadEstateAllegedIrrigationEvents()
    this.props.unloadEstateUnits()
    const { estates } = this.props
    if (estates && estates.length === 0) {
      this.props.unloadEstates()
    }
  }

  render() {
    const { t } = this.props
    return (
      <div>
        <Switch>
          <Route path="/estates/:estateId" exact component={EstateDetail} />
          <Route
            path="/estates/:estateId/fields/:fieldId"
            exact
            component={EstateField}
          />
          <Route
            path="/estates/:estateId/sectors/:sectorId"
            exact
            component={EstateSector}
          />
          <Route
            path="/estates/:estateId/sensor-nodes/:sensorNodeId"
            component={SensorNode}
          />
        </Switch>
        <div>
          <Modal
            size={'lg'}
            isOpen={!!this.props.sensorNodeModal}
            toggle={() => this.props.unsetSensorNodeModal()}
            className={`${this.props.className} modal-xl`}
          >
            <ModalHeader toggle={() => this.props.unsetSensorNodeModal()}>
              {get(this.props.sensorNodeModal, 'name') && (
                <span>
                  {t('sensorNode')}: {this.props.sensorNodeModal.name}
                </span>
              )}
            </ModalHeader>
            <ModalBody>
              {this.props.sensorNodeModal && (
                <SensorNodeChart
                  showTitle={false}
                  nodeProvider={this.props.sensorNodeModal}
                />
              )}
            </ModalBody>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  estates: getEstates(state),
  estateFields: getEstateFields(state),
  estateNotifications: getEstateNotifications(state),
  sensorNodeModal: getSensorNodeModal(state),
})
export default withRouter(
  connect(
    mapStateToProps,
    {
      loadEstate,
      unloadEstate,
      unloadEstates,
      loadEstateFields,
      unloadEstateFields,
      loadEstateUnits,
      unloadEstateUnits,
      loadEstateNotifications,
      unloadEstateNotifications,
      loadEstateFieldsNotifications,
      unloadEstateFieldsNotifications,
      loadEstateFieldsStatus,
      unloadEstateFieldsStatus,
      loadEstateSectorsNotifications,
      unloadEstateSectorsNotifications,
      loadEstateSectors,
      unloadEstateSectors,
      loadEstateMeteoStations,
      unloadEstateMeteoStations,
      unsetSensorNodeModal,
      loadEstateAllegedIrrigationEvents,
      unloadEstateAllegedIrrigationEvents,
    }
  )(localize()(Estate))
)
