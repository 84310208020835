import React from 'react'
import Spinner from 'react-spinkit'

const SpinnerWrapped = ({ color = '#bbb', ...rest }) => (
  <div {...rest}>
    {/* <i className='fa fa-spin fa-refresh fa-2x fa-fw' /> */}
    <Spinner name="line-scale" color={color} />
  </div>
)

export default SpinnerWrapped
