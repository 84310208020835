import React from 'react'
import { Map, CircleMarker } from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google'
import { GOOGLE_API_KEY } from '../consts'

export default class EstateMiniMap extends React.PureComponent {
  state = {}

  componentWillMount() {
    const { estate } = this.props
    this.setState({
      viewport: {
        center: [estate.latitude, estate.longitude],
        zoom: 7,
      },
    })
  }

  render() {
    const { width, height, estate, className } = this.props

    return (
      <div className={className}>
        {this.state.viewport && (
          <Map
            ref={node => (this.map = node)}
            style={{ height: height, width: width }}
            onClick={this.onClickReset}
            zoomControl={false}
            dragging={false}
            touchZoom={false}
            scrollWheelZoom={false}
            viewport={this.state.viewport}
          >
            {/* <TileLayer
            attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          /> */}
            <GoogleLayer googlekey={GOOGLE_API_KEY} maptype={'SATELLITE'} />
            <CircleMarker
              center={[estate.latitude, estate.longitude]}
              radius={5}
            ></CircleMarker>
          </Map>
        )}
      </div>
    )
  }
}
