import React from 'react'
import { Link } from 'react-router-dom'
import { withPasswordRecover } from 'eazy-auth'
// import './Login/Login.scss'

const Recover = ({
  recoverEmail,
  onRecoverEmailChange,
  onSubmitRecoverPassword,
  recoverLoading,
  recovered,
  recoverError,
}) => (
  <div className="d-flex align-items-center justify-content-center mt-5">
    <div className={'d-flex flex-direction-column'}>
      {recovered ? (
        <div className="alert alert-success mt-2">
          Ok! Abbiamo appena inviato una mail a <b>{recoverEmail}</b>. Torna
          alla pagina{' '}
          <Link to="/login">
            <b>login</b>
          </Link>
        </div>
      ) : (
        <form className="form-signin" onSubmit={onSubmitRecoverPassword}>
          {/* <div className="text-center mb-2">
            <img className="mb-3" src="https://getbootstrap.com/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72" />
            <h1 className="h3 mb-3 font-weight-normal">IRRISMART</h1>
            <p>Inserisci la tua mail per recuperare la password</p>
          </div> */}

          <div className="text-center mb-2">
            <img
              className="mb-3"
              src="IrriSmart_Logo_CMYK.svg"
              alt="logo"
              width="200"
            />
            {/* <h1 className="h3 mb-3 font-weight-normal">IRRISMART</h1>
            <p>Irrigation management system</p> */}
          </div>

          <div className="form-group">
            <input
              type="email"
              id="inputEmail"
              className="form-control"
              placeholder="Email address"
              required
              autofocus
              value={recoverEmail}
              onChange={onRecoverEmailChange}
            />
            <label for="inputEmail">Indirizzo Email</label>
          </div>
          <button
            className="btn btn-lg btn-primary btn-block"
            type="submit"
            disabled={recoverLoading}
          >
            Recupera password
          </button>
          {recoverError && (
            <div className="alert alert-danger mt-3">
              Inserisci un indirizzo mail valido
            </div>
          )}
          <small>
            Torna alla pagina{' '}
            <Link to="/login" className="text-primary link">
              <b>login</b>
            </Link>
          </small>
          <p class="mt-4 mb-3 text-muted text-center">&copy; 2018</p>
        </form>
      )}
    </div>
  </div>

  // <div className='row mt-4'>
  //   <div className='col-md-4 offset-md-4'>
  //   {recovered
  //     ? (
  //       <div>Ok! Email sent to {recoverEmail} go to <Link to='/login'>login</Link> </div>
  //     )
  //     : (
  //       <form style={{ padding: 50 }} onSubmit={onSubmitRecoverPassword}>
  //         <Input
  //           type='text'
  //           value={recoverEmail}
  //           onChange={onRecoverEmailChange}
  //         />
  //         <div>
  //           <Button disabled={recoverLoading}>Recover</Button>
  //         </div>
  //         {recoverError && <div>Bad email</div>}
  //       </form>
  //     )
  //   }
  // </div>
  // </div>
)

export default withPasswordRecover()(Recover)
