import React from 'react'
import { DraggableCore } from 'react-draggable'
import moment from 'moment'
import memoizeOne from 'memoize-one'
import { scaleTime } from 'd3-scale'

export default class Brush extends React.PureComponent {
  onDragRectLeft = (e, data, scale) => {
    const { minDate, maxDate, dateRange } = this.props
    const xMinDate = scale(minDate) + data.deltaX
    const newMinDate = moment(scale.invert(xMinDate))
    const finalMinDate =
      newMinDate.isBefore(moment(dateRange[0])) &&
      newMinDate.isBefore(moment(maxDate))
        ? moment(minDate)
        : newMinDate
    this.props.dragCallbackLeft && this.props.dragCallbackLeft(finalMinDate)
  }

  onDragRectRight = (e, data, scale) => {
    const { minDate, maxDate, dateRange } = this.props
    const xMaxDate = scale(maxDate) + data.deltaX
    const newMaxDate = moment(scale.invert(xMaxDate))
    const finalMaxDate =
      newMaxDate.isAfter(moment(dateRange[1])) &&
      newMaxDate.isAfter(moment(minDate))
        ? moment(maxDate)
        : newMaxDate
    this.props.dragCallbackRight && this.props.dragCallbackRight(finalMaxDate)
  }

  onDragRectCenter = (e, data, scale) => {
    const { minDate, maxDate, dateRange } = this.props
    const xMinDate = scale(minDate) + data.deltaX
    const xMaxDate = scale(maxDate) + data.deltaX
    const newMinDate = moment(scale.invert(xMinDate))
    const newMaxDate = moment(scale.invert(xMaxDate))
    const finalMaxDate =
      newMaxDate.isAfter(moment(dateRange[1])) ||
      newMinDate.isBefore(moment(dateRange[0]))
        ? moment(maxDate)
        : newMaxDate
    const finalMinDate =
      newMinDate.isBefore(moment(dateRange[0])) ||
      newMaxDate.isAfter(moment(dateRange[1]))
        ? moment(minDate)
        : newMinDate
    this.props.dragCallbackCenter &&
      this.props.dragCallbackCenter(finalMinDate, finalMaxDate)
  }

  getScale = memoizeOne((dateRange, width, margin) => {
    return scaleTime()
      .range([margin.left, width - margin.right])
      .domain(dateRange)
  })

  render() {
    const {
      minDate,
      maxDate,
      dateRange,
      height,
      width,
      margin,
      chartPadding,
    } = this.props
    const chartHeight = height - margin.top - margin.bottom - chartPadding
    const xScale = this.getScale(dateRange, width, margin)

    return (
      <g>
        {dateRange && dateRange[0] && dateRange[1] && (
          <React.Fragment>
            <DraggableCore
              handle=".handle"
              axis="x"
              onDrag={(e, data) => this.onDragRectLeft(e, data, xScale)}
            >
              <rect
                className="handle ew-resize"
                height={30}
                x={xScale(minDate) - 7}
                fill="#fff"
                stroke="#222"
                y={margin.top + (chartHeight + chartPadding) / 2 - 15}
                width={6}
              ></rect>
            </DraggableCore>

            <DraggableCore
              handle=".handle"
              axis="x"
              onDrag={(e, data) => this.onDragRectCenter(e, data, xScale)}
            >
              <rect
                className="handle move"
                width={xScale(maxDate) - xScale(minDate)}
                x={xScale(this.props.minDate)}
                fill={'#444'}
                stroke={'#fff'}
                fillOpacity={0.15}
                y={margin.top - 10}
                height={chartHeight + chartPadding + 10}
              ></rect>
            </DraggableCore>

            <DraggableCore
              handle=".handle"
              axis="x"
              onDrag={(e, data) => this.onDragRectRight(e, data, xScale)}
            >
              <rect
                className="handle ew-resize"
                height={30}
                x={xScale(maxDate) + 1}
                fill="#fff"
                stroke="#222"
                y={margin.top + (chartHeight + chartPadding) / 2 - 15}
                width={6}
              ></rect>
            </DraggableCore>
          </React.Fragment>
        )}
      </g>
    )
  }
}
