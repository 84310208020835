import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import { middleware as thunkMiddleware } from 'redux-saga-thunk'
import { i18nState } from 'redux-i18n'
import { makeAppsReducers, makeAppsSaga } from 'redux-rocketjump'
import * as auth from './auth'
import * as password from './password'
import * as estates from './estates'
import * as fields from './fields'
import * as sectors from './sectors'
import * as providers from './providers'
import * as nodeProviders from './nodeProviders'
import * as profile from './profile'
import * as notification from './notification'
import * as languages from './languages'
import { uiReducer } from './ui'
import { irrigationEditReducer } from './irrigationEdit'

const APPS = {
  auth,
  password,
  estates,
  fields,
  sectors,
  providers,
  nodeProviders,
  profile,
  notification,
  languages,
}

const rootReducer = combineReducers({
  // HOOK for other reducers like redux-form...
  i18nState,
  form: formReducer,
  ui: uiReducer,
  irrigationEdit: irrigationEditReducer,
  ...makeAppsReducers(APPS),
})

const mainSaga = makeAppsSaga(APPS)

const preloadedState = undefined
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  preloadedState,
  composeEnhancers(applyMiddleware(thunk, thunkMiddleware, sagaMiddleware))
)

sagaMiddleware.run(mainSaga)

export default store
