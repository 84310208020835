import React, { PureComponent } from 'react'
import { localize } from '../localize'
import Layout from '../components/Layout'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getEstate } from '../state/estates'
import {
  makeGetNodeProvider,
  makeGetNodeProviderLoading,
  loadNodeProvidersDict,
  unloadNodeProvidersDict,
} from '../state/nodeProviders'
import { SensorNodeChart } from '../components/SoilProviderChart'
import get from 'lodash/get'
import find from 'lodash/find'
import { Link } from 'react-router-dom'
import qs from 'query-string'

class SensorNode extends PureComponent {
  render() {
    const { t, estate, match, node } = this.props
    const { params } = match

    if (!estate) {
      return null
    }

    const sensor_nodes = get(estate, 'hardware.sensor_nodes')
    const nodeData = find(
      sensor_nodes,
      item => item.id === parseInt(params.sensorNodeId, 10)
    )

    return (
      <Layout>
        <Layout.Content className="container-fluid h-100">
          <div className="mt-3">
            <h5>
              <Link to={`/estates`} className="text-muted">
                {t('estates')} /
              </Link>{' '}
              <Link
                to={`/estates/${estate.id}?tabName=configuration`}
                className="text-muted"
              >
                {estate.name} /
              </Link>{' '}
              {t('Node')} {nodeData.name}
            </h5>
          </div>

          <div className="my-3">
            {nodeData && (
              <SensorNodeChart showTitle={true} nodeProvider={nodeData} />
            )}
            {!nodeData && (
              <div className="alert alert-warning mt-2">
                {t('sensorNodeNotFound')}
              </div>
            )}
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

const makeMapStateToProps = () => {
  const mapStateToProps = (state, props) => {
    const getNodeProvider = makeGetNodeProvider()
    const getNodeProviderLoading = makeGetNodeProviderLoading()
    const { match } = props
    const { params } = match

    return {
      node: params.sensorNodeId
        ? getNodeProvider(state, params.sensorNodeId)
        : null,
      nodeLoading: params.sensorNodeId
        ? getNodeProviderLoading(state, params.sensorNodeId)
        : null,
      estate: getEstate(state),
    }
  }
  return mapStateToProps
}

export default withRouter(
  connect(
    makeMapStateToProps,
    {
      loadNodeProvidersDict,
      unloadNodeProvidersDict,
    }
  )(localize()(SensorNode))
)
