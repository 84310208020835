import React from 'react'
import memoizeOne from 'memoize-one'
import ReactDOM from 'react-dom'
import { localize } from '../localize'
import { connect } from 'react-redux'
import moment from 'moment'
import { Group } from '@vx/group'
import { AxisBottom, AxisLeft, AxisRight } from '@vx/axis'
import { LinePath, AreaClosed, Bar } from '@vx/shape'
// import { curveNatural } from '@vx/curve'
import { GlyphDot } from '@vx/glyph'
import { scaleTime, scaleLinear } from 'd3-scale'
import { Text } from '@vx/text'
import { timeFormat } from 'd3-time-format'
import {
  get,
  keys,
  keyBy,
  merge,
  values,
  omit,
  chunk,
  debounce,
  meanBy,
  sortBy,
  first,
  last,
  find,
} from 'lodash'
import { extent } from 'd3-array'
import FileSaver from 'file-saver'
import domtoimage from 'dom-to-image'
import Brush from './Brush'
import ChartLegend from './ChartLegend'
import { getDayMonthTimeUserFormat, getNumberFormat } from '../state/languages'
import { UncontrolledTooltip } from 'reactstrap'

const margin = {
  top: 50,
  left: 100,
  bottom: 50,
  right: 100,
}
const chartPadding = 10

class Chart_ extends React.PureComponent {
  state = {
    guideX: null,
    guideY: null,
    guideYTemp: null,
  }

  resetGuides = () => {
    this.setState({
      guideX: null,
      guideY: null,
      guideYTemp: null,
      guideYSolarRad: null,
      guideYHumidity: null,
      guideYRain: null,
      guideYWindSpeed: null,
    })
  }

  setGuides = (d, e) => {
    this.setState({
      guideX: d.date,
      guideY: e,
      guideYTemp: d.temperature,
      guideYHumidity: d.humidity,
      guideYSolarRad: d.solar_rad,
      guideYRain: d.rain,
      guideYRainEvent: d.rainEvent,
      guideYWindSpeed: d.wind_speed,
    })
  }

  isCurrentX = d => {
    return (
      this.state.guideX && d.date.toString() === this.state.guideX.toString()
    )
  }

  mouseEnter = e => {
    const { data, simulation } = this.props

    if (e.target.dataset && e.target.dataset.index) {
      const dataIndex = e.target.dataset.index
      const dataY = e.target.dataset.y
      const dataDateMoment = moment(data[dataIndex].date)
      const rainEvent = find(
        simulation.rainPeriods,
        item =>
          item.start.isSameOrBefore(dataDateMoment) &&
          item.end.isSameOrAfter(dataDateMoment)
      )
      this.setGuides({ ...data[dataIndex], rainEvent }, dataY)
    }
  }

  getRanges = memoizeOne(data => {
    const dateRange = extent(data, x => x.date)
    const tempRange = extent(data, x => x.temperature)
    const tempDelta = tempRange[1] - tempRange[0]
    const tempMargin = tempDelta / 10

    const rainRange = extent(data, x => x.rain)
    const rainMax = rainRange[1] + 5
    const windSpeedRange = extent(data, x => x.wind_speed)
    const windSpeedMax = windSpeedRange[1] + 1

    const solarRadRange = extent(data, x => x.solar_rad)
    const solarRadMax = solarRadRange[1] + solarRadRange[1] * 0.1

    return {
      dateRange,
      tempRange,
      tempMargin,
      rainRange,
      rainMax,
      windSpeedRange,
      windSpeedMax,
      solarRadRange,
      solarRadMax,
    }
  })

  render() {
    const {
      simulation,
      width,
      height,
      showVariables,
      data,
      mini = false,
      t,
      numberFormat,
    } = this.props

    const units = {
      temperature: get(simulation, 'data.temperature.unit_of_measure'),
      humidity: get(simulation, 'data.rel_humidity.unit_of_measure'),
      rain: get(simulation, 'data.rain.unit_of_measure'),
      solarRad: get(simulation, 'data.solar_rad.unit_of_measure'),
      windSpeed: get(simulation, 'data.wind_speed.unit_of_measure'),
    }

    const colors = {
      temperature: get(simulation, 'data.temperature.color'),
      humidity: get(simulation, 'data.rel_humidity.color'),
      rain: get(simulation, 'data.rain.color'),
      solarRad: get(simulation, 'data.solar_rad.color'),
      windSpeed: get(simulation, 'data.wind_speed.color'),
    }

    const chartHeight = height - margin.top - margin.bottom - chartPadding
    const hasAnyUpper =
      showVariables.temperature ||
      showVariables.wind_speed ||
      showVariables.humidity ||
      showVariables.solar_rad
    const bottomHeight = hasAnyUpper ? (chartHeight / 5) * 2 : chartHeight
    const topHeight = hasAnyUpper ? (chartHeight / 5) * 3 : 0

    const {
      dateRange,
      tempRange,
      tempMargin,
      rainMax,

      //rainRange, windSpeedRange, solarRadRange,
      windSpeedMax,
      solarRadMax,
    } = this.getRanges(data)

    const xScale = scaleTime()
      .range([margin.left, width - margin.right])
      .domain(dateRange)

    const yScaleSolarRad = scaleLinear()
      .range([margin.top + topHeight, margin.top])
      .domain([0, solarRadMax])

    const yScaleTemperature = scaleLinear()
      .range([margin.top + topHeight, margin.top])
      .domain([tempRange[0] - tempMargin, tempRange[1] + tempMargin])

    const yScaleHumidity = scaleLinear()
      .range([margin.top + topHeight, margin.top])
      .domain([0, 100])

    const yScaleRain = scaleLinear()
      .range([height - margin.bottom, height - margin.bottom - bottomHeight])
      .domain([0, rainMax])

    const yScaleRainHeight = scaleLinear()
      .range([0, bottomHeight])
      .domain([0, rainMax])

    const yScaleRainHeightInverse = scaleLinear()
      .range([bottomHeight, 0])
      .domain([0, rainMax])

    const yScaleWindSpeed = scaleLinear()
      .range([margin.top + topHeight, margin.top])
      .domain([0, windSpeedMax])

    // const yScaleWindSpeedHeightInverse = scaleLinear()
    //   .range([bottomHeight, 0])
    //   .domain([0, windSpeedMax])

    // const firstHour = timeHour.offset(dateRange[0])
    let barWidth
    const dates = data.map(i => i.date).sort()
    if (dates.length > 1) {
      barWidth = xScale(dates[1]) - xScale(dates[0])
    } else {
      // one data point only ..
      barWidth = width - margin.left + margin.right
    }

    const innerBarWidth = barWidth - 1

    return (
      <React.Fragment>
        <Group
          onMouseLeave={mini ? undefined : this.resetGuides}
          onMouseEnter={mini ? undefined : this.mouseEnter}
          onMouseMove={mini ? undefined : this.mouseEnter}
        >
          {!mini && !hasAnyUpper && !showVariables.rain && (
            <Text
              textAnchor="middle"
              verticalAnchor="middle"
              y={chartHeight / 2}
              x={width / 2}
            >
              {t('pleaseSelectAtLeast1Var')}
            </Text>
          )}
          {!showVariables.rain && hasAnyUpper && (
            <AxisBottom
              scale={xScale}
              top={margin.top + topHeight}
              stroke={'#1b1a1e'}
              tickTextFill={'#1b1a1e'}
              // # todo:fix me
              tickFormat={this.props.dayMonthTimeFormat}
            />
          )}

          {showVariables.rain && (
            <AxisBottom
              scale={xScale}
              top={height - margin.bottom}
              stroke={'#1b1a1e'}
              tickTextFill={'#1b1a1e'}
              tickFormat={this.props.dayMonthTimeFormat}
            />
          )}

          {!mini && showVariables.humidity && (
            <AxisLeft
              scale={yScaleHumidity}
              left={margin.left - 20}
              label={`${t('humidity')} [${units.humidity}]`}
              labelOffset={20}
              stroke={'#1b1a1e'}
              tickLength={4}
              tickTextFill={'#1b1a1e'}
              tickFormat={i => i}
              numTicks={mini ? 2 : undefined}
            />
          )}

          {!mini && showVariables.temperature && (
            <AxisRight
              scale={yScaleTemperature}
              left={width - margin.right + 20}
              label={`${t('temperature')} [${units.temperature}]`}
              labelOffset={showVariables.solar_rad ? 20 : undefined}
              stroke={'#1b1a1e'}
              tickTextFill={'#1b1a1e'}
              tickFormat={i => i}
              numTicks={mini ? 2 : undefined}
            />
          )}

          {!mini && showVariables.wind_speed && (
            <AxisLeft
              scale={yScaleWindSpeed}
              left={margin.left - 60}
              label={`${t('windSpeed')} [${units.windSpeed}]`}
              labelOffset={20}
              tickLength={4}
              stroke={'#1b1a1e'}
              tickTextFill={'#1b1a1e'}
              tickFormat={i => i}
              numTicks={mini ? 2 : undefined}
            />
          )}

          {!mini && showVariables.solar_rad && (
            <AxisRight
              scale={yScaleSolarRad}
              left={
                showVariables.temperature
                  ? width - margin.right + 60
                  : width - margin.right + 20
              }
              label={`${t('solarRadiation')} [${units.solarRad}]`}
              labelOffset={showVariables.temperature ? 28 : undefined}
              stroke={'#1b1a1e'}
              tickLength={4}
              tickTextFill={'#1b1a1e'}
              tickFormat={i => i}
              numTicks={mini ? 2 : undefined}
            />
          )}

          {!mini && showVariables.rain && (
            <AxisRight
              scale={yScaleRainHeightInverse}
              top={height - margin.bottom - bottomHeight}
              left={width - margin.right + 20}
              label={`${t('rain')} [${units.rain}]`}
              stroke={'#1b1a1e'}
              tickTextFill={'#1b1a1e'}
              tickFormat={numberFormat}
              numTicks={mini ? 2 : undefined}
            />
          )}

          {/* guides */}
          {!mini && (
            <g>
              {this.state.guideX && (
                <g>
                  <line
                    x1={xScale(this.state.guideX)}
                    x2={xScale(this.state.guideX)}
                    y1={margin.top}
                    y2={height - margin.bottom}
                    className="chart-guide"
                  ></line>

                  {/* <rect x={xScale(this.state.guideX) - barWidth/2} y={margin.top}
            className='chart-guide-band'
            width={barWidth} height={height-margin.top}>
          </rect>*/}

                  {showVariables.temperature && this.state.guideYTemp && (
                    <g>
                      <line
                        y1={yScaleTemperature(this.state.guideYTemp)}
                        y2={yScaleTemperature(this.state.guideYTemp)}
                        x1={xScale(this.state.guideX)}
                        x2={width - margin.right + 20}
                        className="chart-guide"
                      ></line>
                      <circle
                        cy={yScaleTemperature(this.state.guideYTemp)}
                        className="temperature-fill"
                        r={4}
                        cx={width - margin.right + 20}
                      >
                        {' '}
                      </circle>
                    </g>
                  )}
                  {showVariables.humidity && this.state.guideYHumidity && (
                    <g>
                      <line
                        y1={yScaleHumidity(this.state.guideYHumidity)}
                        y2={yScaleHumidity(this.state.guideYHumidity)}
                        x1={xScale(this.state.guideX)}
                        x2={margin.left - 20}
                        className="chart-guide"
                      ></line>
                      <circle
                        cy={yScaleHumidity(this.state.guideYHumidity)}
                        className="humidity-fill"
                        r={4}
                        cx={margin.left - 20}
                      >
                        {' '}
                      </circle>
                    </g>
                  )}
                  {showVariables.solar_rad && this.state.guideYSolarRad && (
                    <g>
                      <line
                        y1={yScaleSolarRad(this.state.guideYSolarRad)}
                        y2={yScaleSolarRad(this.state.guideYSolarRad)}
                        x1={xScale(this.state.guideX)}
                        x2={
                          showVariables.temperature
                            ? width - margin.right + 60
                            : width - margin.right + 20
                        }
                        className="chart-guide"
                      ></line>
                      <circle
                        cy={yScaleSolarRad(this.state.guideYSolarRad)}
                        className="solar-rad-fill"
                        r={4}
                        cx={
                          showVariables.temperature
                            ? width - margin.right + 60
                            : width - margin.right + 20
                        }
                      >
                        {' '}
                      </circle>
                    </g>
                  )}
                  {showVariables.rain && this.state.guideYRain && (
                    <g>
                      <line
                        y1={yScaleRain(this.state.guideYRain)}
                        y2={yScaleRain(this.state.guideYRain)}
                        x1={xScale(this.state.guideX)}
                        x2={width - margin.right + 20}
                        className="chart-guide"
                      ></line>
                      <circle
                        cy={yScaleRain(this.state.guideYRain)}
                        className="rain-fill"
                        r={4}
                        cx={width - margin.right + 20}
                      >
                        {' '}
                      </circle>
                    </g>
                  )}
                  {showVariables.wind_speed && this.state.guideYWindSpeed && (
                    <g>
                      <line
                        y1={yScaleWindSpeed(this.state.guideYWindSpeed)}
                        y2={yScaleWindSpeed(this.state.guideYWindSpeed)}
                        x1={xScale(this.state.guideX)}
                        x2={margin.left - 60}
                        className="chart-guide"
                      ></line>
                      <circle
                        cy={yScaleWindSpeed(this.state.guideYWindSpeed)}
                        className="wind-speed-fill"
                        r={4}
                        cx={margin.left - 60}
                      >
                        {' '}
                      </circle>
                    </g>
                  )}
                </g>
              )}
            </g>
          )}

          {/* solar rad area */}
          {showVariables.solar_rad && (
            <Group>
              <AreaClosed
                data={data}
                xScale={xScale}
                yScale={yScaleSolarRad}
                // curve={curveNatural}
                y={i => i.solar_rad}
                x={i => i.date}
                stroke={'none'}
                fill={colors.solarRad}
                fillOpacity={0.5}
              />
              <Group>
                {data.map((d, i) => (
                  <g key={i}>
                    <GlyphDot
                      className={`glyph-dots ${
                        this.isCurrentX(d)
                          ? 'solar-rad-fill'
                          : 'transparent-fill'
                      }`}
                      key={`line-dot-${i}-solar-rad`}
                      cx={xScale(d.date)}
                      cy={yScaleSolarRad(d.solar_rad)}
                      r={4}
                      data-index={i}
                      data-y={yScaleSolarRad(d.humidity)}
                    />
                  </g>
                ))}
              </Group>
            </Group>
          )}

          {/* humidity line */}
          {showVariables.humidity && (
            <LinePath
              data={data}
              xScale={xScale}
              yScale={yScaleHumidity}
              // curve={curveNatural}
              y={i => i.humidity}
              x={i => i.date}
              stroke={colors.humidity}
              strokeWidth={2}
              glyph={(d, i) => {
                return (
                  <GlyphDot
                    className={`glyph-dots ${
                      this.isCurrentX(d) ? 'humidity-fill' : 'transparent-fill'
                    }`}
                    key={`line-dot-${i}-humidity`}
                    cx={xScale(d.date)}
                    cy={yScaleHumidity(d.humidity)}
                    r={4}
                    data-index={i}
                    data-y={yScaleHumidity(d.humidity)}
                    // onMouseEnter={(e)=>{
                    //   this.setGuides(d, yScaleHumidity(d.humidity))
                    // }}
                  />
                )
              }}
            />
          )}

          {/* temperature line */}
          {showVariables.temperature && (
            <LinePath
              data={data}
              xScale={xScale}
              yScale={yScaleTemperature}
              // curve={curveNatural}
              y={i => i.temperature}
              x={i => i.date}
              stroke={colors.temperature}
              strokeWidth={2}
              glyph={(d, i) => {
                return (
                  <GlyphDot
                    className={`glyph-dots ${
                      this.isCurrentX(d)
                        ? 'temperature-fill'
                        : 'transparent-fill'
                    }`}
                    key={`line-dot-${i}-temperature`}
                    cx={xScale(d.date)}
                    cy={yScaleTemperature(d.temperature)}
                    r={4}
                    data-index={i}
                    data-y={yScaleTemperature(d.temperature)}
                    // onMouseEnter={(e)=>{
                    //   this.setGuides(d, yScaleTemperature(d.temperature))
                    // }}
                  />
                )
              }}
            />
          )}

          {/* wind speed line */}
          {showVariables.wind_speed && (
            <Group top={0}>
              <LinePath
                data={data}
                xScale={xScale}
                yScale={yScaleWindSpeed}
                // curve={curveNatural}
                y={i => i.wind_speed}
                x={i => i.date}
                stroke={colors.windSpeed}
                strokeWidth={2}
                glyph={(d, i) => {
                  return (
                    <GlyphDot
                      className={`glyph-dots ${
                        this.isCurrentX(d)
                          ? 'wind-speed-fill'
                          : 'transparent-fill'
                      }`}
                      key={`line-dot-${i}-wind-speed'`}
                      cx={xScale(d.date)}
                      cy={yScaleWindSpeed(d.wind_speed)}
                      r={4}
                      data-index={i}
                      data-y={yScaleWindSpeed(d.humidity)}
                      // onMouseEnter={(e)=>{
                      //   this.setGuides(d, yScaleWindSpeed(d.wind_speed))
                      // }}
                    />
                  )
                }}
              />
            </Group>
          )}

          {/* rain bars */}
          {showVariables.rain && (
            <Group>
              {data.map((d, i) => (
                <g
                  key={i}
                  onMouseEnter={e => {
                    this.setGuides(d, yScaleRain(d.rain))
                  }}
                >
                  <Bar
                    width={Math.max(innerBarWidth, 0)}
                    height={yScaleRainHeight(d.rain)}
                    x={xScale(d.date) - barWidth / 2}
                    y={yScaleRain(d.rain)}
                    fill={colors.rain}
                    stroke={
                      this.state.guideX &&
                      d.date.toString() === this.state.guideX.toString()
                        ? 'black'
                        : 'none'
                    }
                    strokeWidth={2}
                    data-index={i}
                    data-y={yScaleRain(d.rain)}
                  ></Bar>
                </g>
              ))}
            </Group>
          )}

          {!mini && (
            <ChartLegend
              showVariables={showVariables}
              width={width}
              top={5}
              colors={colors}
            />
          )}

          {/* tooltip */}
        </Group>
        {this.state.guideX && (
          <Group
            top={
              this.state.guideY < chartHeight / 2
                ? this.state.guideY + 65
                : this.state.guideY - 120
            }
            left={Math.max(0, xScale(this.state.guideX) - 100)}
          >
            <rect
              style={{ fill: '#efefef', stroke: '#ccc' }}
              x={0}
              y={0}
              width={200}
              height={90}
              rx="4"
              ry="4"
            ></rect>
            <text className="chart-guide-label" y={16}>
              <tspan className="chart-guide-label-big" x={100}>
                {timeFormat('%a %m/%d %H:%M')(this.state.guideX)}
              </tspan>
              <tspan x={100} dy={14}>
                {t('temperature')}: {numberFormat(this.state.guideYTemp)}°C{' '}
                {t('humidity')}: {numberFormat(this.state.guideYHumidity)}%
              </tspan>
              <tspan x={100} dy={14}>
                {t('solarRadiation')}: {numberFormat(this.state.guideYSolarRad)}
              </tspan>
              <tspan x={100} dy={14}>
                {t('windSpeed')}: {numberFormat(this.state.guideYWindSpeed)}{' '}
              </tspan>
              <tspan x={100} dy={14}>
                {t('rain')}: {numberFormat(this.state.guideYRain)}
              </tspan>
              {!!this.state.guideYRainEvent && (
                <tspan x={100} dy={14}>
                  {t('rainCumulated')}:{' '}
                  {numberFormat(this.state.guideYRainEvent.value)} {units.rain}{' '}
                  ({numberFormat(this.state.guideYRainEvent.duration)}{' '}
                  {t('hours')})
                </tspan>
              )}
            </text>
          </Group>
        )}
      </React.Fragment>
    )
  }
}

export const Chart = connect(state => ({
  dayMonthTimeFormat: getDayMonthTimeUserFormat(state),
  numberFormat: getNumberFormat(state),
}))(localize()(Chart_))

class ChartWrapper_ extends React.PureComponent {
  state = {
    downloadingPng: false,
  }

  downloadPng = e => {
    this.setState({ downloadingPng: true })
    const { chartLabel } = this.props
    var node = ReactDOM.findDOMNode(this)
    function filter(node) {
      return node.tagName !== 'I'
    }

    domtoimage.toBlob(node, { bgcolor: '#fff', filter: filter }).then(blob => {
      FileSaver.saveAs(blob, `${chartLabel}.png`)
      this.setState({ downloadingPng: false })
    })
  }

  render() {
    const {
      minDate,
      maxDate,
      dragCallbackLeft,
      dragCallbackRight,
      dragCallbackCenter,
      dateRange,
      height,
      width,
      mini,
      ...passProps
    } = this.props

    const { stationId, t } = this.props
    const { downloadingPng } = this.state

    return (
      <div className="chart-container">
        {!mini && (
          <React.Fragment>
            <i
              alt="xxx"
              className={`text-muted pointer float-right fa ${
                downloadingPng ? 'fa-spinner fa-spin' : 'fa-download'
              } download-button`}
              id={`stat-${stationId}`}
              onClick={() => this.downloadPng()}
            ></i>
            <UncontrolledTooltip placement="right" target={`stat-${stationId}`}>
              {t('downloadChart')}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        <svg
          ref={node => (this.chartNode = node)}
          width={width}
          height={height}
          className="chart"
          style={{ position: 'relative' }}
        >
          <Chart
            {...passProps}
            dateRange={dateRange}
            height={height}
            width={width}
            mini={mini}
          />
          {this.props.mini && (
            <Brush
              dateRange={dateRange}
              minDate={minDate}
              maxDate={maxDate}
              dragCallbackLeft={dragCallbackLeft}
              dragCallbackRight={dragCallbackRight}
              dragCallbackCenter={dragCallbackCenter}
              margin={margin}
              chartPadding={chartPadding}
              height={height}
              width={width}
            />
          )}
        </svg>
      </div>
    )
  }
}

export const ChartWrapper = connect()(localize()(ChartWrapper_))

class MeteoVxWrapper extends React.PureComponent {
  state = {
    minDate: null,
    maxDate: null,
  }

  computeDataState = memoizeOne(simulation => {
    const temperature = get(simulation, 'data.temperature.values', [])
    const humidity = get(simulation, 'data.rel_humidity.values', [])
    const rain = get(simulation, 'data.rain.values', [])
    const solarRad = get(simulation, 'data.solar_rad.values', [])
    const windSpeed = get(simulation, 'data.wind_speed.values', [])

    const tk = keyBy(
      temperature.map(i => ({ ...i, temperature: i.value })),
      'date'
    )
    const hk = keyBy(humidity.map(i => ({ ...i, humidity: i.value })), 'date')
    const rk = keyBy(rain.map(i => ({ ...i, rain: i.value })), 'date')
    const sk = keyBy(solarRad.map(i => ({ ...i, solar_rad: i.value })), 'date')
    const wk = keyBy(
      windSpeed.map(i => ({ ...i, wind_speed: i.value })),
      'date'
    )

    let data = values(merge(tk, hk, rk, sk, wk)).map(x => ({
      ...x,
      date: moment(x.date).toDate(),
    }))
    data = sortBy(data.map(i => omit(i, 'value')), 'date')
    const dateRange = extent(data, x => x.date)
    //const minDateForDetail = moment(dateRange[1]).subtract(2, 'days')
    //const maxDateForDetail = moment(dateRange[1])
    // initialy center the chart to current date instead of last 2 days if today is inside data range
    const momentFrom = moment(dateRange[0])
    const momentTo = moment(dateRange[1])
    const today = moment();
    if (today.isBefore(momentTo) && today.isAfter(momentFrom)) {
        const newMin = moment().subtract(1, 'days') //moment(dateRange[1]).subtract(2, 'days')
        const newMax = moment().add(1, 'days') //moment(dateRange[1])
        const minDateForDetail = newMin.isAfter(momentFrom) ? newMin : momentFrom
        const maxDateForDetail = newMax.isBefore(momentTo) ? newMax : momentTo
        return { data, dateRange, minDateForDetail, maxDateForDetail }
    }
    else {
        const minDateForDetail = moment(dateRange[1]).subtract(2, 'days')
        const maxDateForDetail = moment(dateRange[1])
        return { data, dateRange, minDateForDetail, maxDateForDetail }
    }
  })

  computeMiniData = memoizeOne(data => chunk(data, 10).map(this.makeChunkDatum))

  componentDidMount() {
    const { simulation } = this.props
    const {
      data,
      dateRange,
      minDateForDetail,
      maxDateForDetail,
    } = this.computeDataState(simulation)
    this.setState({ data, dateRange, minDateForDetail, maxDateForDetail })
  }

  componentDidUpdate(oldProps) {
    if (oldProps.width !== this.props.width) {
      const { data, minDateForDetail, maxDateForDetail } = this.state
        //let shortData = data.filter(x => moment(x.date).isAfter(minDateForDetail))
      let shortData = data.filter(x => moment(x.date).isAfter(minDateForDetail) && moment(x.date).isBefore(maxDateForDetail))
      const dataRatio = Math.ceil(shortData.length / this.props.width)
      if (dataRatio > 1) {
        shortData = chunk(shortData, dataRatio).map(this.makeChunkDatum)
      }
      this.setState({ shortData })
    }
    if (oldProps.simulation !== this.props.simulation) {
      const { simulation } = this.props
      const {
        data,
        dateRange,
        minDateForDetail,
        maxDateForDetail,
      } = this.computeDataState(simulation)
      let shortData = data.filter(x => moment(x.date).isAfter(minDateForDetail))
      const dataRatio = Math.ceil(shortData.length / this.props.width)
      if (dataRatio > 1) {
        shortData = chunk(shortData, dataRatio).map(this.makeChunkDatum)
      }
      this.setState({
        data,
        dateRange,
        minDateForDetail,
        maxDateForDetail,
        shortData,
      })
    }
  }

  updateShortData = debounce((minDate, maxDate) => {
    const { data } = this.state
    let shortData = data.filter(
      x => moment(x.date).isAfter(minDate) && moment(x.date).isBefore(maxDate)
    )
    const dataRatio = Math.ceil(shortData.length / this.props.width)
    if (dataRatio > 1) {
      shortData = chunk(shortData, dataRatio).map(this.makeChunkDatum)
    }
    this.setState({ shortData })
  }, 180)

  dragCallbackLeft = minDate => {
    const { maxDateForDetail } = this.state
    this.setState({ minDateForDetail: minDate })
    this.updateShortData(minDate, maxDateForDetail)
  }

  dragCallbackRight = maxDate => {
    const { minDateForDetail } = this.state
    this.setState({ maxDateForDetail: maxDate })
    this.updateShortData(minDateForDetail, maxDate)
  }

  dragCallbackCenter = (minDate, maxDate) => {
    this.setState({ minDateForDetail: minDate, maxDateForDetail: maxDate })
    this.updateShortData(minDate, maxDate)
  }

  makeChunkDatum = chunk => {
    const d = first(chunk)
    const lastD = last(chunk)
    const datesDiff = moment(lastD.date).diff(moment(d.date))
    const midDate = moment(d.date).add(datesDiff / 2, 'milliseconds')

    const k = keys(d)
    let out = {
      type: d.type,
      date: midDate.toDate(),
    }
    k.filter(x => ['date', 'type'].indexOf(x) === -1).forEach(x => {
      out[x] = meanBy(chunk, x)
    })
    return out
  }

  render() {
    if (!this.props.width || !this.state.data) {
      return null
    }
    const { height, ...passProps } = this.props

    const {
      data,
      dateRange,
      minDateForDetail,
      maxDateForDetail,
      shortData,
    } = this.state
    const miniData = this.computeMiniData(data)

    return (
      <div>
        {shortData && (
          <ChartWrapper data={shortData} height={height - 200} {...passProps} />
        )}
        <ChartWrapper
          mini={true}
          data={miniData}
          minDate={minDateForDetail.toDate()}
          maxDate={maxDateForDetail.toDate()}
          height={200}
          {...passProps}
          dragCallbackLeft={this.dragCallbackLeft}
          dragCallbackRight={this.dragCallbackRight}
          dragCallbackCenter={this.dragCallbackCenter}
          dateRange={dateRange}
        />
      </div>
    )
  }
}

export default MeteoVxWrapper
