import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import { Button, Form, FormGroup, Col, Label, Row } from 'reactstrap'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import {
  FieldInput,
  FieldDatepickerSplit,
  FieldCheckbox,
  required,
  number,
} from './forms'

class IrrigationPreviewForm_ extends React.PureComponent {
  render() {
    const {
      handleSubmit,
      submitting,
      t,
      appliedTo,
      useDuration,
      estateFields,
      estateSectors,
      updating = false,
    } = this.props

    return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={9}>
            <FormGroup row>
              <Label sm={4} className="textm-right">
                <b
                  className={
                    appliedTo === 'irrigation_sector' ? 'text-muted' : ''
                  }
                >
                  {t('useDuration')}
                </b>
              </Label>
              <Col sm={8}>
                <Field
                  size="sm"
                  component={FieldCheckbox}
                  name="use_duration"
                  disabled={appliedTo === 'irrigation_sector'}
                  // disabled={true}
                />
              </Col>
            </FormGroup>

            {useDuration && (
              <FormGroup row>
                <Label sm={4} className="textm-right">
                  <b>{t('durationMin')}</b>
                </Label>
                <Col sm={8}>
                  <Field
                    size="sm"
                    component={FieldInput}
                    name="duration"
                    placeholder={t('insertDurationMinutes')}
                    validate={[required, number]}
                    type="text"
                  />
                </Col>
              </FormGroup>
            )}

            {!useDuration && (
              <FormGroup row>
                <Label sm={4} className="textm-right">
                  <b>{t('amountMM')}</b>
                </Label>
                <Col sm={8}>
                  <Field
                    size="sm"
                    component={FieldInput}
                    placeholder={t('insertAmountMm')}
                    name="amount"
                    validate={[required, number]}
                    type="text"
                  />
                </Col>
              </FormGroup>
            )}
          </Col>
          <Col sm={3}>
            <div className="mb-2">
              <Button
                color="primary"
                disabled={submitting}
                className="btn-block"
              >
                {submitting ? t('saving') : t('test')}
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() =>
                  this.props.applyValues(this.props.duration, this.props.amount)
                }
                color="success"
                className="btn-block"
              >
                {t('apply')}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }
}

const selector = formValueSelector('irrigation-preview')
const mapStateToProps = state => ({
  useDuration: selector(state, 'use_duration'),
  duration: selector(state, 'duration'),
  amount: selector(state, 'amount'),
})

const IrrigationPreviewForm = reduxForm({
  form: 'irrigation-preview',
})(IrrigationPreviewForm_)

export default connect(
  mapStateToProps,
  {
    changeFieldValue: (field, value) =>
      change('irrigation-preview', field, value),
  }
)(localize()(IrrigationPreviewForm))
