import { fork } from 'redux-saga/effects'
import request from 'superagent'
import { combineReducers } from 'redux'
import { getAuthAccessToken as getToken } from 'eazy-auth'
import { createSelector } from 'reselect'
import { get, findIndex } from 'lodash'
import { authApiCall, withToken } from './auth'
import { API_URL } from './consts'
import { inferContinuousPeriods } from '../utils'
import { rj, composeReducers } from 'redux-rocketjump'
import { rjDict, rjDetail, rjIrrigationEvents, enhanceEvent } from './utils'
import rjWithPromise from 'redux-rocketjump/plugins/promise'

// Namespace for actions
const NS = '@estates/'

// List
const GET_ESTATES = `${NS}GET_ESTATES`
export const {
  actions: { load: loadEstates, unload: unloadEstates },
  selectors: { getData: getEstates, isLoading: getEstatesLoading },
  reducer: listReducer,
  saga: listSaga,
} = rj({
  type: GET_ESTATES,
  state: 'estates.list',
  effectCaller: authApiCall,
  effect: t => params =>
    withToken(t, request.get(`${API_URL}estates`))
      .query(params)
      .then(({ body }) => body),
})()

// Detail
const GET_ESTATE = `${NS}GET_ESTATE`
const TOGGLE_SENSORNODE_ON_OFF = `${NS}TOGGLE_SENSORNODE_ON_OFF`
const TOGGLE_SENSORNODE_NOTIFICATION_ON_OFF = `${NS}TOGGLE_SENSORNODE_NOTIFICATION_ON_OFF`

export const toggleSensorNodeOnOffSuccessReducer = (
  prevState,
  { type, payload }
) => {
  if (
    type === `${TOGGLE_SENSORNODE_ON_OFF}_SUCCESS` &&
    prevState.data !== null
  ) {
    const nodes = get(prevState, 'data.hardware.sensor_nodes', [])

    const nodeToPatchIndex = findIndex(
      nodes,
      node => get(node, 'id') === +payload.data
    )

    if (nodeToPatchIndex === -1) {
      return prevState
    }
    nodes[nodeToPatchIndex].is_turned_off = !nodes[nodeToPatchIndex]
      .is_turned_off

    return {
      ...prevState,
      data: {
        ...prevState.data,
        harwdware: {
          ...prevState.harwdware,
          sensor_nodes: nodes,
        },
      },
    }
  }

  if (
    type === `${TOGGLE_SENSORNODE_NOTIFICATION_ON_OFF}_SUCCESS` &&
    prevState.data !== null
  ) {
    const nodes = get(prevState, 'data.hardware.sensor_nodes', [])

    const nodeToPatchIndex = findIndex(
      nodes,
      node => get(node, 'id') === +payload.data
    )

    if (nodeToPatchIndex === -1) {
      return prevState
    }
    nodes[nodeToPatchIndex].notification_status = !!!nodes[nodeToPatchIndex]
      .notification_status

    return {
      ...prevState,
      data: {
        ...prevState.data,
        harwdware: {
          ...prevState.harwdware,
          sensor_nodes: nodes,
        },
      },
    }
  }

  return prevState
}

export const {
  actions: { load: loadEstate, unload: unloadEstate },
  selectors: { getData: getEstate, isLoading: getEstateLoading },
  reducer: detailReducer,
  saga: detailSaga,
} = rj(rjDetail(), {
  type: GET_ESTATE,
  state: 'estates.detail',
  reducer: reducer =>
    composeReducers(reducer, toggleSensorNodeOnOffSuccessReducer),
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}estates/${id}`)).then(
      ({ body }) => body
    ),
})()

// fields list
const GET_ESTATES_FIELDS = `${NS}GET_ESTATES_FIELDS`
export const {
  actions: { load: loadEstateFields, unload: unloadEstateFields },
  selectors: { getData: getEstateFields, isLoading: getEstateFieldsLoading },
  reducer: detailFieldsReducer,
  saga: detailFieldsSaga,
} = rj({
  type: GET_ESTATES_FIELDS,
  state: 'estates.detailFields',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request
        .get(`${API_URL}estates/${id}/fields`)
        .query({ includeStatus: true })
    )
      // .query(params)
      .then(({ body }) => body),
})()

// sectors list
const GET_ESTATES_SECTORS = `${NS}GET_ESTATES_SECTORS`
export const {
  actions: { load: loadEstateSectors, unload: unloadEstateSectors },
  selectors: { getData: getEstateSectors, isLoading: getEstateSectorsLoading },
  reducer: detailSectorsReducer,
  saga: detailSectorsSaga,
} = rj({
  type: GET_ESTATES_SECTORS,
  state: 'estates.detailSectors',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request
        .get(`${API_URL}estates/${id}/irrigationsectors`)
        .query({ includeStatus: true })
    )
      // .query(params)
      .then(({ body }) => body),
})()

// meteo stations list
const GET_ESTATE_METEO_STATIONS = `${NS}GET_ESTATE_METEO_STATIONS`
export const {
  actions: { load: loadEstateMeteoStations, unload: unloadEstateMeteoStations },
  selectors: {
    getData: getEstateMeteoStations,
    isLoading: getEstateMeteoStationsLoading,
  },
  reducer: detailMeteoStationsReducer,
  saga: detailMeteoStationsSaga,
} = rj({
  type: GET_ESTATE_METEO_STATIONS,
  state: 'estates.detailMeteoStations',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}estates/${id}/meteo-stations`))
      // .query(params)
      .then(({ body }) => body),
})()

// Notifications list
const GET_ESTATES_NOTIFICATIONS = `${NS}GET_ESTATES_NOTIFICATIONS`
export const {
  actions: { load: loadEstateNotifications, unload: unloadEstateNotifications },
  selectors: {
    getData: getEstateNotifications,
    isLoading: getEstateNotificationsLoading,
  },
  reducer: detailNotificationsReducer,
  saga: detailNotificationsSaga,
} = rj({
  type: GET_ESTATES_NOTIFICATIONS,
  state: 'estates.detailNotifications',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}estates/${id}/notifications`))
      // .query(params)
      .then(({ body }) => body),
})()

// Notifications list for fields
const GET_ESTATES_FIELDS_NOTIFICATIONS = `${NS}GET_ESTATES_FIELDS_NOTIFICATIONS`
export const {
  actions: {
    load: loadEstateFieldsNotifications,
    unload: unloadEstateFieldsNotifications,
  },
  selectors: {
    getData: getEstateFieldsNotifications,
    isLoading: getEstateFieldsNotificationsLoading,
  },
  reducer: detailFieldsNotificationsReducer,
  saga: detailFieldsNotificationsSaga,
} = rj({
  type: GET_ESTATES_FIELDS_NOTIFICATIONS,
  state: 'estates.detailFieldsNotifications',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}estates/${id}/fields/notifications`))
      // .query(params)
      .then(({ body }) => body),
})()

// Notifications list for sectors
const GET_ESTATES_SECTORS_NOTIFICATIONS = `${NS}GET_ESTATES_SECTORS_NOTIFICATIONS`
export const {
  actions: {
    load: loadEstateSectorsNotifications,
    unload: unloadEstateSectorsNotifications,
  },
  selectors: {
    getData: getEstateSectorsNotifications,
    isLoading: getEstateSectorsNotificationsLoading,
  },
  reducer: detailSectorsNotificationsReducer,
  saga: detailSectorsNotificationsSaga,
} = rj({
  type: GET_ESTATES_SECTORS_NOTIFICATIONS,
  state: 'estates.detailSectorsNotifications',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request.get(`${API_URL}estates/${id}/irrigationsectors/notifications`)
    )
      // .query(params)
      .then(({ body }) => body),
})()

// status for fields
const GET_ESTATES_FIELDS_STATUS = `${NS}GET_ESTATES_FIELDS_STATUS`
export const {
  actions: { load: loadEstateFieldsStatus, unload: unloadEstateFieldsStatus },
  selectors: {
    getData: getEstateFieldsStatus,
    isLoading: getEstateFieldsStatusLoading,
  },
  reducer: detailFieldsStatusReducer,
  saga: detailFieldsStatusSaga,
} = rj({
  type: GET_ESTATES_FIELDS_STATUS,
  state: 'estates.detailFieldsStatus',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(t, request.get(`${API_URL}estates/${id}/fields/status`))
      // .query(params)
      .then(({ body }) => body),
})()

// Caricamento stazioni meteo (rjDict per consentire più caricamenti paralleli)
// NOTA: vengono chiamate sia le api meteo che quelle specifiche per il grafico del vento
const GET_METEO_DICT = `${NS}GET_METEO_DICT`
export const {
  actions: { load: loadMeteoDict, unload: unloadMeteoDict },
  selectors: {
    makeGetData: makeGetStationMeteo,
    makeGetLoading: makeGetStationMeteoLoading,
  },
  reducer: meteoDictReducer,
  saga: meteoDictSaga,
} = rj(
  rjDict({
    dictDataSelector: data => {
      if (data === null) {
        return null
      }
      const rainValues = get(data, 'data.rain.values', [])
      const rainPeriods = inferContinuousPeriods(rainValues)
      // mutating the object causes problems ... let's just patch it
      if (data && rainPeriods) {
        data.rainPeriods = rainPeriods
      }
      return data
    },
  }),
  {
    type: GET_METEO_DICT,
    state: 'estates.meteoDict',
    effectCaller: authApiCall,
    effect: t => ({ id, ...params }) => {
      return withToken(
        t,
        request.get(`${API_URL}meteo-stations/${id}/data`).query(params)
      ).then(({ body }) => {
        return withToken(
          t,
          request.get(`${API_URL}meteo-stations/${id}/wind-data`).query(params)
        )
          .then(({ body: winBody }) => {
            return { ...body, id, wind: winBody }
          })
          .catch(err => {
            console.error('error for wind data', err)
            return { ...body, id }
          })
      })
    },
  }
)()

const UPDATE_IRRIGATION = 'UPDATE_IRRIGATION'
export const irrigationUpdated = payload => ({
  type: UPDATE_IRRIGATION,
  payload,
})
export const updateIrrigationReducer = (prevState, { type, payload }) => {
  if (type === UPDATE_IRRIGATION && prevState.data !== null) {
    return {
      ...prevState,
      data: {
        ...prevState.data,
        events: get(prevState.data, 'events', []).map(d => {
          return d.id === payload.id ? enhanceEvent(payload) : d
        }),
      },
    }
  }
  return prevState
}

const CREATE_IRRIGATION = 'CREATE_IRRIGATION'
export const irrigationCreated = payload => ({
  type: CREATE_IRRIGATION,
  payload,
})
export const createIrrigationReducer = (prevState, { type, payload }) => {
  if (type === CREATE_IRRIGATION && prevState.data !== null) {
    return {
      ...prevState,
      data: {
        ...prevState.data,
        events: get(prevState.data, 'events', []).concat(enhanceEvent(payload)),
      },
    }
  }
  return prevState
}

export const DELETE_ESTATE_IRRIGATION_EVENT = `${NS}DELETE_ESTATE_IRRIGATION_EVENT`
export const deleteIrrigationReducer = (prevState, { type, payload }) => {
  if (
    type === `${DELETE_ESTATE_IRRIGATION_EVENT}_SUCCESS` &&
    prevState.data !== null
  ) {
    return {
      ...prevState,
      data: {
        ...prevState.data,
        events: get(prevState.data, 'events', []).filter(
          evt => evt.id !== payload.data
        ),
      },
    }
  }
  return prevState
}

// irrigation events
const GET_ESTATE_IRRIGATION_EVENTS = `${NS}GET_ESTATE_IRRIGATION_EVENTS`
export const {
  actions: {
    load: loadEstateIrrigationEvents,
    unload: unloadEstateIrrigationEvents,
  },
  selectors: {
    getData: getEstateIrrigationEvents,
    isLoading: getEstateIrrigationEventsLoading,
  },
  reducer: detailIrrigationEventsReducer,
  saga: detailIrrigationEventsSaga,
} = rj(rjIrrigationEvents(), {
  type: GET_ESTATE_IRRIGATION_EVENTS,
  state: 'estates.detailIrrigationEvents',
  effectCaller: authApiCall,
  reducer: reducer =>
    composeReducers(
      reducer,
      updateIrrigationReducer,
      createIrrigationReducer,
      deleteIrrigationReducer
    ),
  effect: t => ({ id, ...params }) => {
    return withToken(
      t,
      request.get(`${API_URL}calendar/estates/${id}`).query(params)
    ).then(({ body }) => body)
  },
})()

export const {
  actions: { load: deleteEstateIrrigationEvent },
  selectors: { isLoading: deleteEstateIrrigationEventLoading },
  reducer: deleteIrrigationEventReducer,
  saga: deleteIrrigationEventSaga,
} = rj({
  type: DELETE_ESTATE_IRRIGATION_EVENT,
  state: 'estates.deleteIrrigationEvent',
  effectCaller: authApiCall,
  effect: t => ({ id }) => {
    return withToken(t, request.delete(`${API_URL}calendar/${id}`)).then(
      () => id
    )
  },
})()

// fields template
const GET_ESTATES_FIELDS_IRRIGATION_TEMPLATE = `${NS}GET_ESTATES_FIELDS_IRRIGATION_TEMPLATE`
export const {
  actions: {
    load: loadEstateFieldsIrrigationTemplate,
    unload: unloadEstateFieldsIrrigationTemplate,
  },
  selectors: {
    getData: getEstateFieldsIrrigationTemplate,
    isLoading: getEstateFieldsIrrigationTemplateLoading,
  },
  reducer: detailFieldsIrrigationTemplateReducer,
  saga: detailFieldsIrrigationTemplateSaga,
} = rj({
  type: GET_ESTATES_FIELDS_IRRIGATION_TEMPLATE,
  state: 'estates.detailFieldsIrrigationTemplate',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request
        .get(`${API_URL}estates/${id}/irrigationtemplate/fields/xlsx`)
        .on('request', function() {
          this.xhr.responseType = 'arraybuffer'
        })
    ).then(({ xhr }) => xhr.response),
})()

// sectors template
const GET_ESTATES_SECTORS_IRRIGATION_TEMPLATE = `${NS}GET_ESTATES_SECTORS_IRRIGATION_TEMPLATE`
export const {
  actions: {
    load: loadEstateSectorsIrrigationTemplate,
    unload: unloadEstateSectorsIrrigationTemplate,
  },
  selectors: {
    getData: getEstateSectorsIrrigationTemplate,
    isLoading: getEstateSectorsIrrigationTemplateLoading,
  },
  reducer: detailSectorsIrrigationTemplateReducer,
  saga: detailSectorsIrrigationTemplateSaga,
} = rj({
  type: GET_ESTATES_SECTORS_IRRIGATION_TEMPLATE,
  state: 'estates.detailSectorsIrrigationTemplate',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request
        .get(`${API_URL}estates/${id}/irrigationtemplate/sectors/xlsx`)
        .on('request', function() {
          this.xhr.responseType = 'arraybuffer'
        })
    ).then(({ xhr }) => xhr.response),
})()

// inferred/suggested irrigations
export const DELETE_ESTATE_ALLEGED_IRRIGATION_EVENT = `${NS}DELETE_ESTATE_ALLEGED_IRRIGATION_EVENT`
const deleteListAllegedIrrigationReducer = (prevState, { type, payload }) => {
  if (
    type === `${DELETE_ESTATE_ALLEGED_IRRIGATION_EVENT}_SUCCESS` &&
    prevState.data !== null
  ) {
    return {
      ...prevState,
      data: [
        ...get(prevState, 'data', []).filter(evt => evt.id !== payload.data),
      ],
    }
  }
  return prevState
}

const GET_ESTATE_ALLEGED_IRRIGATION_EVENTS = `${NS}GET_ESTATE_ALLEGED_IRRIGATION_EVENTS`
export const {
  actions: {
    load: loadEstateAllegedIrrigationEvents,
    unload: unloadEstateAllegedIrrigationEvents,
  },
  selectors: {
    getData: getEstateAllegedIrrigationEvents,
    isLoading: getEstateAllegedIrrigationEventsLoading,
  },
  reducer: detailAllegedIrrigationEventsReducer,
  saga: detailAllegedIrrigationEventsSaga,
} = rj({
  type: GET_ESTATE_ALLEGED_IRRIGATION_EVENTS,
  state: 'estates.detailAllegedIrrigationEvents',
  effectCaller: authApiCall,
  reducer: reducer =>
    composeReducers(reducer, deleteListAllegedIrrigationReducer),
  effect: t => ({ id, ...params }) => {
    return withToken(
      t,
      request
        .get(`${API_URL}calendar/estates/${id}/unconfirmed-allegedirrigations`)
        .query(params)
    ).then(({ body }) => body)
  },
})()

export const {
  actions: { load: deleteEstateAllegedIrrigationEvent },
  selectors: { isLoading: deleteEstateAllegedIrrigationEventLoading },
  reducer: deleteAllegedIrrigationReducer,
  saga: deleteAllegedIrrigationEventSaga,
} = rj(rjWithPromise, {
  type: DELETE_ESTATE_ALLEGED_IRRIGATION_EVENT,
  state: 'estates.deleteAllegedIrrigationEvent',
  effectCaller: authApiCall,
  effect: t => ({ id, idEvent }) => {
    return withToken(
      t,
      request.delete(
        `${API_URL}calendar/estates/${id}/allegedirrigations/${idEvent}`
      )
    ).then(() => idEvent)
  },
})()

export const {
  actions: { load: toggleSensorNodeOnOff },
  selectors: { isLoading: toggleSensorNodeOnOffLoading },
  reducer: toggleSensorNodeOnOffReducer,
  saga: toggleSensorNodeOnOffSaga,
} = rj({
  type: TOGGLE_SENSORNODE_ON_OFF,
  state: 'estates.toggleSensorNodeOnOff',
  effectCaller: authApiCall,
  effect: t => ({ estateId, id, turnOn }) => {
    const url = turnOn
      ? `${API_URL}data-providers/nodes/${id}/turn-on/?estate_id=${estateId}`
      : `${API_URL}data-providers/nodes/${id}/turn-off/?estate_id=${estateId}`

    return withToken(t, request.put(url)).then(() => id)
  },
})()

export const {
  actions: { load: toggleSensorNodeNotificationOnOff },
  selectors: { isLoading: toggleSensorNodeNotificationOnOffLoading },
  reducer: toggleSensorNodeNotificationOnOffReducer,
  saga: toggleSensorNodeNotificationOnOffSaga,
} = rj({
  type: TOGGLE_SENSORNODE_NOTIFICATION_ON_OFF,
  state: 'estates.toggleSensorNodeNotificationOnOff',
  effectCaller: authApiCall,
  effect: t => ({ estateId, id, turnOn }) => {
    const url = turnOn
      ? `${API_URL}data-providers/nodes/${id}/notifications/turn-on/?estate_id=${estateId}`
      : `${API_URL}data-providers/nodes/${id}/notifications/turn-off/?estate_id=${estateId}`

    return withToken(t, request.put(url)).then(() => id)
  },
})()

// pedoclimatic units
const GET_ESTATES_PED_UNITS = `${NS}GET_ESTATES_PED_UNITS`
export const {
  actions: { load: loadEstateUnits, unload: unloadEstateUnits },
  selectors: { getData: getEstateUnits, isLoading: getEstateUnitsLoading },
  reducer: detailUnitsReducer,
  saga: detailUnitsSaga,
} = rj({
  type: GET_ESTATES_PED_UNITS,
  state: 'estates.detailUnits',
  effectCaller: authApiCall,
  effect: t => ({ id }) =>
    withToken(
      t,
      request.get(`${API_URL}estates/${id}/pedoclimatic-units`)
    ).then(({ body }) => body),
})()

export const reducer = combineReducers({
  list: listReducer,
  detail: detailReducer,
  detailNotifications: detailNotificationsReducer,
  detailFieldsNotifications: detailFieldsNotificationsReducer,
  detailFieldsStatus: detailFieldsStatusReducer,
  detailSectorsNotifications: detailSectorsNotificationsReducer,
  detailFields: detailFieldsReducer,
  detailUnits: detailUnitsReducer,
  detailSectors: detailSectorsReducer,
  detailMeteoStations: detailMeteoStationsReducer,
  detailIrrigationEvents: detailIrrigationEventsReducer,
  deleteIrrigationEvent: deleteIrrigationEventReducer,
  meteoDict: meteoDictReducer,
  detailFieldsIrrigationTemplate: detailFieldsIrrigationTemplateReducer,
  detailSectorsIrrigationTemplate: detailSectorsIrrigationTemplateReducer,
  detailAllegedIrrigationEvents: detailAllegedIrrigationEventsReducer,
  deleteAllegedIrrigationEvent: deleteAllegedIrrigationReducer,
  toggleSensorNodeOnOff: toggleSensorNodeOnOffReducer,
  toggleSensorNodeNotificationOnOff: toggleSensorNodeNotificationOnOffReducer,
})

export const saga = function*() {
  yield fork(listSaga)
  yield fork(detailSaga)
  yield fork(detailNotificationsSaga)
  yield fork(detailFieldsNotificationsSaga)
  yield fork(detailFieldsStatusSaga)
  yield fork(detailSectorsNotificationsSaga)
  yield fork(detailFieldsSaga)
  yield fork(detailUnitsSaga)
  yield fork(detailSectorsSaga)
  yield fork(detailMeteoStationsSaga)
  yield fork(meteoDictSaga)
  yield fork(detailIrrigationEventsSaga)
  yield fork(deleteIrrigationEventSaga)
  yield fork(detailFieldsIrrigationTemplateSaga)
  yield fork(detailSectorsIrrigationTemplateSaga)
  yield fork(detailAllegedIrrigationEventsSaga)
  yield fork(deleteAllegedIrrigationEventSaga)
  yield fork(toggleSensorNodeOnOffSaga)
  yield fork(toggleSensorNodeNotificationOnOffSaga)
}

export const makeCreateIrrigation = createSelector(
  getToken,
  token => record =>
    withToken(
      token,
      request.post(`${API_URL}calendar/irrigations`).send(record)
    ).then(({ body }) => body)
)

export const makeCreateBulkIrrigations = createSelector(
  getToken,
  token => record =>
    withToken(
      token,
      request.post(`${API_URL}calendar/bulk-irrigations`).send(record)
    ).then(({ body }) => body)
)

export const makeUpdateIrrigation = createSelector(
  getToken,
  token => record =>
    withToken(
      token,
      request.put(`${API_URL}calendar/irrigations/${record.id}`).send(record)
    ).then(() => record)
)

export const makeDeleteIrrigation = createSelector(
  getToken,
  token => record =>
    withToken(
      token,
      request.delete(`${API_URL}calendar/irrigations/${record.id}`)
    ).then(({ body }) => body)
)
