import React from 'react'
import { connect } from 'react-redux'
import { localize } from '../localize'
import Layout from '../components/Layout'
import Spinner from '../components/Spinner'
import { getAuthUser } from 'eazy-auth'
import { Card, CardHeader, CardBody, Alert } from 'reactstrap'
import {
  loadNotification,
  getNotificationLoading,
  getNotification,
  unloadNotification,
} from '../state/notification'
import qs from 'query-string'

class SegnalaNotifica extends React.PureComponent {
  componentDidMount() {
    const params = qs.parse(this.props.location.search)
    const { data } = params
    if (data) {
      this.props.loadNotification({ data: `"${data.replace(/ /g, '+')}"` })
    }
  }

  componentWillUnmount() {
    this.props.unloadNotification()
  }

  render() {
    const { notificationLoading, t, notification, location } = this.props

    const params = qs.parse(location.search)
    const { data } = params

    return (
      <Layout style={{ paddingBottom: 64 }}>
        <Layout.Content className="container h-100 p-5">
          <div className="mt-3">
            {!data && (
              <div className="text-center p-5">
                <Alert color="danger">{t('invalidParameter')}</Alert>
              </div>
            )}
            {data && notificationLoading && (
              <div className="text-center p-5">
                <Spinner></Spinner>
              </div>
            )}
            {notification && (
              <div className="container">
                <Card>
                  <CardHeader>{t('notificationSentTitle')}</CardHeader>
                  <CardBody>
                    <p>{t('notificationSentBody')}</p>
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default connect(
  state => ({
    user: getAuthUser(state),
    notification: getNotification(state),
    notificationLoading: getNotificationLoading(state),
  }),
  {
    loadNotification,
    unloadNotification,
  }
)(localize()(SegnalaNotifica))
