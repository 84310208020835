import React, { PureComponent } from 'react'
import { localize } from '../localize'
import Layout from '../components/Layout'
import versionData from '../data/version.json'

class Home extends PureComponent {
  render() {
    const { t } = this.props
    const d = new Date()

    return (
      <Layout>
        <Layout.Content className="w-100 flex-1">
          <div className="home-background text-center d-flex flex-column justify-content-center">
            <div
              style={{ flex: 1 }}
              className="d-flex flex-column justify-content-center pb-5"
            >
              <h1 className="text-shadow">IRRISmart</h1>
              <h5 className="text-shadow">{t('homeClaim')}</h5>
              <h6 className="text-shadow mt-4">
                <i>v. {versionData.version}</i>
                <i style={{ display: 'none' }}>build {versionData.version}</i>
              </h6>
              {/* just for spacing.. */}
              <div className="p-5">&nbsp;</div>
            </div>
            <div>
              {/* just for spacing.. */}
              <div className="pb-5 mb-3">&nbsp;</div>
            </div>
          </div>
          <div
            style={{ height: 60, bottom: 0 }}
            className="position-fixed w-100 d-flex align-items-center justify-content-center"
          >
            <h6 className="text-shadow p-4 m-0">
              <a
                href="http://www.iambientale.it/"
                target="_blank"
                rel="noopener norefereer"
                className="text-white "
              >
                © {d.getFullYear()} - Informatica ambientale
              </a>
            </h6>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default localize()(Home)
