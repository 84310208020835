import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { localize } from '../localize'
import Layout from '../components/Layout'
import { Nav, NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'
import {
  getEstate,
  getEstateLoading,
  getEstateNotifications,
  getEstateFields,
  getEstateSectors,
  getEstateMeteoStations,
  getEstateFieldsNotifications,
  getEstateFieldsStatus,
  getEstateSectorsNotifications,
  getEstateAllegedIrrigationEvents,
  getEstateUnits,
} from '../state/estates'
import { getMapPanel, setMapPanel, unsetMapObject } from '../state/ui'
import FieldsMap from '../components/FieldsMap'
import EstateFieldsWithNotifications from '../components/EstateFieldsWithNotifications'
import Spinner from '../components/Spinner'
import EstateMeteoCharts from '../components/EstateMeteoCharts'
import EstateConfiguration from '../components/EstateConfiguration'
import EstateIrrigation from '../components/EstateIrrigation'
import qs from 'query-string'

class EstateDetail extends React.Component {
  toggle = tab => {
    const { mapPanel } = this.props
    if (mapPanel !== tab) {
      this.props.unsetMapObject()
      this.props.setMapPanel(tab)
    }
  }

  componentWillUnmount() {
    this.props.unsetMapObject()
  }

  componentWillMount() {
    const { mapPanel, location } = this.props
    const params = qs.parse(location.search)
    if (params.tabName && params.tabName !== mapPanel) {
      this.props.setMapPanel(params.tabName)
    }
  }

  componentDidUpdate(oldProps) {
    const { mapPanel, location, history } = this.props
    const params = qs.parse(location.search)
    const oldMapPanel = oldProps.mapPanel

    if (mapPanel !== oldMapPanel) {
      // resetting orderBy when switching from fields to sectors
      let newParams = { ...params }
      if (
        (mapPanel === 'fields' && oldMapPanel === 'sectors') ||
        (mapPanel === 'sectors' && oldMapPanel === 'fields')
      ) {
        newParams.orderBy = undefined
      }

      const queryParams = qs.stringify({
        ...newParams,
        tabName: mapPanel,
      })

      history.push(`${location.pathname}?${queryParams}`)
    } else {
      if (params.tabName && params.tabName !== mapPanel) {
        this.props.setMapPanel(params.tabName)
      }
    }
  }

  render() {
    const {
      estate,
      estateNotifications,
      fieldsStatus,
      sectorsNotifications,
      estateFields,
      estateSectors,
      estateMeteoStations,
      estateUnits,
      allegedIrrigationEvents,
      mapPanel,
      loading,
      location,
      t,
    } = this.props

    const hasMap = mapPanel !== 'irrigation'
    const params = qs.parse(location.search)
    const currentStationId = params.stationId

    return (
      <Layout>
        <Layout.Content className="container-fluid h-100">
          {loading && (
            <div className="text-center py-5 my-5">
              <Spinner className="text-muted" />
            </div>
          )}

          {estate && (
            <div>
              <div className="mt-3">
                <h5>
                  <Link to={`/estates`} className="text-muted">
                    {t('estates')} /
                  </Link>{' '}
                  {estate.name}
                </h5>
              </div>

              <div className="my-3">
                <Nav tabs>
                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({ active: mapPanel === 'fields' })}
                      onClick={() => {
                        this.toggle('fields')
                      }}
                    >
                      <i className="fa fa-th"></i> {t('fields')}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({ active: mapPanel === 'sectors' })}
                      onClick={() => {
                        this.toggle('sectors')
                      }}
                    >
                      <i className="fa fa-tint"></i> {t('irrigationSectors')}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({ active: mapPanel === 'meteo' })}
                      onClick={() => {
                        this.toggle('meteo')
                      }}
                    >
                      <i className="fa fa-bar-chart"></i> Meteo
                    </NavLink>
                  </NavItem>
                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({
                        active: mapPanel === 'configuration',
                      })}
                      onClick={() => {
                        this.toggle('configuration')
                      }}
                    >
                      <i className="fa fa-sliders"></i> {t('configuration')}
                    </NavLink>
                  </NavItem>

                  <NavItem className="pointer">
                    <NavLink
                      className={classNames({
                        active: mapPanel === 'irrigation',
                      })}
                      onClick={() => {
                        this.toggle('irrigation')
                      }}
                    >
                      <i className="fa fa-tint"></i> {t('irrigationCalendar')}
                      {allegedIrrigationEvents &&
                        allegedIrrigationEvents.length > 0 && (
                          <span className="badge-notify">
                            {allegedIrrigationEvents.length}
                          </span>
                        )}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              {hasMap && (
                <FieldsMap
                  estate={estate}
                  fields={estateFields}
                  sectors={estateSectors}
                  estateUnits={estateUnits}
                  notifications={estateNotifications}
                />
              )}

              {mapPanel === 'meteo' &&
                estate &&
                estateMeteoStations &&
                estateMeteoStations.length > 0 && (
                  <EstateMeteoCharts
                    stations={estateMeteoStations}
                    currentStationId={currentStationId}
                    height={640}
                  />
                )}

              {mapPanel === 'fields' && (
                <EstateFieldsWithNotifications
                  fields={estateFields}
                  fieldsStatus={fieldsStatus}
                  sectors={estateSectors}
                  subject="fields"
                />
              )}

              {mapPanel === 'sectors' && (
                <EstateFieldsWithNotifications
                  notifications={sectorsNotifications}
                  fields={estateFields}
                  sectors={estateSectors}
                  fieldsStatus={estateSectors}
                  subject="sectors"
                />
              )}

              {mapPanel === 'configuration' && (
                <EstateConfiguration estate={estate} />
              )}

              {mapPanel === 'irrigation' && <EstateIrrigation />}
            </div>
          )}
        </Layout.Content>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  estate: getEstate(state),
  loading: getEstateLoading(state),
  estateFields: getEstateFields(state),
  estateSectors: getEstateSectors(state),
  estateUnits: getEstateUnits(state),
  estateNotifications: getEstateNotifications(state),
  fieldsNotifications: getEstateFieldsNotifications(state),
  fieldsStatus: getEstateFieldsStatus(state),
  sectorsNotifications: getEstateSectorsNotifications(state),
  estateMeteoStations: getEstateMeteoStations(state),
  allegedIrrigationEvents: getEstateAllegedIrrigationEvents(state),
  mapPanel: getMapPanel(state),
})
export default withRouter(
  connect(
    mapStateToProps,
    { setMapPanel, unsetMapObject }
  )(localize()(EstateDetail))
)
